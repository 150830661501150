/** @jsxImportSource @emotion/react */

import { css, jsx } from "@emotion/react";
import React, { useState, useEffect, useReducer, useContext } from "react";
import { SearchMinor } from "@shopify/polaris-icons";
import { Link, useHistory, useLocation } from "react-router-dom";
// import { useParams, useLocation, useHistory } from "react-router-dom";
import { Modal } from "@shopify/polaris";
import Select from "react-select";

import {
  entitySuggest,
  entityTemplate,
  entityTemplatePagination,
  getFilterBundleData,
  getFilterBundleDataPagination,
  TemplateSearch,
} from "../Helpers/DrupalHelper";
import {
  Card,
  Button,
  DataTable,
  DisplayText,
  Pagination,
  Icon,
  TextField,
  ChoiceList,
  TextContainer,
  SkeletonBodyText,
  Loading,
} from "@shopify/polaris";
import { PackagesListContext } from "../Context/PackagesListContext";
import ImageCardSmall from "../Components/Elements/ImageCardSmall";
import BreadCrumb from "../Components/Elements/BreadCrumb";
import { LabelOff } from "@mui/icons-material";
import { timestampToDate } from "../utils";
const customStyles = {
  container: (base) => ({
    ...base,
    fontSize: "1.4rem",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 334444444,
    fontSize: "1.4rem",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};
const cssChoiceListSurround = {
  margin: "40px 0 20px",
  ul: {
    display: "flex",
    flexWrap: "wrap",
    li: {
      maxWidth: "20%",
      width: "20%",
      padding: "4px 0",
    },
  },
};

const initialState = {
  items: [],
  prevPageItems: [],
  nextPageItems: [],
  isLoading: true,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_ITEMS":
      return {
        ...state,
        items: action.payload,
        isLoading: false,
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "SET_NEXT_ITEMS":
      return {
        ...state,
        nextPageItems: action.payload,
      };
    case "SET_PREV_ITEMS":
      return {
        ...state,
        prevPageItems: action.payload,
        prevPageNumber: action.pageNumber,
      };

    default:
      return state;
  }
};

export const toCapitalCase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
const sortColNames = [
  "id",
  "name",
  "brand",
  "sku",
  "created",
  "updated",
  "type",
  "changed",
  "company",
  "region code",
  "country",
];
function PackagesTemplate(props) {
  // const [state, dispatch] = useReducer(reducer, initialState);
  const { state, dispatch, setSortOption, setOtherItmes, setImageColN } =
    useContext(PackagesListContext);
  // let back = useBackButton();
  console.log(props);
  const location = useLocation();
  const {
    filterItems,
    allBundlesItems,
    bundles,
    totalPages,
    page,
    hasPrevious,
    hasNext,
    inputValue,
    checkBoxValue,
    sortOptions,
    otherItems,
    imageColN,
  } = state;
  const { token, pageTitle, entities, name } = props;
  let currentTemplate = entities[name];
  // const [allBundlesItems, setAllBundlesItems] = useState([]);
  // const [bundles, setBundles] = useState([]);
  // const [totalPages, setTotalPages] = useState([]);
  // const [page, setPage] = useState(1);
  // const [hasPrevious, setHasPrevious] = useState(true);
  // const [hasNext, setHasNext] = useState(true);
  console.log(props.name);
  const runColmumnItems = (item) => {
    let otherCol = [];
    let otherColData = [];
    let imageCol = [];
    let imgs = ["img", "logo", "image", "images", "cover"];
    let icol = Object.keys(item).filter((v) => imgs.includes(v))[0];

    if (icol) setImageColN(toCapitalCase(icol).replaceAll("_", " "));

    Object.keys(item).map((v) => {
      if (imgs.includes(v)) {
        imageCol.push(<ImageCardSmall alt={item.title} image={item[v]} />);
        return;
      } else if (v === "id") return;
      else if (v === "title") return;
      else if (v === "target_id") return;
      else if (v === "bundle") return;
      else if (Array.isArray(item[v])) {
        if (item[v].length > 2) {
          let d =
            item[v][0] +
            ", " +
            item[v][1] +
            " and " +
            item[v].length +
            " more...";
          otherColData.push(<span style={{ maxWidth: "100px" }}>{d}</span>);
        } else {
          otherColData.push(
            <span style={{ maxWidth: "100px" }}>
              {item[v].map(
                (c, k) => `${c}${item[v].length !== k + 1 ? ", " : ""}`
              )}
            </span>
          );
        }
        otherCol.push(toCapitalCase(v).replaceAll("_", " "));
      } else {
        otherColData.push(
          <span style={{ maxWidth: "100px" }}>
            {['created', 'updated'].includes(v) && new Date(item[v] * 1000) > 0
              ? timestampToDate(item[v])
              : item[v]}
          </span>
        );
        if (['changed', 'updated'].includes(v)) otherCol.push("Last Updated");
        else otherCol.push(toCapitalCase(v).replaceAll("_", " "));
      }
      // if (!imgs.includes(v))
      //   setImageColN(toCapitalCase(v).replaceAll("_", " "));
    });
    setOtherItmes([...otherCol]);
    console.log();
    return {
      otherColData: otherColData,
      imageCol: icol ? (imageCol[0] ? imageCol[0] : "No Image") : false,
    };
  };
  const getSortColNames = () => {
    let sortColsName = ["id", "name", "created", "last updated"];
    let sortColsOptions = [
      { name: "id", fieldName: "uid" },
      { name: "name", fieldName: "title" },
      { name: "created", fieldName: "created" },
      { name: "last updated", fieldName: "changed" },
    ];
    if (props.name === "asset") {
      sortColsName = [...sortColsName, "brand", "sku"];
      sortColsOptions = [
        ...sortColsOptions,
        { name: "brand", fieldName: "field_asset_brand" },
        { name: "sku", fieldName: "field_asset_sku" },
      ];
    } else if (props.name === "product") {
      sortColsName = [...sortColsName, "brand", "type"];
      sortColsOptions = [
        ...sortColsOptions,
        { name: "brand", fieldName: "field_product_brand" },
        { name: "type", fieldName: "field_product_type" },
      ];
    } else if (props.name === "retailer") {
      sortColsName = [...sortColsName, "company"];
      sortColsOptions = [
        ...sortColsOptions,
        { name: "company", fieldName: "field_retailer_company" },
      ];
    } else if (props.name === "company") {
      sortColsName = [...sortColsName, "type"];
      sortColsOptions = [
        ...sortColsOptions,
        { name: "type", fieldName: "field_company_type" },
      ];
    } else if (props.name === "brand") {
      sortColsName = [...sortColsName, "company"];
      sortColsOptions = [
        ...sortColsOptions,
        { name: "company", fieldName: "field_brand_company" },
      ];
    } else if (props.name === "region") {
      sortColsName = [...sortColsName, "region code", "country"];
      sortColsOptions = [
        ...sortColsOptions,
        { name: "region code", fieldName: "field_region_code" },
        { name: "country", fieldName: "field_region_country" },
      ];
    }
    return { sortColsName, sortColsOptions };
  };
  const getFilterStr = (checkBoxValue, inputValue, sortOptions) => {
    let str = "";

    if (
      checkBoxValue.length ||
      checkBoxValue?.roles ||
      checkBoxValue?.status ||
      inputValue
    ) {
      if (
        checkBoxValue.length ||
        checkBoxValue?.roles ||
        checkBoxValue?.status
      ) {
        let nObj = checkBoxValue;
        if (props.name === "user") {
          Object.keys(nObj).map(
            (v, k) =>
              nObj[v].length &&
              (str =
                str +
                `${k !== 0 ? "&" : ""}${v}=${
                  nObj[v].length > 2
                    ? nObj[v][0] + "," + nObj[v][1] + "," + nObj[v][2]
                    : nObj[v].length > 1
                    ? nObj[v][0] + "," + nObj[v][1]
                    : nObj[v][0]
                }`)
          );

          str = `${str ? `&${str}` : ""}&order=created&sort=desc`;
        } else {
          str = "&bundle=";
          let bundless = checkBoxValue.map((v, k) => {
            if (checkBoxValue.length - 1 !== k) str += v + ",";
            else str += v;
            return v;
          });
        }
      }

      if (inputValue) str = `${str ? `${str}` : ""}&title=${inputValue}`;
    }
    if (sortOptions?.name && sortOptions?.sort) {
      str = `${str ? str : ""}&order=${sortOptions.name}&sort=${
        sortOptions.sort
      }`;
    }
    return str;
  };
  const { sortColsName, sortColsOptions } = getSortColNames();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page]);
  const fetchEntities = (currentPage, s, p) => {
    let sOp = sortOptions;
    if (s) sOp = s;
    if (p !== false) dispatch({ type: "SET_LOADING" });
    if (token !== null) {
      let str = getFilterStr(checkBoxValue, inputValue, sOp);
      console.log(str);

      entityTemplate(token, props.name, currentPage, false, str).then(
        (data) => {
          let newArray = data;
          // console.log(newArray);
          dispatch({
            type: "SET_ITEMS",
            payload: newArray,
          });
        }
      );
      entityTemplate(token, props.name, currentPage + 1, false, str).then(
        (data) => {
          let newArray = data;
          dispatch({
            type: "SET_NEXT_ITEMS",
            payload: newArray,
          });
        }
      );
    }
  };

  // let back = useBackButton();
  // console.log(back);

  const fetchEntitiesPagination = () => {
    if (token !== null) {
      entityTemplatePagination(token, props.name).then((data) => {
        // setTotalPages(data);SET_BUNDLES_ITEMS
        dispatch({
          type: "SET_TOTAL_PAGE",
          payload: data,
        });
      });
    }
  };

  const paginationPrevious = () => {
    // setHasNext(true);
    dispatch({
      type: "SET_HAS_NEXT",
      payload: true,
    });
    // setPage(page - 1);

    dispatch({
      type: "SET_PAGE",
      payload: page - 1,
    });
    if (page - 1 === 1) {
      // setHasPrevious(false);
      dispatch({
        type: "SET_HAS_PREVIOUS",
        payload: false,
      });
    }
    if (inputValue === "" && checkBoxValue.length === 0) {
      // fetchEntities(page - 1)
      dispatch({
        type: "SET_NEXT_ITEMS",
        payload: state.items,
      });
      if (state.prevPageNumber !== page - 1) {
        let str = getFilterStr(checkBoxValue, inputValue, sortOptions);
        entityTemplate(token, props.name, page - 1, false, str).then((data) => {
          let newArray = data;
          dispatch({
            type: "SET_ITEMS",
            payload: newArray,
          });
        });
      } else {
        dispatch({
          type: "SET_ITEMS",
          payload: state.prevPageItems,
        });
      }
    } else {
      handleFiltering(checkBoxValue, inputValue, page - 1);
    }
  };

  const paginationNext = () => {
    if (page + 1 === totalPages.length) {
      // setHasNext(false);
      dispatch({
        type: "SET_HAS_NEXT",
        payload: false,
      });
    }
    // setHasPrevious(true);
    dispatch({
      type: "SET_HAS_PREVIOUS",
      payload: true,
    });
    // setPage(page + 1);
    dispatch({
      type: "SET_PAGE",
      payload: page + 1,
    });

    if (inputValue === "" && checkBoxValue.length === 0) {
      // fetchEntities(page + 1);
      // console.log(page)
      dispatch({
        type: "SET_PREV_ITEMS",
        payload: state.items,
        pageNumber: page,
      });
      dispatch({
        type: "SET_ITEMS",
        payload: state.nextPageItems,
      });
      let str = getFilterStr(checkBoxValue, inputValue, sortOptions);
      entityTemplate(token, props.name, page + 2, false, str).then((data) => {
        let newArray = data;
        dispatch({
          type: "SET_NEXT_ITEMS",
          payload: newArray,
        });
      });
    } else {
      handleFiltering(checkBoxValue, inputValue, page + 1);
    }
  };

  useEffect(() => {
    console.log(window.PressedBack, "back");
    if (window.PressedBack && window.listState) {
      // dispatch()
      window.PressedBack = false;
      dispatch({
        type: "SET_STATE",
        payload: window.listState,
      });

      setImageColN(window.listState.imageColN);
      setOtherItmes(window.listState.otherItems);
      setSortOption(window.listState.sortOptions);
      console.log(window.listState);
      window.removeEventListener("popstate", () => {
        console.log("event removed");
      });
    } else {
      fetchEntities(1);
      fetchEntitiesPagination();
      if (page === 1) {
        // setHasPrevious(false);
        dispatch({
          type: "SET_HAS_PREVIOUS",
          payload: false,
        });
      }
      async function ChoiceListArray() {
        let AA = [];
        Object.entries(entities).map((field, i) => {
          if (field[0] === props.name) {
            console.log(field);
            field[1].bundles &&
              Object.entries(field[1].bundles).map((label, value) => {
                let labele = label.map((v, i) => {
                  return v;
                });
                return AA.push({ label: labele[1], value: labele[0] });
              });
          }
        });
        // console.log(AA);
        // setBundles(AA)
        dispatch({
          type: "SET_BUNDLES_ITEMS",
          payload: AA,
        });
      }
      ChoiceListArray();
    }
  }, []);
  //  implementing Filter
  const [timer, setTimer] = useState(null);
  const handleFiltering = async (checkBoxValue, search, page) => {
    let bundleStr = "&bundle=";
    let searchTitle = search ? search.toLowerCase() : false;
    let str = getFilterStr(checkBoxValue, searchTitle, sortOptions);
    if (str) {
      let allBundlesItemData = await getFilterBundleData(
        token,
        props.name,
        str,
        page
      );
      // let allBundlesItemData = await entitySuggest(token, body, true, str);

      console.log(allBundlesItemData);
      // setAllBundlesItems([...allBundlesItemData]);
      dispatch({
        type: "SET_ALL_BUNDLES_ITEMS",
        payload: [...allBundlesItemData],
      });
      dispatch({
        type: "SET_ITEMS",
        payload: allBundlesItemData,
      });
      // setPage(1);
      dispatch({
        type: "SET_PAGE",
        payload: page,
      });
      let pgStr = str.substring(1);
      let pageArray = await getFilterBundleDataPagination(
        token,
        props.name,
        pgStr,
        1
      );
      dispatch({
        type: "SET_TOTAL_PAGE",
        payload: pageArray,
      });
    } else {
      fetchEntities(1);
      fetchEntitiesPagination();
      dispatch({
        type: "SET_PAGE",
        payload: 1,
      });
      // setHasPrevious(false);
      dispatch({
        type: "SET_HAS_PREVIOUS",
        payload: false,
      });
    }
  };

  const handleSearchChange = async (value) => {
    // setFilterItems([])
    // setInputValue(value);
    dispatch({
      type: "SET_SEARCH_VALUE",
      payload: value,
    });
    clearTimeout(timer);

    // if(searchTtitle.length > inputValue.length){

    // }
    const newTimer = setTimeout(async () => {
      handleFiltering(checkBoxValue, value, 1);
    }, 1000);
    setTimer(newTimer);
  };
  const handleCheckBoxChange = async (value, type) => {
    // dispatch({ type: "SET_FILTER_ITEMS", payload: [] });
    // setCheckBoxValue(vaule);
    dispatch({ type: "SET_LOADING" });
    let bundleStr = "&bundle=";

    let nObj = value;
    if (props.name === "user") {
      nObj = { ...checkBoxValue, [type]: value };
      dispatch({
        type: "SET_CHECKBOX_VALUE",
        payload: { ...checkBoxValue, [type]: value },
      });
    } else {
      dispatch({
        type: "SET_CHECKBOX_VALUE",
        payload: value,
      });
    }
    handleFiltering(nObj, inputValue, 1);
  };

  // handleFilterChanges(inputValue, vaule);

  console.log(checkBoxValue);
  const handleSort = (index, direction) => {
    console.log(index, direction);
    let dir = direction == "descending" ? "desc" : "asc";
    let a = ["ID", imageColN, "Name", ...otherItems, "Edit"].filter(Boolean);
    // console.log(a[index]);
    let thisOp = sortColsOptions.filter(
      (v) => v.name === a[index].toLowerCase()
    )[0];
    console.log(thisOp, dir);
    setSortOption({ name: thisOp.fieldName, sort: dir });
    fetchEntities(page, { name: thisOp.fieldName, sort: dir }, false);
  };
  // useCallback(
  //   (index, direction) => setSortedRows(sortCurrency(rows, index, direction)),
  //   [rows]
  // );
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  const handleChange = (value, action) => {
    console.log(value);
    setSelectValue(`${value.value}`);

    // history.push(`${value.value}`);
    // setOpen(false);
    // setCurrentItem(null);
  };
  const runCreate = () => {
    //  console.log(selectValue);
    //  let Val = selectValue;
    history.push(
      `add-new-${props.name}/${selectValue ? selectValue : bundles[0].value}`
    );
    setOpen(false);
  };
  const [items, setItems] = useState(state.items);
  useEffect(() => {
    const itemss = state.items.map((item, index) => {
      let { target_id, bundle, title } = item;
      let id = item.id ? item.id : target_id;
      let otherCol = [];
      let { otherColData, imageCol } = runColmumnItems(item);
      return [
        <Link
          to={{
            pathname: `/edit_${props.name}/${bundle}/${id}`,
            state: { bundle: bundle },
          }}
        >
          {id}
        </Link>,
        imageCol,
        <Link
          to={{
            pathname: `/edit_${props.name}/${bundle}/${id}`,
            state: { bundle: bundle },
          }}
        >
          {title}
        </Link>,
        ...otherColData,
        <Link
          to={{
            pathname: `/edit_${props.name}/${bundle}/${id}`,
            state: { bundle: bundle },
          }}
        >
          <Button>Edit</Button>
        </Link>,
      ].filter(Boolean);
    });
    setItems(itemss);
  }, [state.items]);
  return (
    <>
      <BreadCrumb list={[{ title: props.name, link: "" }]} />

      {state.isLoading ? (
        <>
          <Loading />
          <br />
          <DisplayText size="extraLarge" element="h1">
            {pageTitle}
          </DisplayText>

          <TextField
            value={inputValue}
            prefix={<Icon source={SearchMinor} color="inkLighter" />}
            placeholder="Search"
            onChange={handleSearchChange}
          />
          {props.name === "user" ? (
            <>
              <div css={cssChoiceListSurround}>
                <ChoiceList
                  title="Filter by Role"
                  choices={[
                    {
                      label: "Brand",
                      value: "brand",
                    },
                    {
                      label: "Retailer",
                      value: "retailer",
                    },
                  ]}
                  allowMultiple
                  selected={checkBoxValue?.roles ? checkBoxValue.roles : []}
                  onChange={(v) => handleCheckBoxChange(v, "roles")}
                />
              </div>
              <div css={cssChoiceListSurround}>
                <ChoiceList
                  title="Filter by Status"
                  choices={[
                    {
                      label: "Approved",
                      value: "approved",
                    },
                    {
                      label: "Pending",
                      value: "pending",
                    },
                    {
                      label: "Declined",
                      value: "declined",
                    },
                  ]}
                  allowMultiple
                  selected={checkBoxValue?.status ? checkBoxValue.status : []}
                  onChange={(v) => handleCheckBoxChange(v, "status")}
                />
              </div>
            </>
          ) : (
            <div css={cssChoiceListSurround}>
              <ChoiceList
                title="Filter by Type"
                choices={bundles}
                allowMultiple
                selected={checkBoxValue}
                onChange={handleCheckBoxChange}
              />
            </div>
          )}
          <br />
          {[...Array(20)].map((e, i) => {
            return (
              <Card.Section key={i}>
                <TextContainer>
                  <SkeletonBodyText lines={1} key={i} />
                </TextContainer>
              </Card.Section>
            );
          })}
        </>
      ) : (
        <>
          <DisplayText size="extraLarge" element="h1">
            {pageTitle}
          </DisplayText>
          <br />
          <TextField
            value={inputValue}
            prefix={<Icon source={SearchMinor} color="inkLighter" />}
            placeholder="Search"
            onChange={handleSearchChange}
          />
          {props.name === "user" && (
            <>
              <div css={cssChoiceListSurround}>
                <ChoiceList
                  title="Filter by Role"
                  choices={[
                    {
                      label: "Brand",
                      value: "brand",
                    },
                    {
                      label: "Retailer",
                      value: "retailer",
                    },
                  ]}
                  allowMultiple
                  selected={checkBoxValue?.roles ? checkBoxValue.roles : []}
                  onChange={(v) => handleCheckBoxChange(v, "roles")}
                />
              </div>
              <div css={cssChoiceListSurround}>
                <ChoiceList
                  title="Filter by Status"
                  choices={[
                    {
                      label: "Approved",
                      value: "approved",
                    },
                    {
                      label: "Pending",
                      value: "pending",
                    },
                    {
                      label: "Declined",
                      value: "declined",
                    },
                  ]}
                  allowMultiple
                  selected={checkBoxValue?.status ? checkBoxValue.status : []}
                  onChange={(v) => handleCheckBoxChange(v, "status")}
                />
              </div>
            </>
          )}
          {bundles.length > 1 && props.name !== "user" && (
            <div css={cssChoiceListSurround}>
              <ChoiceList
                title="Filter by Type"
                choices={bundles}
                allowMultiple
                selected={checkBoxValue}
                onChange={handleCheckBoxChange}
              />
            </div>
          )}
          {name !== "order" && (
            <div className="right-0 min-h-[30px]">
              <button
                className="Polaris-Button Polaris-Button--primary float-right"
                type="button"
                onClick={() => setOpen(true)}
                style={{ marginRight: "10px" }}
              >
                <span class="Polaris-Button__Content">
                  <span class="Polaris-Button__Text">Add New</span>
                </span>
              </button>
            </div>
          )}
          <br />
          <p className="my-[10px]">
            Showing{" "}
            {page == 1 ? "1-20" : `${(page - 1) * 20 + 1} - ${page * 20}`} of{" "}
            {currentTemplate.total}
          </p>
          <Card>
            {state.items.length ? (
              <>
                <div style={{ textAlign: "right", padding: "11px 20px 0 0" }}>
                  <div id="PolarisPortalsContainer"></div>
                </div>
                <DataTable
                  columnContentTypes={[
                    "numeric",
                    "text",
                    imageColN && "text",
                    ...otherItems.map((v) => "text"),
                    "text",
                  ].filter(Boolean)}
                  headings={[
                    "ID",
                    imageColN,
                    "Name",
                    ...otherItems,
                    "Edit",
                  ].filter(Boolean)}
                  rows={items}
                  footerContent={`Showing ${state.items.length} items on this page`}
                  sortable={["ID", imageColN, "Name", ...otherItems, "Edit"]
                    .filter(Boolean)
                    .map((v) => {
                      if (sortColsName.includes(v.toLowerCase())) return true;
                      else return false;
                    })}
                  defaultSortDirection="descending"
                  initialSortColumnIndex={0}
                  onSort={handleSort}
                />
              </>
            ) : inputValue ? (
              <h1> No item Matches with {inputValue}</h1>
            ) : (
              <h1> No item Found</h1>
            )}
          </Card>

          {totalPages.length > 1 && (
            <>
              <br />
              <Pagination
                onPrevious={paginationPrevious}
                onNext={paginationNext}
                plain={false}
                hasPrevious={hasPrevious}
                hasNext={hasNext}
              />
              {/* <Heading>Go to : </Heading>
			  		<select name="paginationSelect" onChange={ (e) => handleSelectChange(e) }>
			  			{totalPages.map(number => (
							<option key={number} value={number}>{number}</option>
						))}
			  		</select> */}
            </>
          )}
        </>
      )}
      <Modal
        instant
        open={open}
        onClose={() => setOpen(false)}
        title={<>Add New {props.name}</>}
      >
        <Modal.Section>
          <p className="mb-5">
            Which type of <u>{props.name}</u> do you want to create?{" "}
          </p>
          <div className="w-full relative flex">
            <div style={{ width: "80%", marginRight: "12px" }}>
              <Select
                options={bundles}
                defaultValue={bundles[0]}
                isSearchable={true}
                onChange={(value, action) => {
                  handleChange(value);
                }}
                styles={customStyles}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                menuPlacement={"auto"}
                menuShouldScrollIntoView={true}
              />
            </div>
            <div className="w-[20%]">
              <Button onClick={() => runCreate()}>Create</Button>
            </div>
          </div>
        </Modal.Section>
      </Modal>
    </>
  );
}

export default PackagesTemplate;
