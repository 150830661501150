import React, {
  useContext,
  useMemo,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import Button from "@mui/material/Button";
import Select from "react-select";
import { Close } from "@material-ui/icons";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { border } from "@mui/system";
import { CircularProgress } from "@mui/material";
import {
  Thumbnail,
  TextContainer,
  Card,
  Button,
  Heading,
  TopBar,
  Stack,
  Modal as ShopifyModal,
  Icon,
  RadioButton,
} from "@shopify/polaris";
import ErrorMessage from "../ErrorMessage";
import { NoteMinor, CancelSmallMinor } from "@shopify/polaris-icons";
import {
  activeHost,
  fileUploadDrupal,
  fileUploadToMediaEntityDrupal,
  uploadFiles,
} from "../../../Helpers/DrupalHelper";
import { StateContext } from "../../../Context/StateContext";
import { useDropzone } from "react-dropzone";

const Container = styled.div`
  padding: 8px;
  transition: background-color 0.2s ease;
  flex-grow: 1;
  display: flex;
`;
const style = {
  position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: "scroll",
};
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
function StyledDropzone(props) {
  const [files, setFiles] = useState([]);
  const [show, setShow] = useState(true);
  const { loading } = props;
  const onDrop = React.useCallback((acceptedFiles) => {
    setFiles((prev) => [
      ...prev,
      ...acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    ]);
    // setFiles((prev) => [...prev, ...acceptedFiles]);
    // props.setRootFiles((prev) => [...prev, ...acceptedFiles]);
  }, []);
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    // getFilesFromEvent: (event) => defaultFileGetter(event),
    onDrop,
  });
  useEffect(() => {
    props.setRootFiles(files);
  }, [files]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      fontSize: 14,
      alignItems: "flex-start",
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const handleRemoveClick = (i) => {
    if (files.length == 1) {
      setShow(true);
      setFiles([]);
    } else {
      const tempArr = [...files];
      tempArr.splice(i, 1);
      // setValue(`${name}`, [...tempArr]);

      setFiles([...tempArr]);
    }
  };
  return (
    <div className="container">
      {show && (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>
            {props.type === "image"
              ? "Drag 'n' drop images here, or click to select images"
              : "Drag 'n' drop some files here, or click to select files"}
          </p>
        </div>
      )}

      {files !== null && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginLeft: -10,
            marginRight: -10,
            marginTop: show && files.length > 0 ? 10 : 0,
          }}
        >
          {files.map((file, i) => {
            let size = file.size / 1024 / 1024;
            return (
              <div
                style={{
                  flexGrow: 1,
                  width: "33.3%",
                  paddingTop: show || files.length > 3 ? 10 : 0,
                  paddingBottom: show || files.length > 3 ? 10 : 0,
                  paddingLeft: 10,
                  paddingRight: 10,
                  display: "flex",
                  alignItems: "center",
                  boxSizing: "border-box",
                }}
                key={file.path}
              >
                <Thumbnail
                  source={
                    file.type.includes("image") ? file.preview : NoteMinor
                  }
                  alt={file.name}
                />
                <div
                  style={{
                    color: "rgba(0,0,0,.8)",
                    marginTop: 5,
                    marginBottom: 5,
                    marginLeft: 10,
                  }}
                >
                  <strong>{file.name}</strong>
                  <p>{size.toFixed(2)} MB</p>
                  <Button
                    plain
                    destructive
                    onClick={() => handleRemoveClick(i)}
                    disabled={loading === true ? true : false}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
function ChildModal(props) {
  // console.log(props);
  const [open, setOpen] = useState(false);
  //   const handleOpen = () => {
  //     setOpen(true);
  //   };
  useEffect(async () => {
    setOpen(props.open);
  }, [props.open]);
  const handleClose = () => {
    setOpen(false);
    props.close(false);
  };
  const insertData = () => {
    props.makeOutput(props.value);
    props.makeOutput(props.value);

    if (props.data.value_limit === 1) {
      props.setValue(`${props.name}`, [
        { target_id: parseInt(props.value.id) },
      ]);
    } else {
      if (props.output !== null) {
        const prevOutput = props.output.map((v) => {
          return { target_id: parseInt(v.id) };
        });
        props.setValue(`${props.name}`, [
          ...prevOutput,
          { target_id: parseInt(props.value.id) },
        ]);
      } else {
        // const prevOutput = props.output.map((v) => v.id);
        props.setValue(`${props.name}`, [
          { target_id: parseInt(props.value.id) },
        ]);
      }
    }
    props.onClose(false);
    props.close(false);
  };

  return (
    <React.Fragment>
      <Modal
        // hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            maxWidth: "60vw",
            textAlign: " center ",
            margin: "auto",
            maxHeight: "80vh",
            justifyContent: "space-between",
            // minHeight: "100vh",
            backgroundColor: "#fff",
            display: "flex",
            padding: "30px",
            position: "absolute",
            flexDirection: "column",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            boxShadow:
              "rgba(0, 0, 0, 0.2) 0px 26px 80px 0px, rgba(0, 0, 0, 0.2) 0px 0px 1px 0px",
          }}
        >
          <button
            class="Polaris-Modal-CloseButton"
            aria-label="Close"
            onClick={handleClose}
            style={{ position: "absolute", top: 30, right: 40 }}
          >
            <span class="Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor">
              <span class="Polaris-VisuallyHidden"></span>
              <svg
                viewBox="0 0 20 20"
                class="Polaris-Icon__Svg"
                focusable="false"
                aria-hidden="true"
              >
                <path d="m11.414 10 6.293-6.293a1 1 0 1 0-1.414-1.414L10 8.586 3.707 2.293a1 1 0 0 0-1.414 1.414L8.586 10l-6.293 6.293a1 1 0 1 0 1.414 1.414L10 11.414l6.293 6.293A.998.998 0 0 0 18 17a.999.999 0 0 0-.293-.707L11.414 10z"></path>
              </svg>
            </span>
          </button>
          <div
            style={{
              maxHeight: "62vh",
              margin: "auto",
              maxWidth: "90%",
            }}
          >
            <img
              src={props.value?.url}
              width="400px"
              height="500px"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>
          <div style={{ marginTop: 30, marginBottom: 15 }}>
            {/*<Heading>Media Entity ID: {props.value.id}</Heading>*/}
            <Heading>Filename: {props.value.name}</Heading>
            {/*JSON.stringify(props)*/}
            <br />
            <button
              onClick={insertData}
              class="Polaris-Button Polaris-Button--primary"
              type="button"
            >
              <span class="Polaris-Button__Content">
                <span class="Polaris-Button__Text">Insert media</span>
              </span>
            </button>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
const UploadNew = ({ token, bundles, active, setActive, triggerRefresh }) => {
  const [activeE, setActiveE] = useState(false);
  const [bundle, setBundle] = useState("asset_file");
  const [files, setFiles] = useState([]);
  const handleChange = useCallback(() => setActive(!active), [active]);
  const handleRadioChange = useCallback(
    (_checked, newValue) => setBundle(newValue),
    []
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (files.length) setActiveE(false);
  }, [files]);
  const makeUpload = async () => {
    if (files.length) {
      let formData = new FormData();
      setLoading(true);

      // console.log(files);
      // console.log("files");

      // let newFiles = [];
      // files.map((i,k ) => {
      //   let filePath = i.path;
      //   filePath = filePath.replace(/[^a-zA-Z0-9_.]/, '')
      //   console.log(filePath)
      //   console.log("filePath")
      //   newFiles.push({...i, path: filePath})
      // })
      files.map(async (v, k) => {
        let res = false;
        try {
          res = await fileUploadDrupal(token, v);
        } catch (e) {
          setLoading(false);
          // setActive(false)
          console.log(e);
          console.log("e");
          setError(e);
        }

        if (res !== false) {
          // console.log(res);
          if (res.response.ok) {
            let res2 = await fileUploadToMediaEntityDrupal(
              token,
              bundle,
              res.data
            );
            setLoading(false);
            setActive(false);

            triggerRefresh();
          } else {
            setLoading(false);

            if ("data" in res && "message" in res.data) {
              setError(res.data.message);
            } else {
              setError(
                "There was an unidentified error in processing the file. It has not been uploaded. Please check that the filename includes only alphanumeric characters, hyphens, and underscores."
              );
            }
          }
        }
      });

      // formData = JSON.stringify(Object.fromEntries(formData));

      // let res = await fileUploa(token, bundle, formData);
    } else setActiveE(true);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "70%",
    // bgcolor: "background.paper",
    pt: 2,
    px: 4,
    pb: 3,
  };
  return (
    <Modal
      open={active}
      onClose={handleChange}
      aria-labelledby="Upload-modal-title"
      aria-describedby="Upload-modal-description"
      // onClose={handleChange}
    >
      <Box sx={{ ...style, width: 400 }}>
        <div class="Polaris-Modal-Dialog__Modal">
          <div class="Polaris-Modal-Header">
            <div id="Polarismodal-header6" class="Polaris-Modal-Header__Title">
              <h2 class="Polaris-DisplayText Polaris-DisplayText--sizeSmall">
                Upload new media
              </h2>
            </div>
            <button
              class="Polaris-Modal-CloseButton"
              aria-label=""
              onClick={handleChange}
            >
              <Icon source={CancelSmallMinor} />
            </button>
          </div>
          <div class="Polaris-Modal__BodyWrapper">
            <div
              class="Polaris-Modal__Body Polaris-Scrollable Polaris-Scrollable--vertical Polaris-Scrollable--hasBottomShadow Polaris-Scrollable--verticalHasScrolling"
              data-polaris-scrollable="true"
            >
              <section class="Polaris-Modal-Section">
                <div class="Polaris-Stack Polaris-Stack--vertical">
                  <div class="Polaris-Stack__Item Polaris-Stack__Item--fill">
                    <div className="flex ">
                      <RadioButton
                        label={"Asset File"}
                        checked={bundle === "asset_file"}
                        name="bundle"
                        onChange={handleRadioChange}
                        value={"asset_file"}
                        id={"asset_file"}
                      />
                      {/* {Object.entries(bundles).map((val, k) => {
                        return (
                          <div className="mr-2">
                            <RadioButton
                              label={val[1]}
                              checked={bundle === val[0]}
                              name="bundle"
                              onChange={handleRadioChange}
                              value={val[0]}
                              id={val[0]}
                            />{" "}
                          </div>
                        );
                      })} */}
                    </div>
                    <StyledDropzone setRootFiles={setFiles} loading={loading} />
                    {activeE && (
                      <span className="text-red-600">Please select files</span>
                    )}
                    {error !== null && (
                      <>
                        {typeof error === "object"
                          ? "There was an unidentified error processing the file."
                          : error}
                      </>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div class="Polaris-Modal-Footer">
            <div class="Polaris-Modal-Footer__FooterContent">
              <div class="Polaris-Stack Polaris-Stack--alignmentCenter">
                <div class="Polaris-Stack__Item Polaris-Stack__Item--fill"></div>
                <div class="Polaris-Stack__Item">
                  <div class="Polaris-ButtonGroup">
                    <div class="Polaris-ButtonGroup__Item">
                      <Button
                        onClick={handleChange}
                        disabled={loading === true ? true : false}
                      >
                        <div className="flex items-center">
                          {" "}
                          Close <Icon source={CancelSmallMinor} />
                        </div>
                      </Button>
                    </div>
                    <div class="Polaris-ButtonGroup__Item">
                      <Button
                        primary
                        onClick={makeUpload}
                        loading={loading === true ? true : false}
                      >
                        Upload{" "}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
function MediaLibaryModal(props) {
  const [open, setOpen] = useState(false);
  const [childModal, setChildModal] = useState(false);
  const [childModalVal, setChildModalVal] = useState(null);
  const [active, setActive] = useState(false);
  const [list, setList] = useState(null);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  // const [refresh, setRefresh] = useState(false);

  const refresh = () => {
    getData(1).then((data) => {
      if (data.length) {
        setList(data);
        // setLoadMore(false);
        // setRefresh(false)
      }
    });
  };
  useEffect(async () => {
    setOpen(props.open);
  }, [props.open]);

  let firstBody = {
    title: "",
    bundle: "",
    file_type: [],
    published: true,
  };
  const [body, setBody] = useState(firstBody);
  const loadRef = useRef();
  // console.log(allProject);
  const getData = async (number = 1, body) => {
    if (body) {
      return fetch(`${activeHost()}/api/o2o/media/search?page=${number}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.token,
        },
        body: JSON.stringify(body),
      }).then((response) => response.json());
    } else {
      return fetch(`${activeHost()}/api/o2o/media/all?page=${number}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.token,
        },
      }).then((response) => response.json());
    }
  };
  useEffect(() => {
    if (props.data.required) {
      props.register(props.name, { required: "This field is required" });
    } else {
      props.register(props.name);
    }
  }, []);

  const handleObserver = (entities) => {
    const target = entities[0];
    // console.log(target);
    if (target.isIntersecting) {
      setLoadMore(true);
    }
  };
  useEffect(() => {
    var options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loadRef.current) {
      observer.observe(loadRef.current);
    }
    // setHasMore(true);
  }, [loadRef.current]);
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list?.length;
      // alert(currentLength)
      const pageNo = Math.floor(currentLength / 20) + 1;
      // alert(pageNo)
      // console.log(pageNo);
      if (JSON.stringify(firstBody) !== JSON.stringify(body)) {
        getData(pageNo, body).then((data) => {
          // console.log(data);
          if (data.length) {
            setList([...list, ...data]);
            setLoadMore(false);
          } else {
            setHasMore(false);
          }
        });
      } else {
        getData(pageNo).then((data) => {
          // console.log(data);
          if (data.length) {
            setList([...list, ...data]);
            setLoadMore(false);
          } else {
            setHasMore(false);
          }
        });
      }
    }
  }, [loadMore, hasMore]);

  useEffect(() => {
    if (list !== null) {
      //   const val = Math.floor(list.length / 20);
      // if()
      let a = list.length % 20;
      setHasMore(a === 0 ? true : false);
    }
  }, [list]); //eslint-disable-line

  const handleClose = () => {
    setOpen(false);
    props.onClose(false);
  };

  useEffect(async () => {
    getData(1).then((data) => {
      // console.log(data);
      setList(data);
    });
  }, []);
  const modalActivate = (v, k) => {
    // console.log(v);
    setChildModal(true);
    setChildModalVal(v);
  };
  useEffect(async () => {
    if (JSON.stringify(firstBody) === JSON.stringify(body)) {
      getData(1).then((data) => {
        // console.log(data);
        setList(data);
      });
      console.log("first");
    }
  }, [body]);
  const handleSearchChange = (v) => {
    if (v.length) {
      let fbody = {
        ...body,
        title: v,
      };
      getData(1, fbody).then((data) => {
        // console.log(data);
        setList(data);
      });
      setBody(fbody);
    } else {
      setBody({
        ...body,
        title: "",
      });
    }
  };
  const handleSelectChange = (v) => {
    console.log(v);
    if (v.length) {
      let fbody = {
        ...body,
        file_type: v.map((v) => v.value),
      };
      getData(1, fbody).then((data) => {
        // console.log(data);
        setList(data);
      });
      setBody(fbody);
    } else {
      setBody({
        ...body,
        file_type: [],
      });
    }
  };
  const handleCheckboxChange = (event) => {
    console.log(event.currentTarget.checked);

    let fbody = {
      ...body,
      published: event.currentTarget.checked,
    };
    // if (event.currentTarget.checked)
    getData(1, fbody).then((data) => {
      // console.log(data);
      setList(data);
    });

    setBody(fbody);
  };
  const { entities, token } = useContext(StateContext).state;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={{ overflow: "scroll", outline: "none" }}
        // disablePortal
      >
        <Box
          sx={{
            ...style,
            // width: "100%",
            background: "rgba(246, 246, 247, 1)",
            minHeight: "100vh",
            width: "100vw",
            boxSizing: "border-box",
            overflowY: "scroll",
            paddingTop: "100px",
            outline: "none",
          }}
        >
          <div
            style={{
              position: "fixed",
              background: "rgba(246, 246, 247, 1)",
              width: "100%",
              // height: 50,
              zIndex: 1,
              top: 0,
              left: 0,
              right: 0,
            }}
          >
            <div
              class="Polaris-Modal-Header"
              style={{ borderBottom: "1px solid rgba(0,0,0,.1)" }}
            >
              <div
                id="Polarismodal-header12"
                class="Polaris-Modal-Header__Title flex justify-between"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h2 class="Polaris-DisplayText Polaris-DisplayText--sizeSmall">
                  Select Media
                </h2>
                <div className="flex" style={{ minWidth: "40%" }}>
                  <div style={{ maxWidth: "50%" }}>
                    <TopBar.SearchField
                      onChange={handleSearchChange}
                      // value={searchValue}
                      placeholder="Search Media"
                      // style={{ width: "80%" }}
                      showFocusBorder
                      // onFocus={() => setActive(true)}
                      // onBlur={() => setSearchResults([]) && setSearchValue("")}
                    />
                  </div>
                  <div className="ml-3" style={{ minWidth: "30%" }}>
                    <Select
                      options={[
                        // {
                        //   label: "All Files",
                        //   value: [],
                        // },
                        {
                          value: "png",
                          label: "PNG",
                        },
                        {
                          value: "jpg",
                          label: "JPG",
                        },
                        {
                          value: "webp",
                          label: "WEBP",
                        },
                        {
                          value: "pdf",
                          label: "PDF",
                        },
                        {
                          value: "docx",
                          label: "DOCX",
                        },
                        {
                          value: "xlsx",
                          label: "XLSX",
                        },
                        {
                          value: "pptx",
                          label: "PPTX",
                        },
                      ]}
                      isSearchable={true}
                      onChange={handleSelectChange}
                      // styles={customStyles}
                      isClearable={true}
                      isMulti
                      placeholder="All Files"
                      styles={{
                        container: (provided, state) => ({
                          ...provided,
                          // width: " 20%",
                          flexGrow: 1,
                          // maxWidth: "50%",
                          paddingLeft: "5px",
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          flexWrap: "nowrap",
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: "rgba(0,0,0,.1)",
                        }),
                        indicatorSeparator: (provided, state) => ({
                          ...provided,
                          backgroundColor: "transparent",
                        }),
                      }}
                    />
                  </div>
                  <div className="flex items-center align-center ml-3">
                    <label className="Polaris-Choice" htmlFor={"publish"}>
                      <span
                        className="Polaris-Choice__Control"
                        style={{
                          top: "-2px",
                          position: "relative",
                          // border: errors[name] && "1px red solid",
                          borderRadius: "2px",
                        }}
                      >
                        <span className="Polaris-Checkbox">
                          <input
                            type="checkbox"
                            name={"publish"}
                            className="Polaris-Checkbox__Input"
                            id={"publish"}
                            // ref={register({ required: true })}
                            defaultChecked={true}
                            onChange={handleCheckboxChange}
                          />

                          <span className="Polaris-Checkbox__Backdrop"></span>
                          <span className="Polaris-Checkbox__Icon">
                            <span className="Polaris-Icon">
                              <svg
                                viewBox="0 0 20 20"
                                className="Polaris-Icon__Svg"
                                focusable="false"
                                aria-hidden="true"
                              >
                                <path d="M8.315 13.859l-3.182-3.417a.506.506 0 0 1 0-.684l.643-.683a.437.437 0 0 1 .642 0l2.22 2.393 4.942-5.327a.436.436 0 0 1 .643 0l.643.684a.504.504 0 0 1 0 .683l-5.91 6.35a.437.437 0 0 1-.642 0"></path>
                              </svg>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span className="Polaris-Choice__Label">Published</span>
                    </label>
                  </div>
                  <div className="ml-4">
                    <button
                      class="Polaris-Button Polaris-Button--primary"
                      type="button"
                      onClick={() => setActive(true)}
                    >
                      <span class="Polaris-Button__Content">
                        <span class="Polaris-Button__Text">Upload New</span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <button
                class="Polaris-Modal-CloseButton"
                aria-label="Close"
                onClick={handleClose}
              >
                <span class="Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor">
                  <span class="Polaris-VisuallyHidden"></span>
                  <svg
                    viewBox="0 0 20 20"
                    class="Polaris-Icon__Svg"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <path d="m11.414 10 6.293-6.293a1 1 0 1 0-1.414-1.414L10 8.586 3.707 2.293a1 1 0 0 0-1.414 1.414L8.586 10l-6.293 6.293a1 1 0 1 0 1.414 1.414L10 11.414l6.293 6.293A.998.998 0 0 0 18 17a.999.999 0 0 0-.293-.707L11.414 10z"></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <div>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              {list !== null &&
                list.map((v, k) => {
                  //   console.log(v);
                  return (
                    <Grid
                      item
                      xs={2}
                      sm={4}
                      md={4}
                      lg={2}
                      key={k}
                      // style={{ border: "1px solid blue" }}
                      onClick={() => modalActivate(v, k)}
                    >
                      <div
                        class="Polaris-Card"
                        style={{
                          // boxShadow:
                          //   "rgba(23, 24, 24, 0.05) 0px 0px 5px 0px, rgba(0, 0, 0, 0.15) 0px 1px 2px 0px",
                          // borderRadius: 4,
                          overflow: "hidden",
                          // backgroundColor: "#fff",
                          marginTop: 10,
                          marginBottom: 10,
                          marginLeft: 10,
                          marginRight: 10,
                        }}
                      >
                        <div
                          class="Polaris-MediaCard"
                          style={{
                            flexDirection: "column",
                          }}
                        >
                          <div class="Polaris-MediaCard__MediaContainer Polaris-MediaCard--portrait">
                            <img
                              alt=""
                              width="100%"
                              height="100%"
                              src={v.url}
                              style={{
                                objectFit: "cover",
                                objectPosition: "center center",
                                height: 200,
                                width: "100%",
                                // maxHeight: 300
                                // maxHeight:
                              }}
                            />
                          </div>
                          <div class="Polaris-MediaCard__InfoContainer">
                            <div class="Polaris-Card__Section">
                              <div class="Polaris-MediaCard__Heading">
                                <p class="" style={{ whiteSpace: "nowrap" }}>
                                  {v.name.length > 20
                                    ? v.name.substring(0, 20) + "..."
                                    : v.name}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
            <div
              className="load-more-area flex justify-center items-center"
              ref={loadRef}
            >
              {hasMore ? (
                <div className=" justify-center items-center text-center mt-50 mb-50">
                  <CircularProgress thickness={1.3} />
                </div>
              ) : (
                <p>No more results</p>
              )}
            </div>
          </div>

          {childModal && (
            <ChildModal
              {...props}
              open={childModal}
              close={setChildModal}
              value={childModalVal}
            />
          )}
        </Box>
      </Modal>
      <UploadNew
        active={active}
        setActive={setActive}
        triggerRefresh={() => refresh()}
        bundles={entities?.media?.bundles}
        token={token}
      />
    </div>
  );
}
function Media({
  token,
  data,
  name,
  label,
  errors,
  register,
  setValue,
  watch,
  unregister,
  control,
  location,
}) {
  const { value_limit } = data;
  const [showModal, setShowModal] = useState(false);
  const [output, setOutput] = useState(null);

  // useEffect(() => {
  //   setValue(name, output);
  // }, [output]);
  const removeSingle = () => {
    setOutput(null);
    setValue(`${name}`, null);
  };
  const removeItem = (i) => {
    const tempArr = [...output];

    let val = watch(`${name}`);
    let temparrVal = val.filter(
      (v) => v.target_id !== output[i].id || v.isDragAccept !== output[i].id
    );
    // console.log(temparrVal);
    setValue(`${name}`, temparrVal);
    tempArr.splice(i, 1);
    setOutput([...tempArr]);
  };
  const makeOutput = (v) => {
    if (data.value_limit === 1 && data.value_limit !== false) {
      setOutput(v);
    } else {
      let val = output;
      if (val !== null) {
        setOutput([...val, v]);
      } else {
        setOutput([v]);
        // setValue(name, [...data.current_value, {target_id: parseFloat(v.id)}])
      }
    }
  };
  useEffect(() => {
    if (data.current_value) {
      if (data.value_limit === 1 && data.value_limit !== false) {
        let cImg = data.current_value[0];
        if (cImg)
          setOutput({
            thumb: cImg?.thumb ? cImg.thumb : cImg.file_url,
            id: cImg.target_id ? cImg.target_id : cImg.id,
            filetype: "png/image",
            title: cImg.title,
            file_url: cImg.file_url,
          });
      } else {
        if (data.current_value.length) {
          let outputData = data.current_value.map((cImg) => ({
            thumb: cImg?.file_url ? cImg.file_url : cImg.thumb,
            file_url: cImg.file_url,
            id: cImg.target_id,
            filetype:
              cImg.file_url.includes("png") || cImg.file_url.includes("jpg")
                ? "png/image"
                : "File",
            title: cImg.title,
          }));
          setOutput(outputData);
        }
      }
      setValue(`${name}`, data.current_value);
    }
  }, []);
  let valueMeta = `Maximum <b>${
    value_limit === false ? "unlimited" : value_limit
  }</b> files`;

  return (
    <>
      <div>
        <div className="Polaris-Labelled__LabelWrapper">
          <div className="Polaris-Label">
            <label
              id="PolarisTextField2Label"
              htmlFor={name}
              className="Polaris-Label__Text"
            >
              {label}
              {data.required ? "*" : ""}
            </label>
          </div>
        </div>
        <div
          className="Polaris-Labelled__HelpText"
          id="PolarisTextField2HelpText"
          style={{ marginBottom: 10 }}
        >
          <span dangerouslySetInnerHTML={{ __html: valueMeta }}></span>
        </div>
        <Card>
          <div style={{ padding: 20 }}>
            {data.value_limit === 1 && output !== null ? (
              <button
                onClick={() => removeSingle(true)}
                className={`Polaris-Button Polaris-Button--primary`}
                type="button"
                style={{
                  borderRadius: "3px",
                }}
              >
                <span className="Polaris-Button__Content">
                  <span className="Polaris-Button__Text">
                    {data.value_limit === 1 && output !== null
                      ? "Remove Media"
                      : "Add Media"}
                  </span>
                </span>
              </button>
            ) : (
              <button
                onClick={() => setShowModal(true)}
                className={`Polaris-Button Polaris-Button--primary`}
                type="button"
                style={{
                  borderRadius: "3px",
                }}
              >
                <span className="Polaris-Button__Content">
                  <span className="Polaris-Button__Text">
                    {data.value_limit === 1 && output !== null
                      ? "Remove Media"
                      : "Add Media"}
                  </span>
                </span>
              </button>
            )}
            {output !== null && data.value_limit === 1 && (
              <div style={{ marginTop: 10 }}>
                <Thumbnail
                  maxWidth={"200px"}
                  source={output.thumb || output.thumbnail}
                  alt={output.id}
                />
              </div>
            )}

            {output !== null && data.value_limit !== 1 && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginLeft: -10,
                  marginRight: -10,
                  marginTop: 10,
                }}
              >
                {output.map((file, i) => {
                  console.log(file);
                  return (
                    <div
                      style={{
                        flexGrow: 0,
                        width: "33.3%",
                        paddingTop: output.length > 3 ? 10 : 0,
                        paddingBottom: output.length > 3 ? 10 : 0,
                        paddingLeft: 10,
                        paddingRight: 10,
                        display: "flex",
                        alignItems: "center",
                        boxSizing: "border-box",
                      }}
                      key={i}
                    >
                      <div
                        role={"none"}
                        onClick={() => {
                          window.open(file.file_url, "_blank");
                        }}
                      >
                        <Thumbnail
                          maxWidth={"200px"}
                          source={file.thumb || file.thumbnail}
                          alt={file.id}
                        />
                      </div>
                      <div
                        style={{
                          color: "rgba(0,0,0,.8)",
                          marginTop: 5,
                          marginBottom: 5,
                          marginLeft: 10,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {/*<strong style={{marginBottom: 10}}>{file.id}</strong>*/}
                        <Button size="slim" onClick={() => removeItem(i)}>
                          Remove
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {errors && errors[name] && (
            <ErrorMessage message={errors[name]["message"]} />
          )}
        </Card>
      </div>
      {showModal && (
        <MediaLibaryModal
          open={showModal}
          onClose={setShowModal}
          name={name}
          setValue={setValue}
          output={output}
          register={register}
          data={data}
          makeOutput={makeOutput}
          token={token}
          // limit={data.value_limit}
        />
      )}
    </>
  );
}

export default Media;
