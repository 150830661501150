import React, { useState } from "react";
import ErrorMessage from "../ErrorMessage";
import ValueInfinity from "./ValueLimit";

const String = ({ data, errors, name, register, setValue, typeName }) => {
  let { label, default_value, description, required } = data;
  let defaultVal = default_value;
  if (data.current_value) {
    defaultVal = data.current_value;
  }
  const [disabled, setDisabled] = useState(
    typeName === "location" ? true : name === "field_asset_sku" ? true : false
  );

  if (data.hasOwnProperty("value_limit")) {
    if (data.value_limit === 1 || data.value_limit === "") {
      return (
        <div key={name}>
          <div className="">
            <div className="Polaris-Labelled__LabelWrapper">
              <div className="Polaris-Label">
                <label
                  id="PolarisTextField2Label"
                  htmlFor={name}
                  className="Polaris-Label__Text"
                >
                  {label}
                  {data.required ? "*" : ""}
                </label>
              </div>
            </div>
            <div className="Polaris-Connected">
              <div className="Polaris-Connected__Item Polaris-Connected__Item--primary inline">
                <div className="Polaris-TextField Polaris-TextField--hasValue">
                  {required && disabled !== true ? (
                    data.type !== "address" ? (
                      <input
                        type="address"
                        className="Polaris-TextField__Input"
                        name={name}
                        disabled={disabled}
                        id={name}
                        defaultValue={defaultVal}
                        {...register(name, {
                          required: `This field is required`,
                        })}
                      />
                    ) : (
                      <input
                        type="text"
                        className="Polaris-TextField__Input"
                        name={name}
                        id={name}
                        disabled={disabled}
                        defaultValue={defaultVal}
                        {...register(name, {
                          required: `This field is required`,
                        })}
                      />
                    )
                  ) : disabled !== true ? (
                    <input
                      type="text"
                      className="Polaris-TextField__Input"
                      name={name}
                      id={name}
                      disabled={disabled}
                      defaultValue={defaultVal}
                      {...register(name)}
                    />
                  ):(
                    <input
                      type="text"
                      className="Polaris-TextField__Input"
                      name={name}
                      id={name}
                      disabled={disabled}
                      defaultValue={defaultVal}
                      //{...register(name)}
                    />
                  )
                   }
                  <div className="Polaris-TextField__Backdrop"></div>
                </div>
                {disabled && name == "field_asset_sku" && (
                  <button
                    class="Polaris-Button Polaris-Button--primary"
                    type="button"
                    onClick={() => {
                      setDisabled(false);
                    }}
                  >
                    <span class="Polaris-Button__Content">
                      <span class="Polaris-Button__Text">
                        Assign Custom SKU
                      </span>
                    </span>
                  </button>
                )}
              </div>
            </div>
            <div
              className="Polaris-Labelled__HelpText"
              id="PolarisTextField2HelpText"
            >
              <span dangerouslySetInnerHTML={{ __html: description }}></span>
            </div>
          </div>
          <div id="PolarisPortalsContainer"></div>
          {errors && errors[name] && (
            <ErrorMessage message={errors[name]["message"]} />
          )}
        </div>
      );
    } else {
      return (
        <ValueInfinity
          errors={errors}
          data={data}
          name={name}
          register={register}
          setValue={setValue}
        />
      );
    }
  } else {
    return (
      <div key={name}>
        <div className="">
          <div className="Polaris-Labelled__LabelWrapper">
            <div className="Polaris-Label">
              <label
                id="PolarisTextField2Label"
                htmlFor={name}
                className="Polaris-Label__Text"
              >
                {label}
              </label>
            </div>
          </div>
          <div className="Polaris-Connected">
            <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
              <div className="Polaris-TextField Polaris-TextField--hasValue">
                {required && disabled === false ? (
                  <input
                    type="text"
                    className="Polaris-TextField__Input"
                    name={name}
                    id={name}
                    disabled={disabled}
                    defaultValue={defaultVal}
                    {...register(name, {
                      required: `This field is required`,
                    })}
                  />
                ) : disabled !== true ? (
                    <input
                      type="text"
                      className="Polaris-TextField__Input"
                      name={name}
                      id={name}
                      disabled={disabled}
                      defaultValue={defaultVal}
                      {...register(name)}
                    />
                  ):(
                    <input
                      type="text"
                      className="Polaris-TextField__Input"
                      name={name}
                      id={name}
                      disabled={disabled}
                      defaultValue={defaultVal}
                      //{...register(name)}
                    />
                  )
                    }
                <div className="Polaris-TextField__Backdrop"></div>
              </div>
            </div>
          </div>
          <div
            className="Polaris-Labelled__HelpText"
            id="PolarisTextField2HelpText"
          >
            <span dangerouslySetInnerHTML={{ __html: description }}></span>
          </div>
        </div>
        <div id="PolarisPortalsContainer"></div>
        {errors && errors[name] && (
          <ErrorMessage message={errors[name]["message"]} />
        )}
      </div>
    );
  }
};

export default String;
