import React, { useEffect, useState } from "react";
import { entityForm } from "../Helpers/DrupalHelper";
import Form from "../Components/Form/DynamicForm";
import {
  Card,
  TextContainer,
  SkeletonBodyText,
  Loading,
} from "@shopify/polaris";
import BreadCrumb from "../Components/Elements/BreadCrumb";
import { toCapitalCase } from "../utils";

const AddNewTemplate = ({ token, name, bundle, isSaved, nested }) => {
  console.log(name, bundle);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  console.log({ token, name, bundle });
  const fetchFormData = async () => {
    await entityForm(token, name, bundle).then(async (data) => {
      setFormData(data);
      setLoading(false);
      console.log(data);
    });
  };
  useEffect(() => {
    fetchFormData();
  }, []);
  useEffect(async () => {
    setLoading(true);

    await fetchFormData();
  }, [name, bundle]);
  useEffect(() => {
    if (!nested) window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return loading ? (
    <>
      <Loading />

      {[...Array(20)].map((e, i) => {
        return (
          <Card.Section key={i}>
            <TextContainer>
              <SkeletonBodyText lines={1} key={i} />
            </TextContainer>
          </Card.Section>
        );
      })}
    </>
  ) : (
    <div>
      <BreadCrumb
        list={[
          { title: name, link: `/${name}` },
          { title: `Create New ${toCapitalCase(name)}` },
        ]}
      />
      <Form
        data={formData}
        token={token}
        addNew={true}
        name={name}
        bundle={bundle}
        isSaved={isSaved}
        nested={nested}
      />
    </div>
  );
};
export default AddNewTemplate;
