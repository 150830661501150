import React, { useEffect, useMemo, useState } from "react";
import { Thumbnail, Card } from "@shopify/polaris";
import { NoteMinor } from "@shopify/polaris-icons";
import { useDropzone } from "react-dropzone";
import imageCompression from "browser-image-compression";
import { Button } from "@shopify/polaris";
import ErrorMessage from "../ErrorMessage";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
async function defaultFileGetter(event) {
  const files = [];
  const fileList = event.dataTransfer
    ? event.dataTransfer.files
    : event.target.files;

  for (var i = 0; i < fileList.length; i++) {
    const file = fileList.item(i);

    Object.defineProperty(file, "myProp", {
      value: true,
    });

    files.push(file);
  }

  return files;
}
function handleImageUpload(event) {
  var imageFile = event[0];
  console.log(event);
  console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
  console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

  var options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 720,
    useWebWorker: true,
  };
  imageCompression(imageFile, options)
    .then(function (compressedFile) {
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      // return uploadToServer(compressedFile); // write your own logic
    })
    .catch(function (error) {
      console.log(error.message);
    });
}
function StyledDropzone(props) {
  const [files, setFiles] = useState([]);
  const [show, setShow] = useState(true);
  useEffect(() => {
    if (!Array.isArray(props.current_value)) {
      let f = props.current_value;
      if (f !== null && typeof f === "object") {
        if (f?.url) {
          setFiles([
            {
              ...f,
              path: f.url,
              size: f.bytes,
            },
          ]);
        }
      }

      // setFiles([
      //   {
      //     ...f,
      //     path: f.url,
      //     size: f.bytes,
      //   },
      // ]);
    }
  }, []);
  const onDrop = React.useCallback((acceptedFiles) => {
    if (props.data.value_limit === 1) {
      if (props.type === "image") handleImageUpload(acceptedFiles);
      setFiles([...acceptedFiles]);
    } else {
      if (props.type === "image") handleImageUpload(acceptedFiles);
      setFiles((prev) => [...prev, ...acceptedFiles]);
    }
  }, []);
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: props.type === "image" ? "image/*" : null,
    maxFiles: props.data.value_limit === 1 ? 1 : 0,
    // getFilesFromEvent: (event) => defaultFileGetter(event),
    onDrop,
  });
  const acceptedFileItems =
    files &&
    files.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size / 1024 / 1024} MB
        {props.type === "image" && <img src={file.path} />}
      </li>
    ));
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      fontSize: 14,
      alignItems: "flex-start",
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  useEffect(() => {
    if (
      props.data.value_limit === 1 &&
      props.current_value !== null &&
      typeof props.current_value === "object" &&
      !Array.isArray(props.current_value)
    )
      setShow(false);
  }, []);
  const handleRemoveClick = (i) => {
    if (files.length == 1) {
      setShow(true);
      setFiles([]);
    } else {
      const tempArr = [...files];
      tempArr.splice(i, 1);
      // setValue(`${name}`, [...tempArr]);

      setFiles([...tempArr]);
    }
  };
  return (
    <div className="container">
      {show && (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>
            {props.type === "image"
              ? "Drag 'n' drop images here, or click to select images"
              : "Drag 'n' drop some files here, or click to select files"}
          </p>
        </div>
      )}

      {files !== null && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginLeft: -10,
            marginRight: -10,
            marginTop: show && files.length > 0 ? 10 : 0,
          }}
        >
          {files.map((file, i) => {
            let size = file.size / 1024 / 1024;
            return (
              <div
                style={{
                  flexGrow: 1,
                  width: "33.3%",
                  paddingTop: show || files.length > 3 ? 10 : 0,
                  paddingBottom: show || files.length > 3 ? 10 : 0,
                  paddingLeft: 10,
                  paddingRight: 10,
                  display: "flex",
                  alignItems: "center",
                  boxSizing: "border-box",
                }}
                key={file.path}
              >
                <Thumbnail
                  source={file.type.includes("image") ? file.path : NoteMinor}
                  alt={file.name}
                />
                <div
                  style={{
                    color: "rgba(0,0,0,.8)",
                    marginTop: 5,
                    marginBottom: 5,
                    marginLeft: 10,
                  }}
                >
                  <strong>{file.name}</strong>
                  <p>{size.toFixed(2)} MB</p>
                  <Button
                    plain
                    destructive
                    onClick={() => handleRemoveClick(i)}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
const File = ({ data, errors, name, register }) => {
  let {
    label,
    default_value,
    description,
    required,
    current_value,
    value_limit,
  } = data;
  const [file, setFile] = useState([]);
  if (data.current_value) {
    default_value = current_value;
  }

  let valueMeta = `Maximum <b>${
    value_limit && value_limit === false
      ? "unlimited"
      : !value_limit
      ? "unlimited"
      : value_limit
  }</b> files`;
  // useEffect(() => {
  //   if (data.current_value) {
  //     let f = data.current_value;
  //     setFile([
  //       {
  //         ...f,
  //         path: f.url,
  //         size: f.bytes,
  //       },
  //     ]);
  //   }
  // }, []);
  return (
    <div key={name}>
      <div className="Polaris-Labelled__LabelWrapper">
        <div className="Polaris-Label">
          <label
            id="PolarisTextField2Label"
            htmlFor={name}
            className="Polaris-Label__Text"
          >
            {label}
            {data.required ? "*" : ""}
          </label>
        </div>
      </div>
      <div
        className="Polaris-Labelled__HelpText"
        id="PolarisTextField2HelpText"
        style={{ marginBottom: 10 }}
      >
        <span dangerouslySetInnerHTML={{ __html: valueMeta }}></span>
      </div>
      <Card>
        <div style={{ padding: 20 }}>
          <StyledDropzone
            current_value={current_value}
            data={data}
            type={data.type}
          />
        </div>
      </Card>
      <div
        className="Polaris-Labelled__HelpText"
        id="PolarisTextField2HelpText"
      >
        <span dangerouslySetInnerHTML={{ __html: description }}></span>
      </div>
      <div id="PolarisPortalsContainer"></div>
      {errors && errors[name] && (
        <ErrorMessage message={errors[name]["message"]} />
      )}
    </div>
  );
};

export default File;
