import React, { useEffect, useState } from "react";
import ReactPrismEditor from "react-prism-editor";
// import CssCodeEditor from "./CssCodeEditor";
import "./CSShighlight.css";
import ErrorMessage from "../ErrorMessage";




const CSSHighlight = ({ data, errors, name, register, setValue }) => {
  let { label, default_value, description } = data;
  if (data.current_value) {
    default_value = data.current_value;
  }
  useEffect(() => {
    if (data.required) {
      register(name, {
        required: "This Field is required.",
      });
    } else {
      register(name);
    }
  }, []);
  const [code, setCode] = useState(default_value);
  return (
    <div key={name}>
      <div className="Polaris-Labelled__LabelWrapper">
        <div className="Polaris-Label">
          <label htmlFor={name} className="Polaris-Label__Text">
            {label}
            {data.required ? "*" : ""}
          </label>
        </div>
      </div>
      {/* <CodeEditor
                name={name}
                register={register}
                required={required}
                default_value={default_value}
                /> */}
      <div
        className="Polaris-Labelled__HelpText"
        id="PolarisTextField2HelpText"
      >
        <span dangerouslySetInnerHTML={{ __html: description }}></span>
      </div>
      <div id="PolarisPortalsContainer"></div>

      <ReactPrismEditor
        language="css"
        code={code}
        theme={"okaidia"}
        // code={code}
        clipboard={true}
        lineNumber={40}
        name={name}
        changeCode={(code) => {
          setCode(code);
          console.log(code);
          setValue(`${name}`, code);
        }}
      />
      {errors && errors[name] && (
        <ErrorMessage message={errors[name]["message"]} />
      )}
    </div>
  );
};

export default CSSHighlight;
