import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";
import Select from "react-select";

const FloatingInput = ({ label, name, error, register, required }) => {
  return (
    <div key={name}>
      <div className="">
        <div className="Polaris-Labelled__LabelWrapper">
          <div className="Polaris-Label">
            <label
              id="PolarisTextField2Label"
              htmlFor={name}
              className="Polaris-Label__Text"
            >
              {label}
              {required ? "*" : ""}
            </label>
          </div>
        </div>
        <div className="Polaris-Connected">
          <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
            <div className="Polaris-TextField Polaris-TextField--hasValue">
              <input
                type="text"
                className="Polaris-TextField__Input"
                name={name}
                id={name}
                // defaultValue={defaultVal}
                {...register(
                  name,
                  required
                    ? {
                        required: `This field is required`,
                      }
                    : {}
                )}
              />
              <div className="Polaris-TextField__Backdrop"></div>
            </div>
          </div>
        </div>
        <div
          className="Polaris-Labelled__HelpText"
          id="PolarisTextField2HelpText"
        >
          {/* <span dangerouslySetInnerHTML={{ __html: description }}></span> */}
        </div>
      </div>
      <div id="PolarisPortalsContainer"></div>
      {error && <ErrorMessage message={error["message"]} />}
    </div>
  );
};
function CustomLocationForm({ data, token, edit, id, onSave, list }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    getValues,
    unregister,
    control,
    formState,
  } = useForm();

  const onSubmit = () => {
    let data = getValues();
    onSave(data);
  };
  const [country, setCountry] = useState({ label: "Canada", value: "CA" });
  const customStyles = {
    container: (base) => ({
      ...base,
      fontSize: "1.4rem",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 334444444,
      fontSize: "1.4rem",
    }),
  };
  useEffect(() => {
    register("country_code", { required: `This field is required ` });
    setValue("country_code", "CA");

    // register({ name: name });
  }, []);
  return (
    // <SectionCard
    // title={edit ? "Edit Store" : "Create New Store"}
    // id="create">
    <form onChange={onSubmit}>
      <FloatingInput
        label="Address 1"
        name="address_line1"
        hookForm
        register={register}
        error={errors["address_line1"]}
        required
      />
      <FloatingInput
        label="Address 2"
        name="address_line2"
        hookForm
        register={register}
        error={errors["address_line2"]}
        required
      />
      {/* <div className="grid xl:grid-cols-2 xl:gap-6">
          <FloatingInput
            label="First name"
            name="fname2"
            hookForm
            register={register}
          />
          <FloatingInput
            label="Last name"
            name="lname2"
            hookForm
            register={register}
          />
        </div> */}
      {/* <div className="grid xl:grid-cols-2 xl:gap-6"> */}
      <FloatingInput
        label="City"
        name="city"
        hookForm
        register={register}
        error={errors["city"]}
        required
      />
      <FloatingInput
        label="Province"
        name="state"
        hookForm
        register={register}
        error={errors["state"]}
        required
      />
      <FloatingInput
        label="Postal Code"
        name="postal_code"
        hookForm
        register={register}
        error={errors["postal_code"]}
        required
      />
      <div>
        {" "}
        <div className="Polaris-Labelled__LabelWrapper">
          <div className="Polaris-Label">
            <label className="Polaris-Label__Text">
              Country
              {"*"}
            </label>
          </div>
        </div>
        <div className="Polaris-Select"></div>
        <>
          <Select
            value={country}
            name={"country_code"}
            options={[{ label: "Canada", value: "CA" }]}
            isSearchable={true}
            onChange={(value, action) => {
              setCountry(value);
              setValue("country_code", value.value);
              let data = getValues();
              onSave({ ...data, country_code: value.value });
            }}
            // isClearable
            styles={customStyles}
            // defaultValue={defaultValue[0]}
          />
          {errors && errors["country_code"] && (
            <ErrorMessage message={errors["country_code"]["message"]} />
          )}
        </>
      </div>
      {/* </div> */}
    </form>
    // </SectionCard>
  );
}

export default CustomLocationForm;
