import React, { useState, useEffect, useRef, Component } from "react";
import { EntitySuggestions } from "../../../Helpers/DrupalHelper";
import Select, { components } from "react-select";
import Modal from "../../Modal";
import { Page } from "@shopify/polaris";
import { FixedSizeList } from "react-window";
import { InView } from "react-intersection-observer";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import ErrorMessage from "../ErrorMessage";
import AddNewTemplate from "../../../Pages/AddNewTemplate";

function arrayMove(array, from, to) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

const SortableMultiValue = SortableElement((props) => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});
const SortableSelect = SortableContainer(Select);
const height = 35;

const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;

  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <FixedSizeList
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </FixedSizeList>
  );
};
function EntityReferenceRevisions({
  data,
  errors,
  name,
  register,
  label,
  setValue,
  token,
  watch,
}) {
  const [entitySuggestion, setEntitySuggestion] = useState(null);
  const [select1, setSelect1] = useState(null);
  const [defaultValue, setDefaultValue] = useState([]);

  const [inView, setInView] = useState(false);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(select1, oldIndex, newIndex);
    setValue(`${name}`, newValue);

    setSelect1(newValue);
    // console.log(
    //   "Values sorted:",
    //   newValue.map((i) => i.value)
    // );
  };
  const handleChange = (
    value,
    action,
    setStateFunction,
    childSelect = null
  ) => {
    if (
      data.value_limit &&
      data.value_limit > 1 &&
      Array.isArray(value) &&
      value.length > data.value_limit
    ) {
      return;
    } else {
      const inputRef = action.name;
      const currentValue = watch(inputRef);
      if (value !== null) {
        // setValue(inputRef, value);
        setValue(`${name}`, value);
        setStateFunction(value);
      } else {
        // setValue(inputRef, null);
        setStateFunction(null);
      }
    }

    // if(childSelect && )
  };
  // console.log(data);
  // console.log(data.current_value);
  useEffect(() => {
    if (data.required) {
      register(name, {
        required: "This field is required.",
      });
    } else {
      register(name);
    }
    const entity = data?.entity_type?.machine_name;
    let bundle = [];
    if (data?.entities?.length) {
      data.entities.map((v) => bundle.push(v.machine_name));
    }
    if (data.current_value) {
      setValue(`${name}`, data.current_value);
      if (Array.isArray(data.current_value)) {
        let a = data.current_value.map((item) => {
          if (typeof item === "string") return { value: item, label: item };
          else
            return {
              value: item.target_id ? item.target_id : item.id,
              label: item.title,
            };
        });
        setDefaultValue([...a]);
        // console.log(a);
        setSelect1([...a]);
      }
      // let a = data.current_value.map((item) => {
      //   return { value: item.target_id, label: item.title };
      // });

      // setDefaultValue([...a]);
    }

    const EntitySuggestion = async () => {
      // console.log(entity, bundle);
      if (name === "roles") {
        data.value_limit = false;
        await EntitySuggestions(token, "user_roles", []).then(async (data) => {
          // console.log(data);
          let ss = data.map((item) => {
            return { value: item.id, label: item.title };
          });

          setEntitySuggestion([...ss]);
        });
      } else {
        await EntitySuggestions(token, entity, bundle).then(async (data) => {
          // console.log(data);
          let ss = data.map((item) => {
            return {
              value: item.target_id ? item.target_id : item.id,
              label: item.title,
            };
          });
          if (entity === "user") {
            ss = data.map((item) => {
              return {
                value: item.target_id ? item.target_id : item.id,
                value: item.target_id,
                label: `${item.username}, ${item.full_name}, ${item.email}`,
              };
            });
          }
          setEntitySuggestion([...ss]);
        });
      }
    };
    EntitySuggestion();
  }, []);
  // useEffect(() => {
  //   if (inView && !entitySuggestion.length) {
  //     const entity = data.entity_type.machine_name;
  //     const bundle = data.entities[0].machine_name;
  //     const token = localStorage.getItem("token");
  //     const EntitySuggestion = async () => {
  //       await EntitySuggestions(token, entity, bundle).then(async (data) => {
  //         let ss = data.map((item) => {
  //           return { value: item.target_id, label: item.title };
  //         });
  //         setEntitySuggestion(ss);
  //       });
  //     };
  //     EntitySuggestion();
  //   }
  // }, [inView]);
  const customStyles = {
    container: (base) => ({
      ...base,
      fontSize: "1.4rem",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 334444444,
      fontSize: "1.4rem",
    }),
  };
  const entity = data?.entity_type?.machine_name;
  const entityTitle = data?.entity_type?.title;

  const bundle =
    data.entities?.length && data.entities.map((v) => v.machine_name);
  const [open, setOpen] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState(
    bundle && {
      label: bundle[0],
      value: bundle[0],
    }
  );

  const handleClose = () => {
    setOpen(false);
    // props.close(false);
    setSelectedBundle(null);
  };
  const isSaved = async (data) => {
    // let value ={}
    // setEntitySuggestion([...entitySuggestion,value);
    // setSelect1(value);
    // props.close(false);
    // await EntitySuggestions(token, entity, bundle).then(async (data) => {
    //   let ss = data.map((item) => {
    //     return { value: item.target_id, label: item.title };
    //   });
    //   console.log(data);
    //   let newItem = ss.filter((item) => {
    //     return entitySuggestion.every((item2) => {
    //       if (JSON.stringify(item) !== JSON.stringify(item2)) {
    //         return item;
    //       }
    //     });
    //   });
    //   setEntitySuggestion([...ss]);
    //   setSelect1(newItem);
    //   setValue(`${name}`, newItem);

    setOpen(false);
    // });
    if (data) {
      let { id, title } = data;
      let value = { id: id[0].value, label: title[0].value };
      let newItem = [...select1, value];
      setEntitySuggestion([...entitySuggestion, value]);
      if (data.value_limit === 1) {
        setSelect1(value);
        setValue(`${name}`, value);
      } else {
        setSelect1(newItem);
        setValue(`${name}`, newItem);
      }
    }
  };
  const CreateNew = () => (
    <div class="Polaris-Stack__Item float-right" style={{ marginLeft: 10 }}>
      <button
        class="Polaris-Button Polaris-Button--sizeSlim"
        type="button"
        onClick={() => setOpen(true)}
      >
        {" "}
        <span class="Polaris-Button__Content">
          <span class="Polaris-Button__Text">Create New</span>
        </span>
      </button>
      <div id="PolarisPortalsContainer"></div>
    </div>
  );
  return (
    <>
      <div className="Polaris-Labelled__LabelWrapper">
        <div className="Polaris-Label">
          <label className="Polaris-Label__Text">
            {label}
            {data.required ? "*" : ""}
          </label>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "stretch" }}>
        <div style={{ flexGrow: 1 }}>
          {entitySuggestion !== null && entitySuggestion.length > 80 ? (
            <InView onChange={setInView}>
              {({ ref, inView }) => (
                <div key={name} ref={ref}>
                  {/*<div className="Polaris-Labelled__LabelWrapper">
                <div className="Polaris-Label">
                  <label className="Polaris-Label__Text">
                    {label}
                    {data.required ? "*" : ""}
                  </label>
                </div>
              </div>*/}
                  <div className="Polaris-Select"></div>
                  {data.value_limit ? (
                    data.value_limit > 1 ? (
                      <SortableSelect
                        // react-sortable-hoc props:
                        axis="xy"
                        onSortEnd={onSortEnd}
                        distance={4}
                        // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                        getHelperDimensions={({ node }) =>
                          node.getBoundingClientRect()
                        }
                        // react-select props:
                        components={{
                          MultiValue: SortableMultiValue,
                          MenuList,
                        }}
                        closeMenuOnSelect={false}
                        name={name}
                        options={entitySuggestion}
                        isSearchable={true}
                        // components={{  }}
                        onChange={(value, action) => {
                          handleChange(value, action, setSelect1, name);
                        }}
                        styles={customStyles}
                        isClearable
                        value={select1}
                        isMulti
                      />
                    ) : (
                      <Select
                        name={name}
                        options={entitySuggestion}
                        isSearchable={true}
                        onChange={(value, action) => {
                          handleChange(value, action, setSelect1, name);
                        }}
                        isClearable
                        value={select1 !== null ? select1 : defaultValue[0]}
                        components={{ MenuList }}
                        defaultValue={defaultValue[0]}
                        styles={customStyles}
                      />
                    )
                  ) : (
                    <SortableSelect
                      // react-sortable-hoc props:
                      axis="xy"
                      onSortEnd={onSortEnd}
                      distance={4}
                      // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                      getHelperDimensions={({ node }) =>
                        node.getBoundingClientRect()
                      }
                      // react-select props:
                      components={{
                        MultiValue: SortableMultiValue,
                        MenuList,
                      }}
                      closeMenuOnSelect={false}
                      name={name}
                      options={entitySuggestion}
                      isSearchable={true}
                      onChange={(value, action) => {
                        handleChange(value, action, setSelect1, name);
                      }}
                      styles={customStyles}
                      isClearable
                      value={select1 !== null ? select1 : defaultValue}
                      isMulti
                    />
                  )}
                </div>
              )}
            </InView>
          ) : (
            <div key={name}>
              {/*<div className="Polaris-Labelled__LabelWrapper">
            <div className="Polaris-Label">
              <label className="Polaris-Label__Text">
                {label}
                {data.required ? "*" : ""}
              </label>
            </div>
          </div>*/}
              <div className="Polaris-Select"></div>
              {data.value_limit ? (
                data.value_limit > 1 ? (
                  <SortableSelect
                    // react-sortable-hoc props:
                    axis="xy"
                    onSortEnd={onSortEnd}
                    distance={4}
                    // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                    getHelperDimensions={({ node }) =>
                      node.getBoundingClientRect()
                    }
                    // react-select props:
                    components={{
                      MultiValue: SortableMultiValue,
                    }}
                    closeMenuOnSelect={false}
                    name={name}
                    options={entitySuggestion}
                    isSearchable={true}
                    // components={{ MenuList }}
                    onChange={(value, action) => {
                      handleChange(value, action, setSelect1, name);
                    }}
                    styles={customStyles}
                    isClearable
                    value={select1}
                    isMulti
                  />
                ) : (
                  <Select
                    name={name}
                    options={entitySuggestion}
                    isSearchable={true}
                    onChange={(value, action) => {
                      handleChange(value, action, setSelect1, name);
                    }}
                    isClearable
                    value={select1}
                    // components={{ MenuList }}
                    defaultValue={defaultValue[0]}
                    styles={customStyles}
                  />
                )
              ) : (
                <SortableSelect
                  // react-sortable-hoc props:
                  axis="xy"
                  onSortEnd={onSortEnd}
                  distance={4}
                  // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                  getHelperDimensions={({ node }) =>
                    node.getBoundingClientRect()
                  }
                  // react-select props:
                  components={{
                    MultiValue: SortableMultiValue,
                  }}
                  closeMenuOnSelect={false}
                  name={name}
                  options={entitySuggestion}
                  isSearchable={true}
                  // components={{ MenuList }}
                  onChange={(value, action) => {
                    handleChange(value, action, setSelect1, name);
                  }}
                  styles={customStyles}
                  isClearable
                  value={select1 !== null ? select1 : defaultValue}
                  isMulti
                />
              )}
              {errors && errors[name] && (
                <ErrorMessage message={errors[name]["message"]} />
              )}
            </div>
          )}
        </div>
        {data.value_limit &&
          // data.value_limit > 1 &&
          select1 === null &&
          entity !== "user" &&
          name !== "roles" && <CreateNew />}
        {data.value_limit &&
          data.value_limit > 1 &&
          Array.isArray(select1) &&
          select1.length < data.value_limit && <CreateNew />}
        {!data.value_limit && entity !== "user" && name !== "roles" && (
          <CreateNew />
        )}
      </div>
      {/* end flex */}
      {open && (
        <Modal
          header={
            bundle.length > 1 && selectedBundle
              ? `Create a new ${selectedBundle.label}`
              : `Create a new ${bundle[0]}`
          }
          isOpen={open}
          onClose={handleClose}
          fullScreen={true}
        >
          <Page narrowWidth>
            <div style={{ paddingBottom: 50 }}>
              {bundle.length > 1 ? (
                <>
                  <h1 style={{ marginBottom: "10px" }}>
                    Add New {entityTitle}
                  </h1>
                  <p>Which entity of {entityTitle} do you want to create? </p>
                  <Select
                    name={name}
                    options={data.entities.map((v) => {
                      return { label: v.title, value: v.machine_name };
                    })}
                    isSearchable={true}
                    onChange={(value, action) => {
                      setSelectedBundle(value);
                    }}
                    value={selectedBundle}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                  {selectedBundle && (
                    <AddNewTemplate
                      token={token}
                      name={entity}
                      bundle={selectedBundle.value}
                      isSaved={isSaved}
                      nested={true}
                    />
                  )}
                </>
              ) : (
                <AddNewTemplate
                  token={token}
                  name={entity}
                  bundle={bundle[0]}
                  isSaved={isSaved}
                  nested={true}
                />
              )}
            </div>
          </Page>
        </Modal>
      )}
    </>
  );
}

export default EntityReferenceRevisions;
