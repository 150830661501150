import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./styles/tailwind.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Route, BrowserRouter } from "react-router-dom";
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import Global from "./Providers/GlobalProvider";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
// const arg = process.env.REACT_APP_ARG;
// console.log(arg);
// console.log(process.env);

let root = document.getElementById("root");

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
  history.replace(path);
}

ReactDOM.render(
  <React.StrictMode>
    <AppProvider i18n={enTranslations}>
      <Global>
        <BrowserRouter>
          <Route path="/" component={App} />
        </BrowserRouter>
      </Global>
    </AppProvider>
  </React.StrictMode>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
