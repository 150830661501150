import React, { useState, useEffect, useRef, Component } from "react";
import { EntitySuggestions } from "../../../Helpers/DrupalHelper";
import Select, { components } from "react-select";
import Modal from "../../Modal";
import { Page } from "@shopify/polaris";
import { FixedSizeList } from "react-window";
import { InView } from "react-intersection-observer";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import ErrorMessage from "../ErrorMessage";

function arrayMove(array, from, to) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

const SortableMultiValue = SortableElement((props) => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});
const SortableSelect = SortableContainer(Select);
const height = 35;

const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;

  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <FixedSizeList
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </FixedSizeList>
  );
};
function EntityReferenceFieldConfig({
  data,
  errors,
  name,
  register,
  label,
  setValue,
  token,
  watch,
}) {
  const [entitySuggestion, setEntitySuggestion] = useState(null);
  const [select1, setSelect1] = useState(null);
  // console.log(name, data);
  const [inView, setInView] = useState(false);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(select1, oldIndex, newIndex);
    // setValue(`${name}`, newValue);

    setSelect1(newValue);
    // console.log(
    //   "Values sorted:",
    //   newValue.map((i) => i.value)
    // );
  };
  const handleChange = (
    value,
    action,
    setStateFunction,
    childSelect = null
  ) => {
    if (
      data.value_limit &&
      data.value_limit > 1 &&
      Array.isArray(value) &&
      value.length > data.value_limit
    ) {
      return;
    } else {
      const inputRef = action.name;
      const currentValue = watch(inputRef);
      // console.log(value);
      if (value !== null) {
        // setValue(inputRef, value);
        // setValue(`${name}`, value.value);
        setStateFunction(value);
      } else {
        // setValue(inputRef, null);
        setStateFunction(null);
      }
    }

    // if(childSelect && )
  };
  // console.log(data);
  // console.log(data.current_value);
  useEffect(() => {
    if (data.required) {
      register(name, {
        required: "This field is required.",
      });
    } else {
      register(name);
    }

    let selectData = [];
    Object.keys(data.fields).map((v) => {
      let str = v.replaceAll("_", " ");
      const arr = str.split(" ");
      for (var i = 0; i < arr.length; i++) {
        if (i == 0) arr[i] = arr[i].toUpperCase();
        else arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const TitleStr2 = arr.join(" ");
      let fields = data.fields[v].map((item) => {
        return {
          value: `product_builder.${v}.${item.machine_name}`,
          label: `(${TitleStr2}) ${item.label}`,
        };
      });
      selectData.push(...fields);
    });

    setEntitySuggestion(selectData);
    if (data.current_value) {
      setValue(`${name}`, data.current_value);

      let a = selectData.filter((v) => v.value === data.current_value)[0];

      setSelect1(a);
    }
  }, []);

  const customStyles = {
    container: (base) => ({
      ...base,
      fontSize: "1.4rem",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 334444444,
      fontSize: "1.4rem",
    }),
  };
  useEffect(() => {
    if (select1 !== null) {
      // console.log(name, select1);

      setValue(`${name}`, select1.value);
    }
  }, [select1]);
  return (
    <>
      <div className="Polaris-Labelled__LabelWrapper">
        <div className="Polaris-Label">
          <label className="Polaris-Label__Text">
            {label}
            {data.required ? "*" : ""}
          </label>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "stretch" }}>
        <div style={{ flexGrow: 1 }}>
          {entitySuggestion !== null && entitySuggestion.length > 80 ? (
            <InView onChange={setInView}>
              {({ ref, inView }) => (
                <div key={name} ref={ref}>
                  {/*<div className="Polaris-Labelled__LabelWrapper">
                <div className="Polaris-Label">
                  <label className="Polaris-Label__Text">
                    {label}
                    {data.required ? "*" : ""}
                  </label>
                </div>
              </div>*/}
                  <div className="Polaris-Select"></div>
                  {data.value_limit ? (
                    data.value_limit > 1 ? (
                      <SortableSelect
                        // react-sortable-hoc props:
                        axis="xy"
                        onSortEnd={onSortEnd}
                        distance={4}
                        // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                        getHelperDimensions={({ node }) =>
                          node.getBoundingClientRect()
                        }
                        // react-select props:
                        components={{
                          MultiValue: SortableMultiValue,
                        }}
                        closeMenuOnSelect={false}
                        name={name}
                        options={entitySuggestion}
                        isSearchable={true}
                        components={{ MenuList }}
                        onChange={(value, action) => {
                          handleChange(value, action, setSelect1, name);
                        }}
                        styles={customStyles}
                        isClearable
                        value={select1}
                        isMulti
                      />
                    ) : (
                      <Select
                        name={name}
                        options={entitySuggestion}
                        isSearchable={true}
                        onChange={(value, action) => {
                          handleChange(value, action, setSelect1, name);
                        }}
                        isClearable
                        value={select1}
                        components={{ MenuList }}
                        // defaultValue={defaultValue[0]}
                        styles={customStyles}
                      />
                    )
                  ) : (
                    <SortableSelect
                      // react-sortable-hoc props:
                      axis="xy"
                      onSortEnd={onSortEnd}
                      distance={4}
                      // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                      getHelperDimensions={({ node }) =>
                        node.getBoundingClientRect()
                      }
                      // react-select props:
                      components={{
                        MultiValue: SortableMultiValue,
                      }}
                      closeMenuOnSelect={false}
                      name={name}
                      options={entitySuggestion}
                      isSearchable={true}
                      components={{ MenuList }}
                      onChange={(value, action) => {
                        handleChange(value, action, setSelect1, name);
                      }}
                      styles={customStyles}
                      isClearable
                      value={select1}
                      isMulti
                    />
                  )}
                </div>
              )}
            </InView>
          ) : (
            <div key={name}>
              {/*<div className="Polaris-Labelled__LabelWrapper">
            <div className="Polaris-Label">
              <label className="Polaris-Label__Text">
                {label}
                {data.required ? "*" : ""}
              </label>
            </div>
          </div>*/}
              <div className="Polaris-Select"></div>
              {data.value_limit ? (
                data.value_limit > 1 ? (
                  <SortableSelect
                    // react-sortable-hoc props:
                    axis="xy"
                    onSortEnd={onSortEnd}
                    distance={4}
                    // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                    getHelperDimensions={({ node }) =>
                      node.getBoundingClientRect()
                    }
                    // react-select props:
                    components={{
                      MultiValue: SortableMultiValue,
                    }}
                    closeMenuOnSelect={false}
                    name={name}
                    options={entitySuggestion}
                    isSearchable={true}
                    // components={{ MenuList }}
                    onChange={(value, action) => {
                      handleChange(value, action, setSelect1, name);
                    }}
                    styles={customStyles}
                    isClearable
                    value={select1}
                    isMulti
                  />
                ) : (
                  <Select
                    name={name}
                    options={entitySuggestion}
                    isSearchable={true}
                    onChange={(value, action) => {
                      handleChange(value, action, setSelect1, name);
                    }}
                    isClearable
                    value={select1}
                    // components={{ MenuList }}
                    // defaultValue={defaultValue[0]}
                    styles={customStyles}
                  />
                )
              ) : (
                <SortableSelect
                  // react-sortable-hoc props:
                  axis="xy"
                  onSortEnd={onSortEnd}
                  distance={4}
                  // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                  getHelperDimensions={({ node }) =>
                    node.getBoundingClientRect()
                  }
                  // react-select props:
                  components={{
                    MultiValue: SortableMultiValue,
                  }}
                  closeMenuOnSelect={false}
                  name={name}
                  options={entitySuggestion}
                  isSearchable={true}
                  // components={{ MenuList }}
                  onChange={(value, action) => {
                    handleChange(value, action, setSelect1, name);
                  }}
                  styles={customStyles}
                  isClearable
                  value={select1}
                  isMulti
                />
              )}
              {errors && errors[name] && (
                <ErrorMessage message={errors[name]["message"]} />
              )}
            </div>
          )}
        </div>
      </div>
      {/* end flex */}
    </>
  );
}

export default EntityReferenceFieldConfig;
