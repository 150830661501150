/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { getDashboardOverview } from "../Helpers/DrupalHelper";
import {
  Card,
  Button,
  ButtonGroup,
  DataTable,
  DisplayText,
  Pagination,
  Icon,
  TextField,
  ChoiceList,
  Layout,
  TextContainer,
  Subheading,
  SkeletonBodyText,
  SkeletonThumbnail,
  Stack,
  Loading,
} from "@shopify/polaris";
import ImageCardSmall from "../Components/Elements/ImageCardSmall";
import Tags from "../Components/Elements/Tags";
import Tag from "../Components/Elements/Tag";

import BreadCrumb from "../Components/Elements/BreadCrumb";
import { useHistory } from "react-router-dom";
import { classNames } from "../utils";
const toCapitalCase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const LoadingIndicator = () => {
  return (
    <>
      {new Array(10).fill().map((_, i) => (
        <Card.Section key={i}>
          <div className="flex w-full space-x-6">
            <ImageCardSmall image={""} />
            <div className="flex flex-col w-full">
              <SkeletonBodyText lines={4} />
            </div>
          </div>
        </Card.Section>
      ))}
    </>
  );
};

function DashBoard({ token }) {
  const history = useHistory();
  const [data, setData] = useState(null);
  useEffect(async () => {
    let data = await getDashboardOverview(token);
    setData(data);
  }, []);
  return (
    <>
      <BreadCrumb />
      <div className="mt-12 mb-10">
        <DisplayText size="medium">
          <b>Welcome back,</b> admin.
        </DisplayText>
        <p>Manage content, entities and users from your O2O Dashboard.</p>
      </div>
      <Layout>
        <Layout.Section>
          <Card title={"Latest Assets"} sectioned>
            {data !== null &&
            "latest_assets" in data &&
            Array.isArray(data["latest_assets"]) &&
            data["latest_assets"].length ? (
              data["latest_assets"].map((c, i) => {
                console.log(c);
                return (
                  <Card.Section key={i}>
                    <div className="flex w-full space-x-6">
                      <ImageCardSmall
                        image={c?.cover?.length > 0 ? c.cover[0] : ""}
                      />
                      <div className="flex flex-col w-full">
                        <div
                          className="flex w-full items-center"
                          css={css`
                            min-height: 30px;
                          `}
                        >
                          <Subheading>{c.title ? c.title : c.name}</Subheading>
                          <div className="ml-auto">
                            <Tags>
                              {c.tags.map((item, k) => (
                                <Tag>{item}</Tag>
                              ))}
                            </Tags>
                          </div>
                        </div>
                        <ButtonGroup>
                          <Button
                            onClick={() =>
                              history.push(
                                `edit_${c.entity}/${c.bundle}/${c.id}`
                              )
                            }
                            primary
                          >
                            Edit
                          </Button>
                          <Button
                            url={`https://useo2o.com/${c.entity}/${c.id}`}
                            plain
                          >
                            View
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  </Card.Section>
                );
              })
            ) : (
              <LoadingIndicator />
            )}
          </Card>

          <Card title={"Latest Products"} sectioned>
            {data !== null &&
            "latest_products" in data &&
            Array.isArray(data["latest_products"]) &&
            data["latest_products"].length ? (
              data["latest_products"].map((c, i) => {
                console.log(c);
                console.log("product");
                return (
                  <Card.Section key={i}>
                    <Stack alignment="center">
                      <ImageCardSmall
                        image={c?.cover?.length > 0 ? c.cover[0] : ""}
                      />
                      <Stack vertical>
                        <Subheading>{c.title ? c.title : c.name}</Subheading>

                        <ButtonGroup>
                          <Button
                            onClick={() =>
                              history.push(
                                `edit_${c.entity}/${c.bundle}/${c.id}`
                              )
                            }
                            primary
                          >
                            Edit
                          </Button>
                          <Button
                            url={`https://useo2o.com/${c.entity}/${c.id}`}
                            plain
                          >
                            View
                          </Button>
                        </ButtonGroup>
                      </Stack>
                    </Stack>
                  </Card.Section>
                );
              })
            ) : (
              <LoadingIndicator />
            )}
          </Card>

          <Card title={"Latest Retailers"} sectioned>
            {data !== null &&
            "latest_retailers" in data &&
            Array.isArray(data["latest_retailers"]) &&
            data["latest_retailers"].length ? (
              data["latest_retailers"].map((c, i) => {
                console.log(c);
                return (
                  <Card.Section key={i}>
                    <Stack alignment="center">
                      {/*<ImageCardSmall
                        image={c?.cover?.length > 0 ? c.cover[0] : ""}
                      />*/}
                      <Stack vertical>
                        <Subheading>{c.title ? c.title : c.name}</Subheading>
                        <ButtonGroup>
                          <Button
                            onClick={() =>
                              history.push(
                                `edit_${c.entity}/${c.bundle}/${c.id}`
                              )
                            }
                            primary
                          >
                            Edit
                          </Button>
                          <Button
                            url={`https://useo2o.com/${c.entity}/${c.id}`}
                            plain
                          >
                            View
                          </Button>
                        </ButtonGroup>
                      </Stack>
                    </Stack>
                  </Card.Section>
                );
              })
            ) : (
              <LoadingIndicator />
            )}
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card title={"Top Assets (Physical)"} sectioned>
            {data !== null &&
            "top_assets_physical" in data &&
            Array.isArray(data["top_assets_physical"]) &&
            data["top_assets_physical"].length ? (
              data["top_assets_physical"].map((c, i) => {
                console.log(c);
                return (
                  <Card.Section key={i}>
                    <Stack alignment="center">
                      <ImageCardSmall
                        image={c?.cover?.length > 0 ? c.cover[0] : ""}
                      />
                      <Stack vertical>
                        <Subheading>{c.title ? c.title : c.name}</Subheading>
                        <ButtonGroup>
                          <Button
                            onClick={() =>
                              history.push(
                                `edit_${c.entity}/${c.bundle}/${c.id}`
                              )
                            }
                            primary
                          >
                            Edit
                          </Button>
                          <Button
                            url={`https://useo2o.com/${c.entity}/${c.id}`}
                            plain
                          >
                            View
                          </Button>
                        </ButtonGroup>
                      </Stack>
                    </Stack>
                  </Card.Section>
                );
              })
            ) : (
              <LoadingIndicator />
            )}
          </Card>
          <Card title={"Top Assets (Digital)"} sectioned>
            {data !== null &&
            "top_assets_digital" in data &&
            Array.isArray(data["top_assets_digital"]) &&
            data["top_assets_digital"].length ? (
              data["top_assets_digital"].map((c, i) => {
                console.log(c);
                return (
                  <Card.Section key={i}>
                    <Stack alignment="center">
                      <ImageCardSmall
                        image={c?.cover?.length > 0 ? c.cover[0] : ""}
                      />
                      <Stack vertical>
                        <Subheading>{c.title ? c.title : c.name}</Subheading>
                        <ButtonGroup>
                          <Button
                            onClick={() =>
                              history.push(
                                `edit_${c.entity}/${c.bundle}/${c.id}`
                              )
                            }
                            primary
                          >
                            Edit
                          </Button>
                          <Button
                            url={`https://useo2o.com/${c.entity}/${c.id}`}
                            plain
                          >
                            View
                          </Button>
                        </ButtonGroup>
                      </Stack>
                    </Stack>
                  </Card.Section>
                );
              })
            ) : (
              <LoadingIndicator />
            )}
          </Card>
        </Layout.Section>
      </Layout>
    </>
  );
}

export default DashBoard;
