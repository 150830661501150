import React, { createContext, useReducer, useEffect, useState } from "react";
import { myO2O as CallMyO2O, sessToken } from "../Helpers/DrupalHelper";

export const StateContext = createContext();
const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  logOutToken: null,
  successMessage: false,
  isLoading: true,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "TOKEN":
      return {
        ...state,
        token: action.payload.csrf_token,
        logOutToken: action.payload.logout_token,
      };
    case "TOKEN_ONLY":
      return {
        ...state,
        token: action.payload,
      };
    case "LOGIN":
      // console.log(action.payload);
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        user: action.payload,
      };
    case "SUCCESSFUL_TOAST":
      return {
        ...state,
        successMessage: action.payload,
      };
    case "LOGOUT":
      // localStorage.clear();
      sessionStorage.clear();
      return {
        isAuthenticated: false,
        user: null,
        token: null,
        logOutToken: null,
        successMessage: false,
        isLoading: false,
      };
    case "ENTITIES":
      return {
        ...state,
        entities: action.payload,
      };

    case "LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};
export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [fail, setFail] = useState(false);
  useEffect(async () => {
    let user = JSON.parse(sessionStorage.getItem("user") || null);
    const token = await sessToken();
    const logOutToken = localStorage.getItem("logOutToken");
    const TOKENS = {
      csrf_token: token,
      logout_token: logOutToken,
    };

    await CallMyO2O(token).then(async (data) => {
      if (data.isAuthenticated === true && data._r.includes("administrator")) {
        sessionStorage.setItem("user", JSON.stringify(data));
        user = data;
        dispatch({
          type: "LOGIN",
          payload: data,
        });
        dispatch({
          type: "TOKEN_ONLY",
          payload: token,
        });
      } else if (data.isAuthenticated === true) {
        setFail(true);
      }
    });

    if (token) {
      dispatch({
        type: "TOKEN",
        payload: TOKENS,
      });
    }

    dispatch({ type: "LOADING", payload: false });
  }, []);

  if (fail === true) return <>You are not authorized to access this page.</>;
  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
};

export const StateConsumer = StateContext.Consumer;
