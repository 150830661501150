import React, { useState, useEffect, Component } from "react";
import Select from "react-select";
import ErrorMessage from "../ErrorMessage";
import MetaDownload from "../../MetaDownload";
import MetaDelivery from "../../MetaDelivery";
const sData = [
  {
    value: "digital",
    label: (
      <div className="flex justify-between">
        Digital
        <MetaDownload />
      </div>
    ),
  },
  {
    value: "physical",
    label: (
      <div className="flex justify-between">
        Physical
        <MetaDelivery />
      </div>
    ),
  },
];
function ListString({ data, errors, name, register, label, setValue, watch }) {
  const [select1, setSelect1] = useState(null);
  const [selectValue, setSelectValue] = useState(null);
  const [defaultValue, setDefaultValue] = useState([]);
  const options =
    name === "field_asset_type"
      ? sData
      : data.allowed_values &&
        data.allowed_values.map((item) => {
          return { value: item.key, label: item.title };
        });
  useEffect(() => {
    if (data.current_value) {
      if (Array.isArray(data.current_value)) {
        let a = data.current_value.map((item) => {
          if (typeof item === "string") return { value: item, label: item };
          else return { value: item.target_id, label: item.title };
        });
        setSelectValue([...a]);
      } else {
        // setDefaultValue([
        //   { value: data.current_value, label: data.current_value },
        // ]);
        const defaultValue =
          data.current_value &&
          data.allowed_values &&
          data.allowed_values.filter(
            (item) => item.key === data.current_value
          )[0];
        console.log(defaultValue);
        if (defaultValue)
          setSelectValue({
            value: defaultValue.key,
            label: defaultValue.title,
          });
      }
    } else if (data.default_value) {
      const defaultValue =
        data.allowed_values &&
        data.allowed_values.filter((item) => item.key === data.default_value);
      setSelectValue({
        value: defaultValue.key,
        label: defaultValue.title,
      });
    }

    // console.log(defaultValue);
    // console.log(selectValue)
  }, []);
  // console.log(data);
  const handleChange = (
    value,
    action,
    setStateFunction,
    childSelect = null
  ) => {
    const inputRef = action.name;
    // console.log(action)
    const currentValue = watch(inputRef);
    setSelectValue(value);
    if (value !== null) {
      const newValue = value.value;
      if (childSelect && newValue !== currentValue) {
        setValue(childSelect, null);
      }
      if (data.value_limit) setValue(inputRef, [value.value]);
      if (!data.value_limit) setValue(inputRef, [...value.map((v) => v.value)]);
      setStateFunction(value);
    } else {
      setValue(inputRef, data.current_value);
      setStateFunction(data.current_value);
    }

    // if(childSelect && )
  };
  const customStyles = {
    container: (base) => ({
      ...base,
      fontSize: "1.4rem",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 334444444,
      fontSize: "1.4rem",
    }),
  };
  useEffect(() => {
    if (data.required) {
      register(name, { required: "This field is required" });
    } else {
      register(name);
    }
    if (data.current_value) {
      // console.log(data.current_value)
      setValue(name, data.current_value);
    }
  }, []);
  // console.log(defaultValue);

  const isSSR = typeof window === "undefined";
  return (
    <div key={name}>
      <div className="Polaris-Labelled__LabelWrapper">
        <div className="Polaris-Label">
          <label className="Polaris-Label__Text">
            {label}
            {data.required ? "*" : ""}
          </label>
        </div>
      </div>
      <div className="Polaris-Select"></div>

      <>
        {data.value_limit ? (
          <Select
            value={selectValue}
            name={name}
            options={options}
            isSearchable={true}
            onChange={(value, action) => {
              handleChange(value, action, setSelect1, name);
            }}
            isClearable
            styles={customStyles}
            // defaultValue={defaultValue[0]}
          />
        ) : (
          <Select
            name={name}
            options={options}
            value={selectValue}
            isSearchable={true}
            onChange={(value, action) => {
              handleChange(value, action, setSelect1, name);
            }}
            styles={customStyles}
            isClearable
            isMulti
            // defaultValue={defaultValue}
          />
        )}
        {errors && errors[name] && (
          <ErrorMessage message={errors[name]["message"]} />
        )}
      </>

      <div
        className="Polaris-Labelled__HelpText"
        id="PolarisTextField2HelpText"
      >
        <span dangerouslySetInnerHTML={{ __html: data.description }}></span>
      </div>
    </div>
  );
}

export default ListString;
