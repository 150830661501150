import React, { useEffect, useRef, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { entitiyFormEdit } from "../Helpers/DrupalHelper";
import { Link } from "react-router-dom";
import Form from "../Components/Form/DynamicForm";
import {
  Card,
  TextContainer,
  SkeletonBodyText,
  Loading,
} from "@shopify/polaris";
import BreadCrumb from "../Components/Elements/BreadCrumb";

function EditPackagesTemplate(props) {
  let { id } = useParams();
  let location = useLocation();

  console.log(props);
  const [formData, setFormData] = useState({});
  useEffect(() => {
    const fetchFormData = async () => {
      await entitiyFormEdit(props.name, props.bundle, id).then(async (data) => {
        setFormData(data);
        console.log(data);
      });
    };
    fetchFormData();
  }, []);
  const myRef = useRef(null);
  const goBack = () => {
    console.log("is back", window.PressedBack);
    console.log(myRef);
    window.PressedBack = true;
  };
  console.log(myRef);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    window.addEventListener("popstate", goBack);
    // window.removeEventListener("popstate", () => {
    //   console.log("event removed");
    // });
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);
  console.log(formData);
  return !Object.keys(formData).length ? (
    <>
      <Loading />

      {[...Array(20)].map((e, i) => {
        return (
          <Card.Section key={i}>
            <TextContainer>
              <SkeletonBodyText lines={1} key={i} />
            </TextContainer>
          </Card.Section>
        );
      })}
    </>
  ) : (
    <>
      <BreadCrumb
        list={[
          { title: props.name, link: `/${props.name}` },
          {
            title: `Editing ${
              formData["title"]?.current_value
                ? formData["title"]?.current_value
                : id
            }`,
          },
        ]}
      />

      <div ref={myRef}>
        <Form
          data={formData}
          type={props.name}
          bundle={props.bundle}
          id={id}
          token={props.token}
        />
      </div>
    </>
  );
}

export default EditPackagesTemplate;
