import React, { useCallback, useState, useContext, useEffect } from "react";
import {
  Button,
  Card,
  TextField,
  Form,
  FormLayout,
  Loading,
  Toast,
  Frame,
  InlineError,
} from "@shopify/polaris";
import { StateContext } from "../Context/StateContext";
import Select from "react-select";

import Footer from "./Footer";
import { Login, myO2O as CallMyO2O, sessToken } from "../Helpers/DrupalHelper";
import Logo from "../Assets/gatsby-icon.png";
let opts = ["dev", "test", "live"];

// if(window.location.href.includes("localhost")) opts.shift()
const options = opts.map((v) => {
  return { value: v, label: v.toUpperCase() };
});
export default function LoginComponent() {
  const { dispatch } = useContext(StateContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(false);
  const [selectValue, setSelectValue] = useState(options[0]);
  const [loginFailed, setLoginFailed] = useState(false);
  const [inlineErrorUsername, setInlineErrorUsername] = useState(false);
  const [inlineErrorPassword, setInlineErrorPassword] = useState(false);
  const [errorUsername, setErrorUsername] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  useEffect(() => {
    let data = localStorage.getItem("HOST_NAME");
    if (!data) {
      localStorage.setItem("HOST_NAME", `https://dev.api.useo2o.com`);

      setSelectValue({ value: "dev", label: "DEV" });
    } else {
      //should replace this filter like `https://${v.value}-.pantheonsite.io`

      if (data === "https://api.useo2o.com") setSelectValue(options[2]);
      else if (data.includes("dev")) setSelectValue(options[0]);
      else if (data.includes("test")) setSelectValue(options[1]);
    }
  }, []);
  const handleChange = async (v) => {
    // const inputRef = action.name;
    localStorage.clear();
    setSelectValue(v);
    let host;
    if (v.value === "live") host = `https://api.useo2o.com`;
    else {
      host = `https://${v.value}.api.useo2o.com`;
    }

    localStorage.setItem("HOST_NAME", host);
    await sessToken().then(async (token) => {
      await CallMyO2O(token).then(async (data) => {
        if (
          data.isAuthenticated === true &&
          data._r.includes("administrator")
        ) {
          sessionStorage.setItem("user", JSON.stringify(data));
          setLoading(false);
          dispatch({
            type: "LOGIN",
            payload: data,
          });
          dispatch({
            type: "SUCCESSFUL_TOAST",
            payload: `Welcome ${data.name}, login successful.`,
          });
          dispatch({
            type: "TOKEN_ONLY",
            payload: token,
          });
        } else {
          alert("Access Denied");
        }
      });
    });
  };
  // useEffect(async () => {
  //   await sessToken().then(async (token) => {
  //     await CallMyO2O(token).then(async (data) => {
  //       if (data.isAuthenticated === true) {
  //         sessionStorage.setItem("user", JSON.stringify(data));
  //         setLoading(false);
  //         dispatch({
  //           type: "LOGIN",
  //           payload: data,
  //         });
  //         dispatch({
  //           type: "SUCCESSFUL_TOAST",
  //           payload: `Welcome ${data.name}, login successful.`,
  //         });
  //         dispatch({
  //           type: "TOKEN_ONLY",
  //           payload: token,
  //         });
  //       }
  //     });
  //   });
  // }, [selectValue]);

  const toggleActive = useCallback(
    () => setLoginFailed((loginFailed) => !loginFailed),
    []
  );
  const handleSubmit = async () => {
    if (username && password) {
      setInlineErrorUsername(false);
      setInlineErrorPassword(false);
      async function MakeLogin() {
        await sessToken().then(async (token) => {
          await CallMyO2O(token)
            .then(async (data) => {
              if (data.isAuthenticated === true) {
                sessionStorage.setItem("user", JSON.stringify(data));
                setLoading(false);
                dispatch({
                  type: "LOGIN",
                  payload: data,
                });
                dispatch({
                  type: "SUCCESSFUL_TOAST",
                  payload: `Welcome ${data.name}, login successful.`,
                });
                dispatch({
                  type: "TOKEN_ONLY",
                  payload: token,
                });
              } else {
                await Login(token, username, password).then(async (data) => {
                  if (data.current_user) {
                    localStorage.setItem("token", data.csrf_token);
                    localStorage.setItem("logOutToken", data.logout_token);
                    dispatch({
                      type: "TOKEN",
                      payload: data,
                    });
                    await CallMyO2O(data.csrf_token)
                      .then(async (data) => {
                        sessionStorage.setItem("user", JSON.stringify(data));
                        setLoading(false);
                        dispatch({
                          type: "LOGIN",
                          payload: data,
                        });
                        dispatch({
                          type: "SUCCESSFUL_TOAST",
                          payload: `Welcome ${data.name}, login successful.`,
                        });
                      })
                      .catch((e) => console.log(e));
                  } else {
                    toggleActive();
                    setLoading(false);
                    setErrorUsername(true);
                    setErrorPassword(true);
                  }
                });
              }
            })
            .catch((e) => console.log(e));
        });
      }
      setLoading(true);
      await MakeLogin();
    } else {
      if (username || password) {
        if (username) {
          setInlineErrorPassword(true);
          setErrorPassword(true);
        }
        if (password) {
          setInlineErrorUsername(true);
          setErrorUsername(true);
        }
      } else {
        setInlineErrorUsername(true);
        setInlineErrorPassword(true);
        setErrorUsername(true);
        setErrorPassword(true);
      }
    }
    //setNewsletter(false);
  };
  const toastMarkup = loginFailed ? (
    <Toast
      content="Sorry, unrecognized username or password."
      onDismiss={toggleActive}
    />
  ) : null;

  useEffect(() => {
    if (username && password) {
      setInlineErrorUsername(false);
      setInlineErrorPassword(false);
      setErrorUsername(false);
      setErrorPassword(false);
    } else {
      if (username) {
        setInlineErrorUsername(false);
        setErrorUsername(false);
      }
      if (password) {
        setInlineErrorPassword(false);
        setErrorPassword(false);
      }
    }
  }, [username, password]);
  const handleEmailChange = useCallback((value) => setUsername(value), []);
  const handlePasswordChange = useCallback((value) => setPassword(value), []);
  return (
    <Frame>
      {loading ? (
        <Loading />
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            width: "100vw",
          }}
        >
          <div
            style={{
              width: "100%",
              maxWidth: "700px",
              margin: "auto",
              paddingTop: "50px!important",
            }}
          >
            <img src={Logo} style={{ width: 70, margin: "0 auto 40px" }} />
            <Card title="Login to your account" sectioned>
              <span style={{ display: "block", marginBottom: 6 }}>
                Environment
              </span>
              <Select
                options={options}
                onChange={handleChange}
                // isClearable
                value={selectValue}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
              <br />
              <Form onSubmit={handleSubmit}>
                <FormLayout>
                  <TextField
                    value={username}
                    onChange={handleEmailChange}
                    label="Username"
                    type="name"
                    required
                    id="username"
                    error={errorUsername}
                  />
                  {inlineErrorUsername ? (
                    <InlineError
                      message="A valid username is required"
                      fieldID="username"
                    />
                  ) : null}
                  <TextField
                    value={password}
                    onChange={handlePasswordChange}
                    label="Password"
                    type="password"
                    required
                    id="password"
                    error={errorPassword}
                  />
                  {inlineErrorPassword ? (
                    <InlineError
                      message="A valid password is required"
                      fieldID="password"
                    />
                  ) : null}

                  <Button submit>Submit</Button>
                  {toastMarkup}
                </FormLayout>
              </Form>
            </Card>
            <Footer />
          </div>
        </div>
      )}
    </Frame>
  );
}
