/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";

import React from "react";
import { ReactComponent as Icon } from "../Assets/icons/download.svg";
function MetaDownload(props) {
  return (
    <div
      className="flex items-center uppercase green"
      css={css`
        font-size: 10px;
        font-weight: 600;
        color: #009d4f;
        font-family: "Jost";
      `}
    >
      <Icon className="mr-2" />
      Download
    </div>
  );
}

export default MetaDownload;
