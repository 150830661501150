import React from 'react';
import { Page, Frame } from '@shopify/polaris';
import { StateConsumer } from '../Context/StateContext'
import Header from './Header'

function Body(props) {

  const { children, fullwidth } = props

  return (
    <Page fullWidth={fullwidth ? true : false}>
      <StateConsumer>
        {({token, myO2O}) => {

          return (
            <>
              { children }
            </>
          )
        }}
      </StateConsumer>
    </Page>
  )
}

export default Body;
