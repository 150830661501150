import { stateToHTML } from "draft-js-export-html";
import React, { useState, useEffect } from "react";
import ErrorMessage from "../ErrorMessage";
import BodyEditor from "./BodyEditor";
const StringLong = ({
  data,
  errors,
  name,
  register,
  typeName,
  control,
  setValue,
}) => {
  let disabled = name == "field_location_geocode" ? true : false;

  let { type, label, default_value, description, required } = data;
  if (data.current_value) {
    default_value = data.current_value;
  }
  const [appState, setAppState] = useState(null);

  useEffect(() => {
    // register(`${name}`);
    if (data.required) {
      register(name, {
        required: `This field is required`,
      });
    } else {
      register(name);
    }
    if (data?.current_value) {
      if (setValue) setValue(`${name}`, data?.current_value);
    }
  }, []);
  useEffect(() => {
    if (appState !== null) {
      let html = stateToHTML(appState.getCurrentContent());
      if (setValue) setValue(`${name}`, html);
    }
  }, [appState]);
  return (
    <div key={name}>
      {type === "text_with_summary" ||
      type === "text_long" ||
      name.includes("field_body") ? (
        <>
          <div className="Polaris-Labelled__LabelWrapper">
            <div className="Polaris-Label">
              <label htmlFor={name} className="Polaris-Label__Text">
                {label}
                {data.required ? "*" : ""}
              </label>
            </div>
          </div>
          <BodyEditor onChange={setAppState} data={data} />
          <div
            className="Polaris-Labelled__HelpText"
            id="PolarisTextField2HelpText"
          >
            <span dangerouslySetInnerHTML={{ __html: description }}></span>
          </div>
        </>
      ) : (
        <>
          <div className="Polaris-Labelled__LabelWrapper">
            <div className="Polaris-Label">
              <label htmlFor={name} className="Polaris-Label__Text">
                {label}
              </label>
            </div>
          </div>
          <div className="Polaris-Connected">
            <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
              <div className="Polaris-TextField Polaris-TextField--hasValue Polaris-TextField--multiline">
                {required ? (
                  <textarea
                    name={name}
                    id={name}
                    className="Polaris-TextField__Input"
                    defaultValue={default_value}
                    {...register(name, {
                      required: `this field is required`,
                    })}
                    style={{ maxHeight: 180 }}
                  ></textarea>
                ) : (
                  <textarea
                    name={name}
                    id={name}
                    className="Polaris-TextField__Input"
                    defaultValue={default_value}
                    {...register(name)}
                    style={{ maxHeight: 180 }}
                    disabled={disabled}
                  ></textarea>
                )}
                <div className="Polaris-TextField__Backdrop"></div>
                <div
                  aria-hidden="true"
                  className="Polaris-TextField__Resizer"
                ></div>
              </div>
            </div>
          </div>
          <div
            className="Polaris-Labelled__HelpText"
            id="PolarisTextField2HelpText"
          >
            <span dangerouslySetInnerHTML={{ __html: description }}></span>
          </div>
          <div id="PolarisPortalsContainer"></div>
          {errors && errors[name] && (
            <ErrorMessage message={errors[name]["message"]} />
          )}
        </>
      )}
    </div>
  );
};

export default StringLong;
