import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import MuiModal from "@mui/material/Modal";
import { Scrollable } from "@shopify/polaris";

const style = {
  position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  width: "90%",
  margin: "auto",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: "scroll",
};
// const styles = (theme) => ;
const useStyles = makeStyles((theme) => ({
  modalStyle1: {
    // position: "absolute",
    overflow: "hidden",
    // height: "100%",
    // display: "block",
  },
}));

// const Dialog = withStyles((theme) => ({
//   root: {
//     backdropFilter: "blur(5px)",
//   },
//   paper: {
//     borderRadius: 0,
//   },
// }))(MuiDialog);

const Modal = (props) => {
  const {
    isOpen,
    closeModal,
    children,
    size,
    backgroundColor,
    onClose,
    fullScreen,
    header,
  } = props;
  const classes = useStyles();

  if (fullScreen === true) {
    return (
      <Dialog
        // maxWidth={"30%"}
        // fullWidth={false}
        open={isOpen}
        // onClose={() => onClose()}
        fullScreen
        // css={{
        //   "> div > div": {
        //     backgroundColor: backgroundColor ? backgroundColor : "#fff",
        //   },
        // }}
      >
        <div
          style={{
            // ...style,
            // width: "100%",
            background: "rgba(246, 246, 247, 1)",
            minHeight: "100vh",
            width: "100%",
            margin: "auto",
            boxSizing: "border-box",
            paddingTop: "100px",
            paddingLeft: "20px",
            overflowY: "scroll",

            outline: "none",
          }}
        >
          <div
            style={{
              position: "fixed",
              background: "rgba(246, 246, 247, 1)",
              width: "100%",

              height: 60,
              borderBottom: "1px solid rgba(0,0,0,.1)",

              zIndex: 133333333,
              top: 0,
              left: 0,
              right: 0,
            }}
          >
            <div
              style={{
                float: "left",
                fontSize: "18px",
                lineHeight: 1,
                padding: " 20px",
                paddingLeft: "30px",
              }}
            >
              <h3>{header}</h3>
            </div>
            <div
              class="Polaris-Modal-Header"
              style={{
                float: "right",
                borderBottom: 0,
                padding: "0 25px 0 0",
                height: "100%",
                alignItems: "center",
                // position: "fixed",
                zIndex: 333333,
              }}
            >
              <button
                class="Polaris-Modal-CloseButton"
                aria-label="Close"
                onClick={() => onClose()}
              >
                <span class="Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor">
                  <span class="Polaris-VisuallyHidden"></span>
                  <svg
                    viewBox="0 0 20 20"
                    class="Polaris-Icon__Svg"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <path d="m11.414 10 6.293-6.293a1 1 0 1 0-1.414-1.414L10 8.586 3.707 2.293a1 1 0 0 0-1.414 1.414L8.586 10l-6.293 6.293a1 1 0 1 0 1.414 1.414L10 11.414l6.293 6.293A.998.998 0 0 0 18 17a.999.999 0 0 0-.293-.707L11.414 10z"></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <div style={{ width: "90%", margin: "auto" }}>{children}</div>
        </div>
      </Dialog>
    );
  } else {
    return (
      <React.Fragment>
        <Dialog
          maxWidth={"30%"}
          fullWidth={false}
          open={isOpen}
          onClose={!props.isLoader ? () => closeModal() : false}
          // css={{
          //   "> div > div": {
          //     backgroundColor: backgroundColor ? backgroundColor : "#fff",
          //   },
          // }}
        >
          {
            // <div
            //   className="btn-close-search popup-close"
            //   onClick={() => closeModal()}
            // >
            //   <i className="dlicon ui-1_simple-remove"></i>
            // </div>
            // <div css={{ padding: "", whiteSpace: "pre-wrap" }}></div>
          }
          <div
            style={{
              overflow: "hidden",
              minWidth: "30%",
              backgroundColor: "transparent",
            }}
          >
            {children}
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
};

export default Modal;
