import React, { useState, useCallback } from "react";
import { Button, DatePicker, PageActions } from "@shopify/polaris";
// import { useCallback } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

import ErrorMessage from "../ErrorMessage";

export default function DatePickerType({
  data,
  errors,
  name,
  register,
  setValue,
}) {
  return (
    <Stack component="form" noValidate spacing={3}>
      <div className="Polaris-Labelled__LabelWrapper">
        <div className="Polaris-Label">
          <label htmlFor={name} className="Polaris-Label__Text">
            {data.label}
            {data.required ? "*" : ""}
          </label>
        </div>
      </div>
      <TextField
        id={name}
        name={name}
        inputRef={
          data.required
            ? register(name, {
                required: "This is required.",
              })
            : register(name)
        }
        type="date"
        defaultValue={data.current_value ? data.current_value : false}
        // sx={{ width: 220 }}
        // InputLabelProps={{
        //   shrink: true,
        // }}
        inputProps={
          data.required
            ? {
                ...register(name, {
                  required: "This is required.",
                }),
              }
            : { ...register(name) }
        }
        style={{ marginTop: "2px" }}
      />
      {errors && errors[name] && (
        <ErrorMessage message={errors[name]["message"]} />
      )}
    </Stack>
  );
}
