import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import Boolean from "./FormTypes/Boolean";
import EntityParagraph from "./FormTypes/EntityParagraph";
import EntityReferenceRevisions from "./FormTypes/EntityReferenceRevisions";
import ListString from "./FormTypes/ListString";
import File from "./FormTypes/File";
import String from "./FormTypes/String";
import StringLong from "./FormTypes/StringLong";
import Float from "./FormTypes/Float";
import Integer from "./FormTypes/Integer";
import Decimal from "./FormTypes/Decimal";
import Weight from "./FormTypes/Weight";
import ListInteger from "./FormTypes/ListInteger";
import ColorPicker from "./FormTypes/ColorPicker";
import CSSHighlight from "./FormTypes/CSSHighlight";
import { useLocation, useHistory } from "react-router";
import DatePickerType from "./FormTypes/DatePicker";
import Media from "./FormTypes/Media";
import MetaTags from "./FormTypes/MetaTags";
import WebForm from "./FormTypes/Webform";
import { StateContext } from "../../Context/StateContext";
import { activeHost, deleteEntity } from "../../Helpers/DrupalHelper";
import EntityReferenceFieldConfig from "./FormTypes/EntityReferenceFieldConfig";
import Email from "./FormTypes/Email";
import GeoLocations from "./FormTypes/GeoLocation";
import ViewInShopify from "../AssetEntity/ViewInShopify";
import AllocStock from "../AssetEntity/AllocStock";
import EditOrderForm from "./EditOrderForm";

const SingleField = (props) => {
  const { children, small, med } = props;

  let maxWidth = "none";
  if (small) maxWidth = 500;
  if (med) maxWidth = 700;
  return (
    <div style={{ margin: "0 0 30px", maxWidth: maxWidth }}>{children}</div>
  );
};

// Reusable Form Component
let skipArray = [
  "id",
  // "field_brand_active",
  // "field_internal_featured",
  "field_aloc_rep",
  "field_aloc_ret",
  "field_aloc_unallocated",
  "field_aloc_rep_restrictions",
  "field_aloc_ret_restrictions",
  "field_shopifyid",
  "field_shopifyinventoryid",
  "field_aloc_rep_segments",
  "field_shopifyvariantid",
  // "field_asset_price",
  // "field_asset_sku",
  // "field_product_featured",
  "field_aloc_unallocated",
  "field_provided_retailer_id",
  "field_provided_location_id",
  "field_provided_store_id",
  "field_provided_retailer_user",
  "field_provided_brand_user",
  "field_onboarding_completed",
  "field_provided_user_id",
  "field_shopifyuserid",
  "field_deleted",
  "field_asset_company",
  "field_init_registration_json",
  "field_provided_brand_user_id",
  // "field_company_created",
  // "field_company_type",
  // "field_retailer_created",
];
export const renderFields = (
  data,
  errors,
  register,
  setValue,
  watch,
  index,
  unregister,
  prename,
  token,
  control,
  location,
  typeName,
  addNew
) => {
  if (data) {

    // console.log(register)
    // console.log("register")
    return Object.entries(data).map((field) => {
      let { type, label } = field[1];
      let name = field[0];
      // if (index) {
      //   name = `${prename}_${field[0]}_${index.toString()}`;
      // }
      if (skipArray.includes(name)) return;

      if (type === "text_long") {
        type = "string_long";
      }
      if (addNew && name === "field_asset_sku") return;
      if (
        addNew &&
        typeName === "location" &&
        name !== "field_location_address"
      )
        return;
      if (
        typeName === "location" &&
        (name === "field_location_id" || name === "field_location_geocode")
      )
        return;
      switch (type) {
        case "datetime":
          return (
            <SingleField small>
              <DatePickerType
                data={field[1]}
                errors={errors}
                setValue={setValue}
                key={name}
                name={name}
                register={register}
              />
            </SingleField>
          );
        case "created":
          return (
            <SingleField small>
              <DatePickerType
                data={field[1]}
                errors={errors}
                setValue={setValue}
                key={name}
                name={name}
                register={register}
              />
            </SingleField>
          );
        case "link":
          return (
            <SingleField small>
              <String
                data={field[1]}
                errors={errors}
                setValue={setValue}
                key={name}
                name={name}
                register={register}
              />
            </SingleField>
          );

        case "path":
          return (
            <SingleField small>
              <String
                data={field[1]}
                errors={errors}
                setValue={setValue}
                key={name}
                name={name}
                register={register}
              />
            </SingleField>
          );
        case "string":
          if (
            field[0].toLowerCase().includes("color") ||
            label.toLowerCase().includes("color")
          ) {
            return (
              <SingleField>
                <ColorPicker
                  setValue={setValue}
                  data={field[1]}
                  errors={errors}
                  key={name}
                  name={name}
                  register={register}
                />
              </SingleField>
            );
          } else {
            return (
              <SingleField small>
                <String
                  data={field[1]}
                  errors={errors}
                  setValue={setValue}
                  key={name}
                  name={name}
                  register={register}
                  typeName={typeName}
                />
              </SingleField>
            );
          }
        case "email":
          return (
            <SingleField small>
              <Email
                data={field[1]}
                errors={errors}
                setValue={setValue}
                key={name}
                name={name}
                register={register}
              />
            </SingleField>
          );

        case "telephone":
          return (
            <SingleField small>
              <String
                data={field[1]}
                errors={errors}
                setValue={setValue}
                key={name}
                name={name}
                register={register}
              />
            </SingleField>
          );
        case "address":
          return (
            <SingleField>
              <GeoLocations
                currentValue={
                  typeName === "location"
                    ? data["field_location_geocode"]?.current_value
                    : false
                }
                data={field[1]}
                errors={errors}
                name={name}
                register={register}
                label={label}
                setValue={setValue}
                watch={watch}
                token={token}
              />
            </SingleField>
          );

        case "decimal":
          return (
            <SingleField small>
              <Decimal
                type={type}
                data={field[1]}
                errors={errors}
                key={name}
                name={name}
                register={register}
                setValue={setValue}
              />
            </SingleField>
          );
        case "list_integer":
          return (
            <SingleField small>
              <ListInteger
                data={field[1]}
                errors={errors}
                key={name}
                name={name}
                register={register}
                label={label}
                setValue={setValue}
                watch={watch}
              />
            </SingleField>
          );
        case "integer":
          if (name !== "id") {
            return (
              <SingleField small>
                <Integer
                  type={type}
                  data={field[1]}
                  errors={errors}
                  key={name}
                  name={name}
                  register={register}
                />
              </SingleField>
            );
          } else {
            return;
          }

        case "metatag":
          return (
            <SingleField>
              <MetaTags
                data={field[1]}
                errors={errors}
                setValue={setValue}
                key={name}
                name={name}
                regis={register}
              />
            </SingleField>
          );
        case "list_float":
          return (
            <SingleField small>
              <ListString
                data={field[1]}
                errors={errors}
                key={name}
                name={name}
                register={register}
                label={label}
                setValue={setValue}
                watch={watch}
              />
            </SingleField>
          );
        case "float":
          return (
            <SingleField>
              <Float
                type={type}
                data={field[1]}
                errors={errors}
                key={name}
                name={name}
                register={register}
              />
            </SingleField>
          );
        case "weight":
          return (
            <SingleField>
              <Weight
                data={field[1]}
                errors={errors}
                key={name}
                name={name}
                register={register}
                label={label}
                setValue={setValue}
              />
            </SingleField>
          );
        case "computed_float":
          return null;

        case "boolean":
          if (field[0].toLowerCase() !== "status") {
            return (
              <SingleField small>
                <Boolean
                  data={field[1]}
                  errors={errors}
                  key={name}
                  name={name}
                  register={register}
                />
              </SingleField>
            );
          } else {
            return;
          }
        case "string_long":
          if (field[0].toLowerCase().includes("css")) {
            return (
              <SingleField>
                <CSSHighlight
                  data={field[1]}
                  errors={errors}
                  key={name}
                  name={name}
                  register={register}
                  setValue={setValue}
                />
              </SingleField>
            );
          } else {
            return (
              <SingleField med>
                <StringLong
                  data={field[1]}
                  errors={errors}
                  key={name}
                  name={name}
                  register={register}
                  control={control}
                  setValue={setValue}
                />
              </SingleField>
            );
          }

        case "text_with_summary":
          return (
            <SingleField>
              <StringLong
                data={field[1]}
                errors={errors}
                typeName={"text_long"}
                control={control}
                key={name}
                name={name}
                register={register}
              />
            </SingleField>
          );
        case "text_long":
          if (field[0].toLowerCase().includes("css")) {
            return (
              <SingleField small>
                <CSSHighlight
                  data={field[1]}
                  errors={errors}
                  key={name}
                  name={name}
                  register={register}
                />
              </SingleField>
            );
          } else {
            return (
              <SingleField>
                <StringLong
                  data={field[1]}
                  errors={errors}
                  typeName={"text_long"}
                  control={control}
                  key={name}
                  name={name}
                  register={register}
                />
              </SingleField>
            );
          }

        case "image":
          return (
            <SingleField med>
              <File
                data={field[1]}
                errors={errors}
                key={name}
                name={name}
                register={register}
              />
            </SingleField>
          );
        case "file":
          return (
            <SingleField med>
              <File
                data={field[1]}
                errors={errors}
                key={name}
                name={name}
                register={register}
              />
            </SingleField>
          );
        case "entity_reference":
          if (field[1].hasOwnProperty("entity_type")) {
            if (field[1].entity_type.machine_name === "paragraph") {
              return <EntityParagraph data={field[1]} token={token} />;
            } else if (field[1].entity_type.machine_name === "media") {
              return (
                <SingleField med>
                  <Media
                    data={field[1]}
                    errors={errors}
                    key={name}
                    name={name}
                    register={register}
                    label={label}
                    setValue={setValue}
                    watch={watch}
                    token={token}
                  />
                </SingleField>
              );
            } else if (field[1].entity_type.machine_name === "field_config") {
              return (
                <SingleField med>
                  <EntityReferenceFieldConfig
                    data={field[1]}
                    errors={errors}
                    key={name}
                    name={name}
                    register={register}
                    label={label}
                    setValue={setValue}
                    watch={watch}
                  />
                </SingleField>
              );
            } else {
              return (
                <SingleField small>
                  <EntityReferenceRevisions
                    data={field[1]}
                    errors={errors}
                    key={name}
                    name={name}
                    register={register}
                    label={label}
                    setValue={setValue}
                    watch={watch}
                    token={token}
                  />
                </SingleField>
              );
            }
          } else {
            if (name === "roles") {
              return (
                <SingleField small>
                  <EntityReferenceRevisions
                    data={field[1]}
                    errors={errors}
                    key={name}
                    name={name}
                    register={register}
                    label={label}
                    setValue={setValue}
                    watch={watch}
                    token={token}
                  />
                </SingleField>
              );
            }
            return (
              <SingleField small>
                <String
                  data={field[1]}
                  errors={errors}
                  setValue={setValue}
                  key={name}
                  name={name}
                  register={register}
                />
              </SingleField>
            );
          }

        case "entity_reference_revisions":
          if (!location.pathname.includes("product_builder_logic")) {
            if (
              location.pathname.includes("product_builder_parts") &&
              name === "field_pb_part_condition"
            )
              return;
            if (field[1].entity_type.machine_name === "paragraph") {
              return (
                <SingleField>
                  <EntityParagraph
                    errors={errors}
                    register={register}
                    data={field[1]}
                    key={name}
                    name={name}
                    label={label}
                    setValue={setValue}
                    watch={watch}
                    unregister={unregister}
                    token={token}
                    control={control}
                    location={location}
                  />
                </SingleField>
              );
            } else {
              return (
                <SingleField small>
                  <EntityReferenceRevisions
                    data={field[1]}
                    errors={errors}
                    key={name}
                    name={name}
                    register={register}
                    label={label}
                    setValue={setValue}
                    watch={watch}
                    token={token}
                  />
                </SingleField>
              );
            }
          } else {
            return;
          }
        case "webform":
          return (
            <SingleField small>
              <WebForm
                data={field[1]}
                errors={errors}
                key={name}
                name={name}
                register={register}
                label={label}
                setValue={setValue}
                watch={watch}
              />
            </SingleField>
          );
        // case "address_country":
        //   return (
        //     <SingleField small>
        //       <CountryAddress
        //         data={field[1]}
        //         errors={errors}
        //         key={name}
        //         name={name}
        //         register={register}
        //         label={label}
        //         setValue={setValue}
        //         watch={watch}
        //       />
        //     </SingleField>
        //   );

        case "list_string":
          return (
            <SingleField small>
              <ListString
                data={field[1]}
                errors={errors}
                key={name}
                name={name}
                register={register}
                label={label}
                setValue={setValue}
                watch={watch}
              />
            </SingleField>
          );
        default:
          return (
            <SingleField>
              <div key={name}>
                <span className="red-text">Invalid field</span>
              </div>
            </SingleField>
          );
      }
    });
  }
};
function Form({
  data,
  token,
  type,
  bundle,
  id,
  name,
  addNew,
  isSaved,
  nested,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    getValues,
    unregister,
    control,
    formState,
  } = useForm();
  const { state, dispatch } = useContext(StateContext);
  const location = useLocation();
  const history = useHistory();
  const onSubmit = (data) => {
    for (let key in data) {
      if (data[key] === undefined) data[key] = null;
    }
    if (location.pathname.includes("add-new-") || addNew) {
      let body = {
        entity_type: name,
        entity_bundle: bundle,
        entity_title: name,
        json: data,
      };
      let locationBody = data?.customBodyLocation?.body;
      let locationFieldName = data?.customBodyLocation?.name;
      let locId = "";
      if (locationBody && locationFieldName) {
        body = { ...body, ...locationBody };
      }
      fetch(`${activeHost()}/api/o2o/add-entity-single-item`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token,
        },
        body: JSON.stringify(body),
      })
        .then(async (res) => {
          if (res.status === 200) {
            // history.push(`/${type}`);
            dispatch({
              type: "SUCCESSFUL_TOAST",
              payload: "Successfully added new data " + name + " " + bundle,
            });
            let data = await res.json();
            if (nested) {
              // let data = res.json();
              isSaved(data);
            } else {
              history.push(`/edit_${name}/${bundle}/${data.id[0].value}`);
            }
          } else {
            dispatch({
              type: "SUCCESSFUL_TOAST",
              payload: "Form data submit failed",
            });
          }
        })
        .catch((e) => {
          console.log(e);
          dispatch({
            type: "SUCCESSFUL_TOAST",
            payload: "failed to add new data " + name + " " + bundle,
          });
          if (nested) {
            // let data = res.json();
            isSaved(false);
          }
        });
    } else {
      fetch(`${activeHost()}/api/o2o/entity_form/${type}.${bundle}/${id}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token,
        },
        body: JSON.stringify({
          json: data,
        }),
      }).then((res) => {
        if (res.status === 200) {
          // history.push(`/${type}`);
          dispatch({
            type: "SUCCESSFUL_TOAST",
            payload: "Successfully submitted form data",
          });
        } else {
          dispatch({
            type: "SUCCESSFUL_TOAST",
            payload: "Form data submit failed",
          });
        }
      });
    }
  };
  const saveClose = (data) => {
    // const data = getValues();
    for (let key in data) {
      if (data[key] === undefined) data[key] = null;
    }
    fetch(`${activeHost()}/api/o2o/entity_form/${type}.${bundle}/${id}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify({
        json: data,
      }),
    }).then((res) => {
      if (res.status === 200) {
        window.PressedBack = true;
        history.goBack();
        dispatch({
          type: "SUCCESSFUL_TOAST",
          payload: "Successfully submitting form data",
        });
      } else {
        dispatch({
          type: "SUCCESSFUL_TOAST",
          payload: "Form data submit failed",
        });
      }

      // history.push(`/${type}`);
    });
  };
  const handleDelete = async () => {
    let id = data.id.current_value;
    await deleteEntity(token, type, bundle, id).then((res) => {
      if (res.status === 200) {
        // history.push(`/${type}`);
        window.PressedBack = true;
        history.goBack();
        dispatch({
          type: "SUCCESSFUL_TOAST",
          payload: `Successfully deleted ${id} from ${type} ${bundle}`,
        });
      } else {
        dispatch({
          type: "SUCCESSFUL_TOAST",
          payload: `${id} from ${type} ${bundle} failed to delete`,
        });
      }
    });
  };
  console.log(watch());
  const [allocModal, setAllocModal] = useState(false);
  return (
    <div style={{ marginTop: 20 }}>
      {type === "asset" && bundle === "default" && !addNew && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "5px",
          }}
        >
          <AllocStock data={data} token={token} />

          <ViewInShopify data={data} />
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        {Object.keys(data).length && name !== "order" ? (
          renderFields(
            data,
            errors,
            register,
            setValue,
            watch,
            null,
            unregister,
            null,
            token,
            control,
            location,
            type ? type : name,
            addNew
          )
        ) : name !== "order" ? (
          <EditOrderForm data={data} />
        ) : (
          <h4>No fields available</h4>
        )}

        <div>
          <div class="Polaris-PageActions">
            <div class="Polaris-Stack Polaris-Stack--spacingTight Polaris-Stack--distributionEqualSpacing">
              {!addNew && (
                <div class="Polaris-Stack__Item">
                  <div class="Polaris-ButtonGroup">
                    <div class="Polaris-ButtonGroup__Item">
                      <button
                        class="Polaris-Button Polaris-Button--destructive"
                        type="button"
                        onClick={handleDelete}
                      >
                        <span class="Polaris-Button__Content">
                          <span class="Polaris-Button__Text">Delete</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div class="Polaris-Stack__Item">
                {!location.pathname.includes("add-new-") && !addNew ? (
                  <button
                    class="Polaris-Button Polaris-Button--primary"
                    type="button"
                    onClick={handleSubmit(saveClose)}
                    style={{ marginRight: "60px" }}
                  >
                    <span class="Polaris-Button__Content">
                      <span class="Polaris-Button__Text">Save & Close</span>
                    </span>
                  </button>
                ) : null}
                <button
                  class="Polaris-Button Polaris-Button--primary"
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                >
                  <span class="Polaris-Button__Content">
                    <span class="Polaris-Button__Text">Save</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div id="PolarisPortalsContainer"></div>
        </div>
      </form>
    </div>
  );
}

export default Form;
