/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import React from "react";
// import { BoxCard, BoxCardGroup } from "../Cards/Dashboard/BoxCard"
// import { Table, Tr, Td } from "../Table/TableElements"

function Skeletonm(props) {
  const { dark, height, width, rounded } = props;

  let styles = css`
    min-height: ${height ? height + "px" : "none"};
    border-radius: ${rounded ? rounded : 0};
    min-width: ${width ? width + "px" : "none"};
    background: ${dark ? '#EBEBEB' : '#F7F7F7'}
  `;
  return (
    <div className={`animate-pulse w-full h-full`} css={styles}>
      <div
        className={`h-full w-full`}
        css={styles}
      ></div>
    </div>
  );
}

export default function Skeleton(props) {
  // const { table, boxCard } = props;
  return (
    <>
      <Skeletonm {...props} />
    </>
  );
}
