import React, { useState, useEffect, createContext } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import AddNewTemplate from "../Pages/AddNewTemplate";
import Select from "react-select";
import EditPackagesTemplate from "../Pages/EditPackagesTemplate";
import PackagesTemplate from "../Pages/PackagesTemplate";
// import ManageFrontSearch from "../Components/ManageFrontSearch/ManageFrontSearch";
import { PackagesListProvider } from "../Context/PackagesListContext";
import DashBoard from "../Pages/DashBoard";
import BreadCrumb from "../Components/Elements/BreadCrumb";

//const routes = require('./Routes.json')

const SelectContext = createContext();
export default function RouteConfig({ token, user, entityroutes }) {
  // console.log(entityroutes);
  const generateListRoute = () => {
    if (entityroutes) {
      let route = Object.entries(entityroutes).map((field, i) => {
        let { title } = field[1];
        let name = field[0];
        if (name === "status") {
          return null;
        }
        return {
          path: `/${name}`,
          title: title,
          name: name,
          data: field[1],
        };
      });
      let filtered = route.filter(Boolean);
      return filtered;
    }
  };
  const ListRoutes = generateListRoute();
  const generateAddNewRoute = () => {
    if (entityroutes) {
      let route = Object.entries(entityroutes).map((field, i) => {
        let { bundles, title } = field[1];
        let name = field[0];
        if (name === "status") {
          return null;
        }
        if (name === "order") {
          return null;
        }
        return {
          path: `/add-new-${name}`,
          title: title,
          name: name,
          routes: Object.entries(bundles).map((value, ii) => {
            return {
              path: `/add-new-${name}/${value[0]}`,
              title: value[1],
              name: value[0],
            };
          }),
        };
      });
      let filtered = route.filter(Boolean);
      return filtered;
    }
  };
  const AddNewroutes = generateAddNewRoute();
  const generateEditRoute = () => {
    if (entityroutes) {
      let route = Object.entries(entityroutes).map((field, i) => {
        let { bundles, title } = field[1];
        let name = field[0];
        if (name === "status") {
          return null;
        }

        return Object.entries(bundles).map((value, ii) => {
          return {
            path: `/edit_${name}/${value[0]}/:id`,
            title: value[1],
            name: name,
            bundleName: value[0],
          };
        });
      });
      let filtered = route.filter(Boolean);
      return filtered;
    }
  };
  const Editroutes = generateEditRoute();
  // console.log(Editroutes);
  return (
    <Switch>
      <Route path="/" exact>
        {user ? <DashBoard token={token} /> : null}
      </Route>

      {ListRoutes &&
        ListRoutes.map((route, i) => {
          return (
            <RouteList
              requestBundles={entityroutes}
              key={i}
              {...route}
              token={token}
            />
          );
        })}

      {
        //<Route path='/manage-front-search' exact component={() => <ManageFrontSearch token={token} pageTitle="Update Front Search Records" />}/>}
        // <Route
        //   path="/product_builder_package"
        //   exact
        //   component={() => (
        //     <ProductBuilderPackage
        //       token={token}
        //       pageTitle="Product Builder Package"
        //     />
        //   )}
        // />
        // <Route
        //   path="/edit-product_builder_package/:id"
        //   exact
        //   component={() => (
        //     <EditProductBuilderPackage
        //       token={token}
        //       pageTitle="Edit Product Builder Package"
        //     />
        //   )}
        // />
        // <Route
        //   path="/add-new-product_builder_package/pb_package"
        //   exact
        //   component={() => (
        //     <AddNewProducBuilderPackage
        //       token={token}
        //       pageTitle="Add New Product Builder Package"
        //     />
        //   )}
        // />
        // <Route
        //   path="/model3d"
        //   exact
        //   component={() => <Model3d token={token} pageTitle="Model3d" />}
        // />
        // <Route
        //   path="/installation-blurbs"
        //   exact
        //   component={() => (
        //     <InstallationBlurbs token={token} pageTitle="Installation Blurbs" />
        //   )}
        // />
      }
      {AddNewroutes &&
        AddNewroutes.map((route, i) => (
          <RouteWithSubRoutesAddNew key={i} {...route} token={token} />
        ))}

      {Editroutes &&
        Editroutes.map((v, i) =>
          v.map((route, index) => {
            return <RouteEdit key={index} {...route} token={token} />;
          })
        )}
      <Route path="*" exact>
        <h1>404 not Found</h1>
      </Route>
    </Switch>
  );
}
function RouteList(props) {
  return (
    <Route
      path={props.path}
      exact
      render={(propss) => (
        // pass the sub-routes down to keep nesting
        <PackagesListProvider>
          <PackagesTemplate
            key={props.path}
            entities={props.requestBundles}
            {...propss}
            name={props.name}
            title={props.title}
            token={props.token}
          />{" "}
        </PackagesListProvider>
      )}
    />
  );
}
function RouteEdit(props) {
  console.log(props);
  return (
    <Route
      exact
      path={props.path}
      render={(propss) => (
        // pass the sub-routes down to keep nesting
        <EditPackagesTemplate
          key={props.path}
          {...propss}
          name={props.name}
          bundle={props.bundleName}
          title={props.title}
          token={props.token}
        />
      )}
    />
  );
}
function RouteWithSubRoutesAddNew(props) {
  console.log(props);
  return (
    <Route
      path={props.path}
      render={(propss) => (
        // pass the sub-routes down to keep nesting
        <PrimaryRoutesAddNew
          key={props.path}
          {...propss}
          name={props.name}
          title={props.title}
          token={props.token}
          routes={props.routes}
        />
      )}
    />
  );
}

function PrimaryRoutesAddNew({ name, title, token, routes, ...propss }) {
  console.log(propss);
  const routesData = routes.map((route, ii) => {
    return { value: route.name, label: route.title };
  });
  const [defaultValue, setDefaultValue] = useState(null);
  useEffect(() => {
    routesData.map((value) => {
      if (propss.location.pathname === `/add-new-${name}/${value.value}`) {
        console.log(value);
        setDefaultValue(value);
      }
    });
  }, [propss.location.pathname]);

  const customStyles = {
    container: (base) => ({
      ...base,
      fontSize: "1.4rem",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 334444444,
      fontSize: "1.4rem",
    }),
  };

  const handleChange = (value, action) => {
    console.log(value);
    setDefaultValue(value);
    propss.history.push(`/add-new-${name}/${value.value}`);
  };
  return (
    <div>
      {propss.location.pathname !== `/add-new-${name}` ? (
        <>
          {defaultValue ? (
            <>
              <h1 style={{ marginBottom: "10px" }}>
                Add New {defaultValue.label} of {title}
              </h1>
              <hr />
            </>
          ) : null}
        </>
      ) : (
        <>
          <h1 style={{ marginBottom: "10px" }}>Add New {title}</h1>
          <p>Which type of {title} do you want to create? </p>
          <Select
            name={name}
            options={routesData}
            isSearchable={true}
            onChange={(value, action) => {
              handleChange(value, action);
            }}
            styles={customStyles}
          />
        </>
      )}

      <Switch>
        {routes &&
          routes.map((route, i) => (
            <Route
              path={route.path}
              key={i}
              exact
              render={() => (
                // pass the sub-routes down to keep nesting
                <AddNewTemplate
                  key={route.path}
                  title={route.title}
                  token={token}
                  name={name}
                  bundle={route.name}
                />
              )}
            />
          ))}
      </Switch>
    </div>
  );
}
