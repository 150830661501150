import React, { createContext, useReducer, useState, useEffect } from "react";

export const PackagesListContext = createContext();
const initialState = {
  items: [],
  prevPageItems: [],
  nextPageItems: [],
  isLoading: true,
  filterItems: [],
  allBundlesItems: [],
  bundles: [],
  totalPages: [],
  page: 1,
  hasPrevious: true,
  hasNext: true,
  inputValue: "",
  checkBoxValue: "",
};
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CHECKBOX_VALUE":
      return {
        ...state,
        checkBoxValue: action.payload,
      };
    case "SET_SEARCH_VALUE":
      return {
        ...state,
        inputValue: action.payload,
      };
    case "SET_HAS_NEXT":
      return {
        ...state,
        hasNext: action.payload,
      };
    case "SET_HAS_PREVIOUS":
      return {
        ...state,
        hasPrevious: action.payload,
      };

    case "SET_PAGE":
      return {
        ...state,
        page: action.payload,
      };

    case "SET_TOTAL_PAGE":
      return {
        ...state,
        totalPages: action.payload,
      };

    case "SET_ALL_BUNDLES_ITEMS":
      return {
        ...state,
        allBundlesItems: action.payload,
      };
    case "SET_BUNDLES_ITEMS":
      return {
        ...state,
        bundles: action.payload,
      };
    case "SET_ITEMS":
      return {
        ...state,
        items: action.payload,
        isLoading: false,
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "SET_NEXT_ITEMS":
      return {
        ...state,
        nextPageItems: action.payload,
      };
    case "SET_PREV_ITEMS":
      return {
        ...state,
        prevPageItems: action.payload,
        prevPageNumber: action.pageNumber,
      };
    case "SET_FILTER_ITEMS":
      return {
        ...state,
        filterItems: action.payload,
      };
    case "SET_STATE":
      return {
        ...action.payload,
        // filterItems: action.payload,
      };

    default:
      return state;
  }
};
const useBackButton = () => {
  const [isBack, setIsBack] = useState(false);
  const handleEvent = () => {
    setIsBack(true);
  };

  useEffect(() => {
    window.addEventListener("popstate", handleEvent);
    return () => window.removeEventListener("popstate", handleEvent);
  });

  return isBack;
};
export const PackagesListProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [sortOptions, setSortOption] = useState(null);
  const [otherItems, setOtherItmes] = useState([]);
  const [imageColN, setImageColN] = useState(false);
  useEffect(() => {
    window.listState = { ...state, sortOptions, otherItems, imageColN };
  }, [state]);
  return (
    <PackagesListContext.Provider
      value={{
        state: { ...state, sortOptions, otherItems, imageColN },
        dispatch,
        setSortOption,
        setOtherItmes,
        setImageColN,
      }}
    >
      {children}
    </PackagesListContext.Provider>
  );
};

export const PackagesListConsumer = PackagesListContext.Consumer;
