import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useContext,
} from "react";
import { StateContext } from "../Context/StateContext";
import {
  AppProvider,
  Avatar,
  Icon,
  VisuallyHidden,
  ActionList,
  Popover,
  Frame,
  TopBar,
  SkeletonDisplayText,
  Button,
  Modal,
  TextContainer,
} from "@shopify/polaris";
import CircularProgress from "@mui/material/CircularProgress";
import Caches from "./Caches";
import {
  ArrowLeftMinor,
  QuestionMarkMajor,
  TroubleshootMajor,
  DeleteMinor,
} from "@shopify/polaris-icons";
import { FaDrupal, FaShopify } from "react-icons/fa";
import Select from "react-select";
import {
  LogOut,
  activeHost,
  Login,
  myO2O as CallMyO2O,
  sessToken,
  getSearchData,
} from "../Helpers/DrupalHelper";
import { ContactSupportOutlined } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import { ShoppingBag } from "@mui/icons-material";

const FaDrupalIcon = () => <FaDrupal style={{ fontSize: "19px" }} />;

let opts = ["dev", "test", "live"];
// if (window.location.href.includes("localhost")) opts.shift();

const optionsHost = opts.map((v) => {
  return { value: v, label: v.toUpperCase() };
});
export default function TopBarComponent(props) {
  const { state, dispatch } = useContext(StateContext);
  const { token, isAuthenticated, user } = state;
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [entities, setEntities] = useState([]);
  const [isSecondaryMenuOpen, setIsSecondaryMenuOpen] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [noResult, setNoResult] = useState(false);
  const [timer, setTimer] = useState(null);
  const [selectValue, setSelectValue] = useState(null);
  const [selectValueHost, setSelectValueHost] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [cacheModal, setCacheModal] = useState(false);
  const firstHost = localStorage.getItem("HOST_NAME");
  // useEffect(async () => {
  //   let data = localStorage.getItem("HOST_NAME");

  //   if (selectValueHost) {
  //     await sessToken().then(async (token) => {
  //       await CallMyO2O(token).then(async (data) => {
  //         if (data.isAuthenticated === true) {
  //           sessionStorage.setItem("user", JSON.stringify(data));
  //           // setLoading(false);
  //           dispatch({
  //             type: "LOGIN",
  //             payload: data,
  //           });
  //           dispatch({
  //             type: "SUCCESSFUL_TOAST",
  //             payload: `Welcome ${data.name}, login successful.`,
  //           });
  //           dispatch({
  //             type: "TOKEN_ONLY",
  //             payload: token,
  //           });
  //         } else {
  //           dispatch({
  //             type: "LOGOUT",
  //           });
  //         }
  //       });
  //     });
  //   }
  // }, [selectValueHost]);
  useEffect(() => {
    let data = localStorage.getItem("HOST_NAME");
    //should replace this filter like `-.pantheonsite.io`
    if (data === "https://api.useo2o.com") setSelectValueHost(optionsHost[2]);
    else if (data.includes("dev")) setSelectValueHost(optionsHost[0]);
    else if (data.includes("test")) setSelectValueHost(optionsHost[1]);
  }, []);
  const handleChangeHost = async (v) => {
    // const inputRef = action.name;
    localStorage.clear();
    //should replace this filter like `https://-.pantheonsite.io`

    let host;
    if (v.value === "live") host = `https://api.useo2o.com`;
    else {
      host = `https://${v.value}.api.useo2o.com`;
    }

    localStorage.setItem("HOST_NAME", host);

    let data = localStorage.getItem("HOST_NAME");
    setSelectValueHost(v);
    window.location.reload();
    // await sessToken().then(async (token) => {
    //   await CallMyO2O(token).then(async (data) => {
    //     if (data.isAuthenticated === true) {
    //       sessionStorage.setItem("user", JSON.stringify(data));
    //       // setLoading(false);
    //       dispatch({
    //         type: "LOGIN",
    //         payload: data,
    //       });
    //       dispatch({
    //         type: "SUCCESSFUL_TOAST",
    //         payload: `Welcome ${data.name}, swtiched to host ${v.value} successful.`,
    //       });
    //       dispatch({
    //         type: "TOKEN_ONLY",
    //         payload: token,
    //       });
    //     } else {
    //       dispatch({
    //         type: "LOGOUT",
    //       });
    //     }
    //   });
    // });
  };
  const toggleCacheModal = () => {
    setCacheModal(!cacheModal);
  };

  const cacheButton = useRef();

  // const activator = <Button onClick={handleChange}>Open</Button>;

  useEffect(() => {
    if (props.entities) {
      const values = Object.entries(props.entities).map((entity) => {
        return {
          value: entity[0],
          label: entity[1].title,
        };
      });
      setEntities([...values]);
      setSelectValue(values[0]);
    }
  }, [props.entities]);
  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
    []
  );

  const history = useHistory();
  const handleSearchResultsDismiss = useCallback(() => {
    setIsSearchActive(false);
    setSearchValue("");
  }, []);

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setIsSearchActive(value.length > 0);
    clearTimeout(timer);

    if (value) {
      setNoResult(false);

      const newTimer = setTimeout(async () => {
        let body = {
          entity: "asset",
          term: value,
          type: [],
        };
        if (selectValue !== null) {
          body = {
            ...body,
            entity: selectValue.value,
          };
        }
        let arrays = [];

        getSearchData(token, body).then((data) => {
          if (data.total) {
            Object.entries(data?.results).map((val) => {
              if (val[1]?.length > 5) {
                let arr = val[1].slice(0, 5).map((i) => {
                  let { id, bundle, title } = i;

                  arrays.push({
                    content: (
                      <Link
                        to={{
                          pathname: `/edit_${
                            selectValue?.value ? selectValue?.value : "asset"
                          }/${bundle}/${id}`,
                          state: { bundle: bundle },
                        }}
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        {title}{" "}
                        {
                          //bundle
                        }
                      </Link>
                    ),
                    onClick: () =>
                      history.push(
                        `/edit_${
                          selectValue?.value ? selectValue?.value : "asset"
                        }/${bundle}/${id}`
                      ) &&
                      setSearchValue("") &&
                      setSearchResults([]),
                    onAction: () =>
                      history.push(
                        `/edit_${
                          selectValue?.value ? selectValue?.value : "asset"
                        }/${bundle}/${id}`
                      ) &&
                      setSearchValue("") &&
                      setSearchResults([]),
                  });
                });
                // arrays.push([...val[1].slice(0, 5)]);
              } else {
                let arr = val[1].map((i) => {
                  let { id, bundle, title } = i;

                  arrays.push({
                    content: (
                      <Link
                        to={{
                          pathname: `/edit_${
                            selectValue?.value ? selectValue?.value : "asset"
                          }/${bundle}/${id}`,
                          state: { bundle: bundle },
                        }}
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        {title}
                      </Link>
                    ),
                    onClick: () =>
                      history.push(
                        `/edit_${
                          selectValue?.value ? selectValue?.value : "asset"
                        }/${bundle}/${id}`
                      ) &&
                      setSearchValue("") &&
                      setSearchResults([]),
                    onAction: () =>
                      history.push(
                        `/edit_${
                          selectValue?.value ? selectValue?.value : "asset"
                        }/${bundle}/${id}`
                      ) &&
                      setSearchValue("") &&
                      setSearchResults([]),
                  });
                });
              }
            });
          } else {
            setNoResult(true);
          }
          if (arrays.length) {
            setSearchResults([...arrays]);
          } else {
            setSearchResults([...arrays]);
          }
        });
      }, 700);
      setTimer(newTimer);
    }
  };

  const handleSelectChange = (v) => setSelectValue(v);
  const [active, setActive] = useState(true);

  const userMenuMarkup = (
    <>
      <TopBar.UserMenu
        actions={[
          {
            items: [
              {
                content: "Back to app.useo2o.com",
                icon: ArrowLeftMinor,
                url: "https://app.useo2o.com",
              },
            ],
          },
          {
            items: [
              {
                content: "Access Drupal",
                icon: FaDrupalIcon,
                url: "https://api.useo2o.com/admin",
              },
            ],
          },
          {
            items: [
              {
                content: "Access Shopify",
                icon: FaShopify,
                url: "https://order.useo2o.com/admin",
              },
            ],
          },
          {
            items: [
              {
                content: "Manage Caches",
                icon: DeleteMinor,
                onAction: toggleCacheModal,
              },
            ],
          },
        ]}
        name={state.user.name}
        detail={state.user.email}
        initials={
          state.user.name.charAt(0).toUpperCase() ||
          state.user.email.charAt(0).toUpperCase()
        }
        open={isUserMenuOpen}
        onToggle={toggleIsUserMenuOpen}
      />
      <div
        style={{
          minWidth: "150px",
          boxSizing: "border-box",
          // position: "absolute", left: 0, top: 0, width: "150px"
        }}
      >
        <Select
          options={optionsHost}
          defaultValue={selectValueHost}
          isSearchable={true}
          onChange={handleChangeHost}
          // styles={customStyles}
          isClearable={false}
          value={selectValueHost}
          placeholder="Host"
          styles={{
            container: (provided, state) => ({
              ...provided,
              // width: " 20%",
              flexGrow: 1,
              // maxWidth: "50%",
              paddingLeft: "5px",
              paddingRight: "5px",
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              flexWrap: "nowrap",
            }),
            control: (provided, state) => ({
              ...provided,
              borderColor: "rgba(0,0,0,.1)",
            }),
            indicatorSeparator: (provided, state) => ({
              ...provided,
              backgroundColor: "transparent",
            }),
          }}
        />
      </div>
      <TopBar.Menu
        activatorContent={
          <span
            onClick={() => {
              dispatch({
                type: "SUCCESSFUL_TOAST",
                payload: `Logging out, please wait...`,
              });
              LogOut(state.logOutToken)
                .then((res) => {
                  if (res.ok) {
                    dispatch({
                      type: "LOGOUT",
                    });
                    dispatch({
                      type: "SUCCESSFUL_TOAST",
                      payload: `Logged out...`,
                    });
                  }
                })
                .catch((e) => {
                  console.log(e);
                  dispatch({
                    type: "SUCCESSFUL_TOAST",
                    payload: `Logging out failed...`,
                  });
                });
            }}
          >
            Logout
          </span>
        }
      />
    </>
  );

  const searchResultsMarkup =
    active && searchValue ? (
      <div style={{ overflow: "scroll", overflowX: "hidden" }}>
        <ActionList
          items={
            searchResults.length > 0
              ? [...searchResults]
              : [
                  {
                    content: (
                      <>
                        {noResult ? (
                          <p>Search has no result </p>
                        ) : (
                          <>
                            <SkeletonDisplayText size="small" />
                            <br />
                            <SkeletonDisplayText size="small" />
                            <br />
                            <SkeletonDisplayText size="small" />
                          </>
                        )}
                      </>
                    ),
                  },
                ]
          }
          onActionAnyItem={(v) => {
            setActive(false);
            setSearchResults([]);
            setSearchValue("");
          }}
        />
      </div>
    ) : null;

  const searchFieldMarkup = (
    <div style={{ display: "flex" }}>
      <div style={{ width: "70%" }}>
        <TopBar.SearchField
          onChange={handleSearchChange}
          value={searchValue}
          placeholder="Search"
          // style={{ width: "80%" }}
          showFocusBorder
          onFocus={() => setActive(true)}
          // onBlur={() => setSearchResults([]) && setSearchValue("")}
        />
      </div>
      <Select
        options={entities.filter((v) => v.label !== "Status")}
        defaultValue={{ label: "Asset", value: "asset" }}
        isSearchable={true}
        onChange={handleSelectChange}
        // styles={customStyles}
        value={selectValue}
        isClearable={false}
        // isMulti
        // placeholder="All content"
        styles={{
          container: (provided, state) => ({
            ...provided,
            // width: " 20%",
            flexGrow: 1,
            maxWidth: "50%",
            paddingLeft: "5px",
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            flexWrap: "nowrap",
          }),
          control: (provided, state) => ({
            ...provided,
            borderColor: "rgba(0,0,0,.1)",
          }),
          indicatorSeparator: (provided, state) => ({
            ...provided,
            backgroundColor: "transparent",
          }),
        }}
      />
    </div>
  );

  // const secondaryMenuMarkup = (
  //   <TopBar.Menu
  //     activatorContent={
  //       <span>
  //         <Icon source={QuestionMarkMajor} />
  //         <VisuallyHidden>Secondary menu</VisuallyHidden>
  //       </span>
  //     }
  //     open={isSecondaryMenuOpen}
  //     onOpen={toggleIsSecondaryMenuOpen}
  //     onClose={toggleIsSecondaryMenuOpen}
  //     actions={[
  //       {
  //         items: [{ content: "Community forums" }],
  //       },
  //     ]}
  //   />
  // );

  return (
    <>
      <TopBar
        showNavigationToggle
        userMenu={userMenuMarkup}
        // secondaryMenu={secondaryMenuMarkup}
        searchResultsVisible={isSearchActive}
        searchField={searchFieldMarkup}
        searchResults={searchResultsMarkup}
        onSearchResultsDismiss={handleSearchResultsDismiss}
        // onNavigationToggle={handleNavigationToggle}
      />
      <Modal
        instant
        open={cacheModal}
        onClose={toggleCacheModal}
        title="Manage caches"
        // ]}
      >
        <Modal.Section>
          <Caches />
        </Modal.Section>
      </Modal>
    </>
  );
}
