/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import React from "react";

function QuantitySelect(props) {
  const { small, decrement, increment, count } = props;

  return (
    <div className="flex ">
      <div
        className={`custom-number-input ${
          small ? `h-[32px] w-[62px] px-[4px]` : `h-[50px] w-[30%]`
        }  flex bg-white flex-row items-center overflow-hidden lg:mr-4`}
        css={css`
          background: #ffffff;
          border: 1px solid #ebebeb;
          box-sizing: border-box;
          border-radius: 8px;
          &:hover {
            border-color: #222;
          }
          min-width: ${small ? `62px` : `none`};
        `}
      >
        <button
          data-action="decrement"
          onClick={decrement}
          className={`h-full ${
            small ? `w-[20px] relative -top-[1px]` : `w-18`
          } cursor-pointer outline-none bg-white overflow-hidden ${
            small && `text-reg-12`
          }`}
          css={css`
            border-radius: 8px 0 0 8px;
          `}
        >
          <span className="">−</span>
        </button>
        <input
          type="text"
          className={` ${
            small ? `text-reg-12` : `text-med-14`
          } outline-none focus:outline-none focus:ring-0 text-center w-full bg-white border-0 overflow-hidden p-0 z-[1] relative`}
          name="custom-input-number"
          value={count}
        />
        <button
          data-action="increment"
          onClick={increment}
          className={`h-full ${
            small ? `w-[20px] relative -top-[1px]` : `w-18`
          } cursor-pointer bg-white overflow-hidden ${small && `text-reg-12`}`}
          css={css`
            border-radius: 0 8px 8px 0;
          `}
        >
          <span className="">+</span>
        </button>
      </div>
    </div>
  );
}

export default QuantitySelect;
