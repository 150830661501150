/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import React from "react";
import { ReactComponent as Icon } from "../Assets/icons/delivery.svg";
// import { red } from "../Styles";
const red = "#E43D30";
function MetaDelivery(props) {
  const { large } = props;

  let styles = `
    font-family: 'Jost';
    color: ${red};`;
  if (!large)
    styles = `${styles}
      font-size: 10px;
      font-weight: 600;`;
  if (large)
    styles = `${styles}
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.03em;`;

  return (
    <div
      css={css`
        ${styles}
      `}
      className="red flex items-center uppercase"
    >
      <Icon className={large ? "mr-3" : "mr-2"} />
      Delivery
    </div>
  );
}

export default MetaDelivery;
