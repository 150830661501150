import React from "react";
import { css } from "@emotion/react";
// import Next from "../../Assets/icons/next.svg";
import { Link, useLocation } from "react-router-dom";
import { toCapitalCase } from "../../utils";

function BreadCrumb(props) {
  // const location = useLocation();
  // console.log(location);
  // const routeArray = location.pathname.split("/");
  return (
    <nav class="flex md:mb-3" aria-label="Breadcrumb">
      <ol class="inline-flex items-center space-x-3">
        {props.list ? (
          <li class="inline-flex items-center">
            <Link
              to="/"
              className="inline-flex items-center text-med-14"
              css={css`
                &&& {
                  color: #717171;
                  letter-spacing: 0.02em;
                  &:hover {
                    color: #e43d30;
                  }
                }
              `}
            >
              Dashboard /
            </Link>
          </li>
        ) : (
          <li class="inline-flex items-center">
            <p
              className="inline-flex items-center text-med-14"
              css={css`
                &&& {
                  color: #717171;
                  letter-spacing: 0.02em;
                  &:hover {
                    color: #e43d30;
                  }
                }
              `}
            >
              Dashboard /
            </p>
          </li>
        )}
        {props.list &&
          props.list.length &&
          props.list.map((v, k) => (
            <>
              {props.list.length - 1 === k ? (
                <li class="inline-flex items-center" key={k}>
                  <p
                    to={v.link}
                    className="inline-flex items-center text-med-14"
                    css={css`
                      &&& {
                        color: #717171;
                        letter-spacing: 0.02em;
                        &:hover {
                          color: #e43d30;
                        }
                      }
                    `}
                  >
                    {toCapitalCase(v.title)}
                  </p>
                </li>
              ) : (
                <li class="inline-flex items-center" key={k}>
                  <Link
                    to={v.link}
                    className="inline-flex items-center text-med-14"
                    css={css`
                      &&& {
                        color: #717171;
                        letter-spacing: 0.02em;
                        &:hover {
                          color: #e43d30;
                        }
                      }
                    `}
                  >
                    {toCapitalCase(v.title)} /
                  </Link>
                </li>
              )}
            </>
          ))}
      </ol>
    </nav>
  );
}

export default BreadCrumb;
