export const activeHost = () => {
  if (localStorage.getItem("HOST_NAME")) {
    console.log("host is ", localStorage.getItem("HOST_NAME"));
    return localStorage.getItem("HOST_NAME");
  } else {
    console.log("host is ", process.env.REACT_APP_HOSTNAME);

    return process.env.REACT_APP_HOSTNAME;
  }
};

export const sessToken = async () => {
  let response = await fetch(`${activeHost()}/session/token`, {
    method: "GET",
    credentials: "include",
  });
  let data = await response.text();
  return data;
};

export const myO2O = async (csrfToken) => {
  let response = await fetch(`${activeHost()}/api/my`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  });
  let data = await response.json();
  return data;
};
export const getLocationFromId = async (csrfToken, id) => {
  let response = await fetch(`${activeHost()}/api/location/${id}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  });
  let data = await response.json();
  return data;
};
export const allEntities = async (csrfToken) => {
  let response = await fetch(`${activeHost()}/api/o2o/entities`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  });
  let data = await response.json();
  return data;
};
export const Login = async (csrfToken, username, password) => {
  let response = await fetch(`${activeHost()}/user/login?_format=json`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({
      name: username,
      pass: password,
      // "name":"zisan@cocoon",
      // "pass":"zisan@cocoon123"
    }),
  });
  let data = await response.json();
  return data;
};
export const LogOut = async (logout_token) => {
  return await fetch(
    `${activeHost()}/user/logout?_format=json&token=${logout_token}`,
    {
      method: "GET",
      credentials: "include",
    }
  );
};

export const entityForm = async (csrfToken, item, bundle) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/entity_form/${item}.${bundle}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  );
  let data = await response.json();
  return data;
};
export const getSearchData = async (token, body) => {
  let response = await fetch(`${activeHost()}/api/o2o/search`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": token,
    },
    body: [JSON.stringify([body])],
  });
  let data = await response.json();
  return data;
};
export const entityTemplatePagination = async (csrfToken, name) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/entity/${name}/pagination`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  );
  let data = await response.json();
  return data;
};
export const entityProductBuilderPagination = async (csrfToken) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/entity/product_builder/pagination`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  );
  let data = await response.json();
  return data;
};

export const entityProductBuilderPackagePagination = async (csrfToken) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/entity/product_builder_package/pagination`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  );
  let data = await response.json();
  return data;
};
export const entitiyProductBuilderPackagesBuild = async () => {
  let response = await fetch(
    `${activeHost()}/api/o2o/entity_form/product_builder_package.pb_package`,
    {
      method: "GET",
      credentials: "include",
    }
  );
  let data = await response.json();
  return data;
};
export const entitiyProductBuilderPackageEdit = async (id) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/entity_form/product_builder_package.pb_package/${id}`,
    {
      method: "GET",
      credentials: "include",
    }
  );
  let data = await response.json();
  return data;
};
export const entitiyFormEdit = async (name, bundle, id) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/entity_form/${name}.${bundle}/${id}`,
    {
      method: "GET",
      credentials: "include",
    }
  );
  let data = await response.json();
  return data;
};
export const entityModel3dPagination = async (csrfToken) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/entity/models3d/pagination`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  );
  let data = await response.json();
  return data;
};

export const entityInstallationBlurbsPagination = async (csrfToken) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/entity/installation_blurbs/pagination`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  );
  let data = await response.json();
  return data;
};

export const entitySpecSheetsPagination = async (csrfToken) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/node/product/pagination`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  );
  let data = await response.json();
  return data;
};

export const entityTemplate = async (
  csrfToken,
  name,
  pageNumber = 1,
  sortOptions,
  filters
) => {
  let url = `${activeHost()}/api/o2o/entity/${name}?page=${pageNumber}${
    filters ? filters : ""
  }`;
  if (sortOptions)
    url = `${activeHost()}/api/o2o/entity/${name}?page=${pageNumber}${
      filters ? filters : ""
    }&order=${sortOptions.name}&sort=${sortOptions.sort}`;
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  });
  let data = await response.json();
  return data;
};
export const entityProductBuilder = async (csrfToken, pageNumber = 1) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/entity/product_builder?page=${pageNumber}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  );
  let data = await response.json();
  return data;
};
export const EntitySuggestions = async (
  csrfToken,
  entity,
  bundle,
  extended
) => {
  let url = extended
    ? `${activeHost()}/api/o2o/entity_suggest?extended=true`
    : `${activeHost()}/api/o2o/entity_suggest`;
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({
      entity: entity,
      bundle: bundle,
    }),
  });
  let data = await response.json();
  return data;
};

export const entityProductBuilderPackage = async (
  csrfToken,
  pageNumber = 1
) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/entity/product_builder_package?page=${pageNumber}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  );
  let data = await response.json();
  return data;
};

export const entityModels3d = async (csrfToken, pageNumber = 1) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/entity/models3d?page=${pageNumber}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  );
  let data = await response.json();
  return data;
};

export const entityInstallationBlurbs = async (csrfToken, pageNumber = 1) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/entity/installation_blurbs?page=${pageNumber}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  );
  let data = await response.json();
  return data;
};

export const entitySpecSheets = async (csrfToken, pageNumber = 1) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/node/product?page=${pageNumber}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  );
  let data = await response.json();
  return data;
};
export const ProductBuilderPackageSearch = async (csrfToken, value) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/entity/product_builder_package/search`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        title: value,
      }),
    }
  );
  let data = await response.json();
  return data;
};
export const TemplateSearch = async (csrfToken, name, value, array) => {
  let response = await fetch(`${activeHost()}/api/o2o/entity/${name}/search`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({
      title: value,
      bundle: array,
    }),
  });
  let data = await response.json();
  return data;
};
export const ProductBuilderSearch = async (csrfToken, value, array) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/entity/product_builder/search`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        title: value,
        bundle: array,
      }),
    }
  );
  let data = await response.json();
  return data;
};
export const Models3dSearch = async (csrfToken, value, array) => {
  let response = await fetch(`${activeHost()}/api/o2o/entity/models3d/search`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({
      title: value,
      bundle: array,
    }),
  });
  let data = await response.json();
  return data;
};

export const requestBundles = async (csrfToken) => {
  let response = await fetch(`${activeHost()}/api/o2o/entities`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  });
  let data = await response.json();
  return data;
};
export const getDashboardOverview = async (csrfToken) => {
  let response = await fetch(`${activeHost()}/api/o2o/overview`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  });
  let data = await response.json();
  return data;
};
export const getFilterBundleDataPagination = async (
  csrfToken,
  entity,
  str,
  page = 1
) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/entity/${entity}/pagination?${str}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  );
  let data = await response.json();
  return data;
};
export const getFilterBundleData = async (csrfToken, entity, str, page = 1) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/entity/${entity}?page=${page}${str}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  );
  let data = await response.json();
  return data;
};
//for logic builder
export const entitySuggest = async (csrfToken, body, extended, params) => {
  let url = extended
    ? `${activeHost()}/api/o2o/entity_suggest?extended=true${
        params ? `${params}` : ""
      }`
    : `${activeHost()}/api/o2o/entity_suggest`;
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  });
  let data = await response.json();
  // if(data)data= data.json()
  if (body.entity === "user") {
    return data.map((v) => ({
      ...v,
      target_id: v.id,
      id: undefined,
      bundle: "user",
      title: v.username,
    }));
  }
  return data;
};
export const actionDataPackage = async (csrfToken, id) => {
  let response = await fetch(`${activeHost()}/api/package/${id}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  });
  let data = await response.json();
  return data;
};
export const loadEntitiesReferenceData = async (
  csrfToken,
  entityTypemachine_name,
  entities_machine_name,
  target_id
) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/entity_form/${entityTypemachine_name}.${entities_machine_name}/${target_id}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  );
  let data = await response.json();
  return data;
};
export const deleteEntity = async (csrfToken, entity, bundle, id) => {
  let response = await fetch(
    `${activeHost()}/api/o2o/entity_form/${entity}.${bundle}/${id}/delete`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  );

  return response;
};

export const rebuildCache = async (csrfToken, types = ["all"]) => {
  let response = await fetch(`${activeHost()}/api/o2o/rebuild`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({ types: types }),
  });

  return response;
};

export const getStockSingle = async (csrfToken, id) => {
  let response = await fetch(`${activeHost()}/api/stock/${id}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  });
  let data = await response.json();
  return data;
};
export const updateUserStock = async (csrfToken, STOCK_ITEM_ID, body) => {
  let url = `${activeHost()}/api/stock/${STOCK_ITEM_ID}
`;
  let response = await fetch(url, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  });
  // let data = await response.json()
  return response;
};

export const getMyListing = async (
  csrfToken,
  listName,
  bundle = "default",
  page = 1
) => {
  let response = await fetch(
    `${activeHost()}/api/my-listing/${listName}/${bundle}?page=${page}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    }
  );
  let data = await response.json();
  return data;
};

export const uploadFiles = async (csrfToken, MediaBundleMachineName, body) => {
  let url = `${activeHost()}/api/o2o/media/${MediaBundleMachineName}/new
`;
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: body,
  });
  // let data = await response.json()
  return response;
};

export const fileUploadDrupal = async (csrfToken, file) => {
  // console.log(file.name);
  // var reader = new FileReader();
  // let data = reader.readAsBinaryString(file);
  // console.log(data);
  let url = `${activeHost()}/file/upload/media/asset_file/field_media_file`;
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/octet-stream",
      "Content-Disposition": `file;filename="${file.name}"`,
      "X-CSRF-Token": csrfToken,
    },
    body: file,
  });
  let data = await response.json();
  return { response: response, data: data };
};

export const fileUploadToMediaEntityDrupal = async (
  csrfToken,
  bundle,
  data
) => {
  // console.log("THEDATA");
  // console.log(data);
  // console.log(data["fid"]);
  let url = `${activeHost()}/entity/media?_format=json`;
  let body = {};
  body.bundle = bundle;
  body.uid = data.uid;
  body.name = data.filename;
  body.field_media_file = [{ target_id: data["fid"][0].value }];

  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  });
  // let data = await response.json()
  return response;
};

// rebuildDrupalCache, rebuildAlgoliaCache, rebuildNetlifyFrontend;

export const rebuildDrupalCache = async (csrfToken) => {
  let response = await fetch(`${activeHost()}/api/o2o/rebuild/drupal`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  });
  let data = await response.json();
  return data;
};
export const rebuildAlgoliaCache = async (csrfToken) => {
  let response = await fetch(`${activeHost()}/api/o2o/rebuild/algolia`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  });
  let data = await response.json();
  return data;
};
export const rebuildNetlifyFrontend = async (csrfToken) => {
  let response = await fetch(
    `https://api.netlify.com/build_hooks/62d9d57260d1c6087a9387d3`,
    {
      method: "POST",
      // credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    }
  );
  let data = await response;
  return data;
};
