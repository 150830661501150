import React, { useState, useEffect, useContext } from "react";
import { StateContext } from "../Context/StateContext";
import { rebuildDrupalCache, rebuildAlgoliaCache, rebuildNetlifyFrontend } from "../Helpers/DrupalHelper";
import {
  ActionList,
  ButtonGroup,
  Button,
  Popover,
  Spinner as ShopifySpinner,
} from "@shopify/polaris";

const Caches = (props) => {
  // const { token, pageTitle } = props;
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState([]);
  const activator = (
    <Button onClick={() => setActive(!active)} disclosure>
      More actions
    </Button>
  );

  const Spinner = (props) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ShopifySpinner {...props} />
      </div>
    );
  };

  const { state, dispatch } = useContext(StateContext);

  const token = localStorage.getItem("token");


  const processRebuild = async (tok, typeArr) => {
    setLoading(typeArr);

    if(typeArr.includes("algolia") || typeArr.length > 1) {
      dispatch({
        type: "SUCCESSFUL_TOAST",
        payload: "This may take several minutes. Do not navigate away from this page.",
      });
    }

    if(typeArr.includes('drupal')) {
      await rebuildDrupalCache(tok)
        .then((res) => {
          dispatch({
            type: "SUCCESSFUL_TOAST",
            payload: typeArr.length > 1 ? "Successly rebuilt Drupal caches. Still processing your other requests..." : "Successly rebuilt Drupal caches.",
          });
        })
        .catch((err) => {
          dispatch({
            type: "SUCCESSFUL_TOAST",
            payload: typeArr.length > 1 ? "Drupal cache rebuilding failed. Still processing your other requests..." : "Drupal cache rebuilding failed. Wait a few minutes and try again.",
            // payload: "Drupal cache rebuilding failed. Wait a few minutes and try again.",
          });
        });
    }
    if(typeArr.includes('frontend')) {
      const netlify = await rebuildNetlifyFrontend(tok)
      if(netlify.ok) {
        dispatch({
          type: "SUCCESSFUL_TOAST",
          payload: typeArr.length > 2 ? "Successly triggered frontend rebuild. Still processing your other requests..." : "Successly triggered frontend rebuild.",
        });

      } else {
        dispatch({
          type: "SUCCESSFUL_TOAST",
          payload: typeArr.length > 2 ? "The frontend failed to rebuild. Still processing your other requests..." : "The frontend failed to rebuild. Wait a few minutes and try again.",
          // payload: "",
        });
      }
        // .then((res) => {
        //
        // })
        // .catch((err) => {
        //
        // });
    }
    if(typeArr.includes('algolia')) {
      await rebuildAlgoliaCache(tok)
        .then((res) => {
          dispatch({
            type: "SUCCESSFUL_TOAST",
            payload: "Successly rebuilt Algolia indexes.",
          });
        })
        .catch((err) => {
          dispatch({
            type: "SUCCESSFUL_TOAST",
            payload: "Algolia indexes rebuilding failed. Wait a few minutes and try again.",
          });
        });
    }

    setLoading([])

  };

  return (
    <>
      <p>Clear caches or rebuild the website manually here.</p>
      <br />
      <p> Purging all caches may be resource intensive.</p>
      <br />
      <br />

      <ButtonGroup>
        <Popover
          active={active}
          activator={activator}
          onClose={() => setActive(!active)}
        >
          <ActionList
            items={[
              {
                content: (
                  <>
                    {!loading.includes("drupal") && <>Rebuild Drupal Caches</>}
                    {loading.includes("drupal") && <Spinner size="small" />}
                  </>
                ),
                onAction: () => processRebuild(token, ["drupal"]),
              },
              {
                content: (
                  <>
                    {!loading.includes("frontend") && <>Rebuild Frontend</>}
                    {loading.includes("frontend") && <Spinner size="small" />}
                  </>
                ),
                onAction: () => processRebuild(token, ['frontend']),
              },
              {
                content: (
                  <>
                    {!loading.includes("algolia") && <>Rebuild Algolia Indexes</>}
                    {loading.includes("algolia") && <Spinner size="small" />}
                  </>
                ),
                onAction: () => processRebuild(token, ['algolia']),
              },
            ]}
          />
        </Popover>
        <Button destructive onClick={() => processRebuild(token, ["drupal", 'frontend', 'algolia'])}>
          {loading.length < 2 && <>Purge all</>}
          {loading.length > 1 && <Spinner size="small" />}
        </Button>
      </ButtonGroup>
    </>
  );
};

export default Caches;
