/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import React, { useEffect, useContext, useState } from "react";
import {
  getStockSingle,
  getMyListing,
  updateUserStock,
} from "../../Helpers/DrupalHelper";
import { Button } from "@shopify/polaris";
import QuantitySelect from "../Elements/QuantitySelect";
import Select from "react-select";
import CountUp from "react-countup";
import { Modal, TextContainer } from "@shopify/polaris";
import { Range, getTrackBackground } from "react-range";
import Accordion from "../Accordion/Accordion";
import { Table, Tr, Td } from "../Table/TableElements";
const customStyles = {
  container: (base) => ({
    ...base,
    fontSize: "1.4rem",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 334444444,
    fontSize: "1.4rem",
  }),
};
const TRow = ({ name, val, number, selectval, onChange }) => {
  const [show, setShow] = useState(number && selectval ? false : true);
  const [count, setCount] = useState(number ? number : 0);
  const [select, setSelect] = useState(
    selectval ? selectOptions.filter((v) => v.value === selectval)[0] : null
  );
  useEffect(() => {
    onChange({
      restriction_number: count,
      restriction_frequency: select?.value,
    });
  }, [count, select]);
  return (
    <Tr>
      <Td>
        <div className="flex flex-col">
          {name}
          <span className="text-med-10 uppercase">{val} units</span>
        </div>
      </Td>
      {show ? (
        <Td col>
          <div className="flex items-center">
            <input
              id={`${name}`}
              // defaultValue={option.value}
              type="checkbox"
              defaultChecked={true}
              onChange={() => setShow(!show)}
              className="h-4 w-4 border-[#EBEBEB] rounded text-[#222] focus:ring-1 focus:ring-offset-2 focus:ring-[#222]"
              // {...register(`${name}.${optionIdx}.value`)}
            />
            <label
              htmlFor={`${name}`}
              className="ml-3 text-reg-16"
              css={css`
                &&& {
                  color: #222;
                }
              `}
            >
              Unlimited
            </label>
          </div>
        </Td>
      ) : (
        <>
          <Td>
            <QuantitySelect
              decrement={() => setCount((v) => parseInt(v) - 1)}
              increment={() => setCount((v) => parseInt(v) + 1)}
              count={parseInt(count)}
              small
            />
            <div className="flex items-center mt-2">
              <input
                id={`${name}`}
                // defaultValue={option.value}
                type="checkbox"
                defaultChecked={false}
                onChange={() => setShow(!show)}
                className="h-3 w-3 border-[#EBEBEB] rounded text-[#222] focus:ring-1 focus:ring-offset-2 focus:ring-[#222]"
                // {...register(`${name}.${optionIdx}.value`)}
              />
              <label
                htmlFor={`${name}`}
                className="ml-3  text-reg-16"
                style={{ fontSize: "12px" }}
                css={css`
                  &&& {
                    color: #222;
                  }
                `}
              >
                Unlimited
              </label>
            </div>
          </Td>
          <Td>
            <Select
              name={name}
              isSearchable={true}
              onChange={(value, action) => {
                setSelect(value);
              }}
              isClearable
              styles={customStyles}
              className="!mb-0"
              options={selectOptions}
              label="Frequency"
              sm
              defaultValue={select}
            />
          </Td>
        </>
      )}
    </Tr>
  );
};
const selectOptions = [
  { value: "daily", label: "Per day" },
  { value: "weekly", label: "Per week" },
  { value: "biweekly", label: "Biweekly" },
  { value: "monthly", label: "Per month" },
  { value: "forever", label: "Forever" },
];

const AllocateModal = ({
  token,
  current,
  value,
  maxV,
  onSave,
  myUserGroups,
  id,
}) => {
  const [values, setValues] = useState([value]);
  const [repUnits, setTepUnits] = useState(maxV - value);
  const [max, setMAX] = useState(maxV);
  const unalocs = parseInt(current.stock.unaloc);
  const [unaloc, setUnaloc] = useState(unalocs);
  const [segments, setSegments] = useState([]);
  // const { toggleNotification } = useContext(NotificationContext);
  // const { myUserGroups } = useContext(DataContext).data;
  const [userGroupAlloc, setUserGroupAlloc] = useState(0);
  // console.log(myUserGroups)
  const countUpDuration = 0.5;
  useEffect(() => {
    let repUnits = maxV - value;

    let users = myUserGroups;
    let remainder = repUnits % users.length;
    // console.log(remainder)
    // console.log(repUnits)
    let perUserItem = (repUnits - remainder) / users.length;
    // console.log(perUserItem)

    setSegments(
      myUserGroups.map((v) => {
        return {
          roles: [v.id],
          aloc: perUserItem,
          restriction_number: null,
          restriction_frequency: null,
        };
      })
    );
    // setUserGroupAlloc(perUserItem)
  }, []);
  const makeAloc = () => {
    let remainder = unaloc % 2;
    let perItem = (unaloc - remainder) / 2;

    let retailerAloc = perItem + remainder;
    let val = values[0];
    setMAX(maxV + unalocs);

    setValues([retailerAloc + val]);
    let repUnits = maxV + unalocs - (retailerAloc + val);

    let users = myUserGroups;
    let remainderA = repUnits % users.length;
    // console.log(remainder)
    // console.log(repUnits)
    let perUserItem = (repUnits - remainderA) / users.length;
    setSegments(
      myUserGroups.map((v) => {
        return {
          roles: [v.id],
          aloc: perUserItem,
          restriction_number: null,
          restriction_frequency: null,
        };
      })
    );
    // setUserGroupAlloc(perUserItem)
  };
  // console.log(segments)
  const [body, setBody] = useState({
    ret: {
      total: values[0],
    },
    rep: {
      total: max - values[0],
      segments: segments,
    },
  });
  const onSaveClick = async () => {
    let repUnits = max - values[0];
    let users = myUserGroups;
    let remainder = repUnits % users.length;
    // let perUserItem = (repUnits - remainder) / users.length

    // let body = {
    //   ret: {
    //     total: values[0],
    //   },
    //   rep: {
    //     total: repUnits,
    //     segments: segments,
    //   },
    // }
    // if (remainder) {
    //   setUnaloc(unaloc + remainder)
    //   setMAX(max - (unaloc + remainder))
    // }
    let body = {
      ret: {
        total: values[0],
      },
      rep: {
        total: max - values[0],
        segments: segments,
      },
    };
    // console.log(body)
    let res = await updateUserStock(token, id, body);
    if (res.status === 200) {
      // toggleNotification({ content: `${current.id} Stock Updated` });
      onSave();
    } else {
      // toggleNotification({ content: "Stock Update fail", error: true });
    }
  };
  const increment = (k) => {
    if (values[0] > 0) {
      setValues([values[0] - 1]);

      // setUserGroupAlloc(userGroupAlloc + 1)

      let list = segments;
      // console.log(segments, k)
      list[k] = { ...list[k], aloc: list[k].aloc + 1 };
      //  setBody(c => ({ ...c, rep: { ...c.rep, segments: list } }))
      setSegments([...list]);
    }
  };
  const decrement = (k) => {
    let list = segments;

    if (list[k].aloc > 0) {
      // setUserGroupAlloc(userGroupAlloc - 1)
      setValues([values[0] + 1]);
      list[k] = { ...list[k], aloc: list[k].aloc - 1 };
      setSegments([...list]);
    }
  };

  const accordionPanels = [
    {
      title: "Manage Sales Rep allocations",
      body: (
        <>
          <p className="text-reg-15 mb-4">
            {max - values[0]} units are currently allocated to Sales Reps.
          </p>
          {myUserGroups && segments && (
            <Table columns={["User group", "Allocation"]} sm>
              {segments.map((item, k) => {
                const { title } = myUserGroups[k];
                // console.log(k)
                return (
                  <Tr key={k}>
                    <Td>
                      <div className="flex flex-col">
                        {title}
                        <span className="text-med-10 uppercase">
                          {segments.length} users
                        </span>
                      </div>
                    </Td>

                    <Td>
                      <QuantitySelect
                        decrement={() => decrement(k)}
                        increment={() => increment(k)}
                        // count={userGroupAlloc}
                        count={parseInt(item.aloc)}
                        small
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Table>
          )}
        </>
      ),
    },
    {
      title: "Manage order restrictions",
      body: (
        <>
          <p className="text-reg-15 mb-4">
            Restrict orders of this asset by quantity and frequency.
          </p>
          <Table
            columns={["User group", "Order quantity", "Order frequency"]}
            sm
            overflowVisible
          >
            <TRow
              name="Retailers"
              val={values[0]}
              number={current.stock.ret.restriction_number}
              selectval={current.stock.ret.restriction_frequency}
              onChange={(v) =>
                setBody((c) => ({ ...c, ret: { ...c.ret, ...v } }))
              }
            />
            <TRow
              name="Sales Reps"
              val={max - values[0]}
              number={current.stock.rep.restriction_number}
              selectval={current.stock.rep.restriction_frequency}
              onChange={(v) =>
                setBody((c) => ({ ...c, rep: { ...c.rep, ...v } }))
              }
            />

            {myUserGroups.map((item, k) => {
              const { title, users } = item;
              return (
                <TRow
                  name={title}
                  val={max - values[0]}
                  number={segments[k]?.restriction_number}
                  selectval={segments[k]?.restriction_frequency}
                  onChange={(v) => {
                    let list = segments;
                    list[k] = { ...list[k], ...v };
                    setBody((c) => ({
                      ...c,
                      rep: { ...c.rep, segments: list },
                    }));
                    setSegments([...list]);
                  }}
                />
              );
            })}
          </Table>
        </>
      ),
    },
  ];

  return (
    <>
      <p className="text-med-18">
        Move available stock between Retailers{" & "}Sales Reps.
      </p>
      <p className="text-reg-16 mt-4 flex justify-between">
        <span>{current?.title}</span>
        <span>SKU: 345453553535</span>
      </p>
      <div className="flex items-center  mt-6">
        <p className="text-med-14">
          Remaining stock:{" "}
          {max !== maxV ? (
            <CountUp
              duration={countUpDuration}
              end={max}
              startVal={maxV}
              onEnd={() => {
                // toggleNotification({
                //   content:
                //     unalocs +
                //     " units have been allocated evenly between Sales Reps and Retailers. Drag the slider to reallocate units.",
                // });
                setUnaloc(0);
              }}
            />
          ) : (
            max
          )}{" "}
          units
        </p>
        {unaloc > 0 && (
          <div className="flex items-center ml-auto">
            <p className="text-med-14">Unallocated units: {unaloc}</p>
            <a
              className="btn-as-link cursor-pointer hover:text-[#222] transition-all ml-4"
              onClick={makeAloc}
            >
              Allocate
            </a>
          </div>
        )}
      </div>

      <div className="flex justify-center my-6">
        <span
          className=" p-2 pr-6"
          css={css`
            font-family: "Jost";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.03em;
            /* font-family: "KansasNew";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.03em; */
            text-align: left;
            color: #fff;
            align-self: center;
            background-color: #e43d30;
            border-radius: 8px 0 0 8px;
            border: 1px solid #e43d30;
            height: 55px;
          `}
        >
          Retailers{" "}
          {max !== maxV ? (
            <CountUp
              duration={countUpDuration}
              startVal={values[0] - Math.floor(unaloc / 2)}
              end={values[0]}
            />
          ) : (
            values[0]
          )}{" "}
          units
        </span>
        <Range
          values={values}
          min={0}
          max={max}
          onChange={(values) => {
            setValues(values);
            let repUnits = max - values[0];

            let users = myUserGroups;
            let remainder = repUnits % users.length;
            let perUserItem = (repUnits - remainder) / users.length;
            setSegments(
              segments.map((v) => {
                return {
                  ...v,
                  aloc: perUserItem,
                };
              })
            );
          }}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "55px",
                // borderRadius: "4px",
                display: "flex",
                width: "100%",
                // border: " 1px solid #E43D30",
              }}
              css={css`

                    border: 1px solid #e43d30;
                    border-right-width:0;
                    font-family: "KansasNew";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: 0.03em;
                    color: #fff
                    transition: 0.2s ease all;
                    background-color: #e43d30

                  `}
            >
              <div
                ref={props.ref}
                style={{
                  height: "53px",
                  width: "100%",
                  // borderRadius: "8px",

                  background: getTrackBackground({
                    values: values,
                    colors: ["#E43D30", `#fff`],
                    min: 0,
                    max: max,
                  }),
                  alignSelf: "center",
                  display: "flex",
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                backgroundColor: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 2px 6px rgba(0,0,0,.1)",
                zIndex: 33333,
              }}
            >
              <div
                style={{
                  height: "10px",
                  borderRadius: "50%",
                  width: "10px",
                  backgroundColor: isDragged ? "#E43D30" : "#CCC",
                }}
              />
            </div>
          )}
        />
        <span
          className=" p-2 pl-6"
          css={css`
            /* font-family: "KansasNew";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.03em; */
            font-family: "Jost";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.03em;
            color: #e43d30;
            text-align: right;
            background-color: #fff;
            border-radius: 0 8px 8px 0;
            border: 1px solid #e43d30;

            border-left-width: 0;

            height: 55px;
          `}
        >
          Sales Reps{" "}
          {max !== maxV ? (
            <CountUp
              duration={countUpDuration}
              startVal={maxV - values[0]}
              end={max - values[0]}
            />
          ) : (
            max - values[0]
          )}{" "}
          units
        </span>
      </div>

      <div className="mb-6">
        <Accordion panels={accordionPanels} />
      </div>
      {value !== values[0] && (
        <Button red onClick={onSaveClick}>
          Save changes
        </Button>
      )}
    </>
  );
};

function AllocStock({ token, data }) {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(null);
  const [values, setValues] = useState([0]);
  const [userGroups, setUserGroups] = useState(null);
  const [max, setMAX] = useState(0);

  const id = data.id.current_value;

  useEffect(async () => {
    let datas = await getStockSingle(token, id);
    setItem(datas);
    let v = datas?.stock?.rep?.total ? parseInt(datas?.stock?.rep?.total) : 0;
    if (v) setValues([v]);

    // parseInt(current?.stock?.rep?.total),
    let valM =
      (datas?.stock?.rep?.total ? parseInt(datas?.stock?.rep?.total) : 0) +
      (datas?.stock?.ret?.total ? parseInt(datas?.stock?.ret?.total) : 0);
    // setMAX(valM);
    if (valM) setMAX(valM);

    getMyListing(token, "user_group").then((res) => {
      // console.log(res)
      console.log(res);

      setUserGroups(res);
    });
  }, []);

  return (
    <>
      <button
        class="Polaris-Button Polaris-Button--primary"
        type="button"
        onClick={() => setOpen(true)}
        style={{ marginRight: "60px" }}
      >
        <span class="Polaris-Button__Content">
          <span class="Polaris-Button__Text">Allocate Stock</span>
        </span>
      </button>
      {item && userGroups && (
        <Modal
          instant
          open={open}
          onClose={() => setOpen(false)}
          title={"Allocate Inventory"}
        >
          <Modal.Section>
            {max ? (
              <AllocateModal
                value={values[0]}
                maxV={max}
                current={item}
                token={token}
                onSave={async () => {
                  setOpen(false);

                  const data = await getStockSingle(token, id);
                  // console.log(data)
                  setItem(data);
                }}
                myUserGroups={userGroups}
                id={id}
              />
            ) : (
              "No inventory is currently available for this item."
            )}
          </Modal.Section>
        </Modal>
      )}
    </>
  );
}

export default AllocStock;
