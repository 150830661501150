/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react"
import React from "react"

function Tags(props) {
  const { wrap } = props

  if (wrap)
    return (
      <div
        className="flex flex-wrap"
        css={css`
          > * {
            margin-left: 0 !important;
            margin-right: 10px !important;
            margin-bottom: 10px !important;
          }
        `}
      >
        {props.children}
      </div>
    )
  return <div className="flex space-x-2">{props.children}</div>
}

export default Tags
