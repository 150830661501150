import React, { useState } from "react";
import { StateConsumer } from "../Context/StateContext";
import { Navigation } from "@shopify/polaris";
import { HomeMajor, OrdersMajor } from "@shopify/polaris-icons";

function Header({ routes }) {
  const [item, setItem] = useState([]);
  let nested;
  let items = [];
  if (routes) {
    nested = Object.entries(routes).map((field, i) => {
      let { bundles, title } = field[1];
      let name = field[0];
      switch (name) {
        case "product_builder_package":
          return null;
        default:
          return {
            url: `/add-new-${name}`,
            label: `Add  New ${title}`,
            icon: OrdersMajor,
            subNavigationItems: Object.entries(bundles).map((value, ii) => {
              return {
                url: `/add-new-${name}/${value[0]}`,
                label: `Add  New ${value[1]}`,
              };
            }),
          };
      }
    });
    let filtered = nested.filter(Boolean);
    items = [
      {
        url: "/",
        label: "Home",
        icon: HomeMajor,
      },
      {
        url: "/product-builder",
        label: "Product Builder",
        icon: OrdersMajor,
        badge: "15",
      },
      {
        url: "/product-builder-package",
        label: "Product Builder Package",
        icon: OrdersMajor,
      },
      {
        url: "/add-new-product-builder-package",
        label: "Add New Product Builder Package",
        icon: OrdersMajor,
      },
      {
        url: "/model3d",
        label: "Model3d",
        icon: OrdersMajor,
      },
      {
        url: "/installation-blurbs",
        label: "Installation Blurbs",
        icon: OrdersMajor,
      },
      ...filtered,
      {
        url: "/specsheets",
        label: "SpecSheets",
        icon: OrdersMajor,
      },
      {
        url: "/test",
        label: "test",
        icon: OrdersMajor,
        badge: "15",
      },
    ];
  }

  return (
    <StateConsumer>
      {({ token, myO2O }) => {
        return (
          <>
            <Navigation location={window.location.pathname}>
              {items.length ? <Navigation.Section items={items} /> : null}
            </Navigation>
            {/*  SESS TOKEN: {token}
            { myO2O.isAuthenticated === true &&
              <>
                <p>Email: {myO2O.email}</p>
                <p>Username: {myO2O.name}</p>
              </>
            } */}
          </>
        );
      }}
    </StateConsumer>
  );
}

export default Header;
