import React, { useEffect, useState } from "react";
import { Button } from "@shopify/polaris";
import { renderFields } from "../DynamicForm";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  loadEntitiesReferenceData,
  activeHost,
} from "../../../Helpers/DrupalHelper";
import { useForm } from "react-hook-form";
import ErrorMessage from "../ErrorMessage";

const NestedFields = ({
  id,
  name,
  formDatas,
  title,
  index,
  handleRemoveClick,
  dragNDropName,
  addClicked,
  location,
  token,
  list,
  exName,
  setList,
  entities,
}) => {
  const [formData, setFormdata] = useState(null);
  const [expand, setExpand] = useState(false);
  useEffect(() => setExpand(addClicked), []);
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    watch,
    getValues,
    unregister,
    control,
  } = useForm();
  const onChange = () => {};
  //   let data = getValues();
  //   console.log(data);
  //   // const tempArray = [...list];
  //   // const tempF = list[index];
  //   // const tmpObj = { ...tempF, data: data };
  //   // tempArray.splice(index, 1, tmpObj);
  //   // setList([...tempArray]);
  // };
  // console.log(expand);
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log(value);
      // console.log(list);
      const tempArray = [...list];
      const tempF = list[index];
      let tmpObj = { ...tempF, data: value };
      if (addClicked === false) {
        tmpObj = {
          ...tempF,
          data: value,
          type: formDatas.entities[0].machine_name,
          expand: true,
        };
      }
      tempArray.splice(index, 1, tmpObj);
      // console.log(tempArray);
      setList([...tempArray]);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  // useEffect(() => {
  //   if (formData !== null) {
  //     let data = getValues();
  //     const tempArray = [...list];
  //     const tempF = list[index];
  //     let tmpObj = {
  //       ...tempF,
  //       data: data,
  //     };
  //     if (expand && !addClicked) {
  //       tmpObj = {
  //         ...tempF,
  //         data: data,
  //         type: formDatas.entities[0].machine_name,
  //         expand: true,
  //       };
  //       console.log(expand, addClicked);
  //     }
  //     tempArray.splice(index, 1, tmpObj);
  //     setList([...tempArray]);
  //   }
  // }, [formData]);
  let newName = name;

  const handleRemove = (index) => {
    // handleUnregisterClick(index)
    handleRemoveClick(index)
  }
  const handleUnregisterClick = (index) => {

    console.log(formDatas)
    console.log("formDatas")
    // unregister(field)


    // console.log(formData)
    // console.log("formData")
    // Object.entries(formData).map((field) => {
    //
    //
    //   return unregister(`${field[0]}`);
    // });
  };
  // console.log(formData);
  // console.log(watch());

  useEffect(async () => {
    if (expand && !addClicked && formData === null) {
      const tempF = list[index];
      let f = entities.filter((v) => v.title === title);

      console.log(f)
      console.log("f")


      if (f.length) {
        let data = await loadEntitiesReferenceData(
          token,
          formDatas.entity_type.machine_name,
          f[0].machine_name,
          id
        );

        setFormdata(data);
      } else {
        let data = await loadEntitiesReferenceData(
          token,
          formDatas.entity_type.machine_name,
          formDatas.entities[0].machine_name,
          id
        );
        setFormdata(data);
      }

      // console.log(data);
    }
  }, [expand]);
  useEffect(() => {
    if (addClicked) {
      setFormdata(formDatas);
    }
  }, []);

  return (
    <form
      onChange={onChange}
      // onFocus={onChange}
    >
      <div style={{ margin: "0 ", padding: "14px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 style={{ fontSize: "15px" }}>
            {addClicked ? exName + " " + index : title + " " + id}
          </h4>
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button onClick={() => setExpand(!expand)} size="slim">
              {!expand ? "Expand" : "Collapse"}
            </Button>
            <div style={{ width: 4 }}></div>
            <Button
              onClick={() => handleRemove(index)}
              size="slim"
            >
              Remove This Field
            </Button>
          </div>
        </div>
        <div
          style={{
            clear: "both",
            display: expand ? "block" : "none",
            margin: expand ? "20px" : "0",
            borderTop: expand ? "1px solid rgba(0,0,0,.1)" : "",
            padding: expand ? "30px 0 0 0" : "",
          }}
        >
          {formData &&
            renderFields(
              formData,
              errors,
              register,
              setValue,
              watch,
              id,
              unregister,
              name,
              token,
              control,
              location
            )}
        </div>
      </div>{" "}
    </form>
  );
};

const GenerateNestedFields = ({
  setState,
  list,
  title,
  name,
  errors,
  register,
  setValue,
  watch,
  unregister,
  handleRemoveClick,
  dragNDropName,
  control,
  location,
  token,
  entities,
}) => {
  const grid = 8;
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "",
    padding: 0,
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: grid * 2,
    margin: `10px 10px`,
    // padding: '5p 0',
    // margin: 0,

    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "",

    // styles we need to apply on draggables
    ...draggableStyle,
  });
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(list, result.source.index, result.destination.index);

    setState(items);
  };

  return (
    <div
      style={{
        marginBottom: "8px",
        padding: "1px 0",
        background: "rgba(255,255,255,.5)",
        display: "grid",
      }}
    >
      {!list && <p>no fields added</p>}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {list &&
                list.map((value, i) => {
                  return (
                    <Draggable
                      key={value.id}
                      draggableId={value.id.toString()}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div
                            style={{
                              background: "var(--p-surface)",
                              borderRadius: "var(--p-border-radius-wide)",
                              // border: '1px solid rgba(0,0,0,.1)',
                              outline: "0.1rem solid transparent",
                              boxShadow: "var(--p-card-shadow)",
                            }}
                          >
                            <NestedFields
                              errors={errors}
                              register={register}
                              setValue={setValue}
                              list={list}
                              setList={setState}
                              watch={watch}
                              formDatas={value.formData}
                              addClicked={value.addClicked}
                              unregister={unregister}
                              name={name}
                              label={title}
                              title={value.title}
                              exName={value.name}
                              id={value.id}
                              index={i}
                              handleRemoveClick={handleRemoveClick}
                              dragNDropName={dragNDropName}
                              token={token}
                              control={control}
                              location={location}
                              entities={entities}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

const EntityParagraph = ({
  token,
  data,
  name,
  errors,
  register,
  setValue,
  watch,
  unregister,
  control,
  location,
}) => {
  const [expand, setExpand] = useState(false);
  const [entities, setEntities] = useState([]);
  const [List, setList] = useState([]);
  useEffect(() => {
    if (data.required) {
      register(name, {
        required: "This field is required.",
      });
    } else {
      register(name);
    }
    if (data.current_value) {
      let preList = data.current_value.map((v, k) => {
        return {
          id: v.target_id,
          title: v.title,
          formData: data,
          addClicked: false,
          data: v,
        };
      });
      setValue(`${name}`, data.current_value);

      setList([...preList]);
    }
  }, []);
  const setState = (i) => {
    setList(i);
  };
  const handleAddClick = (i, type) => {
    // console.log(data.entities[i]);
    const getData = async (entityName) => {
      return fetch(
        `${activeHost()}/api/o2o/entity_form/paragraph.${entityName}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": token,
          },
        }
      ).then((response) => response.json());
    };
    getData(data.entities[i].machine_name).then((datas) => {
      console.log(datas);
      let newData = {
        id: List.length + 1,
        formData: datas,
        addClicked: true,
        type: type,
        name: data.entities[i].title,
      };
      let list = [...List, newData];
      sessionStorage.setItem("inputList", JSON.stringify(list));
      setList([...List, newData]);
    });

    // inputList.push(length.toString());
  };
  const handleRemoveClick = (index) => {
    // console.log(index);
    const tempArray = [...List];
    console.log(List);
    tempArray.splice(index, 1);
    console.log(tempArray)
    setList(tempArray);

    const listdata = tempArray.map((v) => {
      if (v.addClicked === false) {
        for (let key in v.data) {
          if (v.data[key] === undefined) v.data[key] = null;
        }
        if (v.expand === true) {
          return {
            type: v.type,
            fields: v.data,
          };
        } else {
          return v.data;
        }
      }
      return {
        type: v.type,
        fields: v.data,
      };
    });
    // console.log(listdata);
    // console.log(watch());
    // setValue(`${name}`, listdata);
    setValue(name, listdata);

    // unregister(field[index])

    if (!tempArray.length) {
    }


  };

  useEffect(() => {
    const newEntities = [...data.entities];
    newEntities.shift();
    setEntities(newEntities);
  }, []);
  const updateValue = (list) => {
    setList([...list]);
    if (list.length) {
      const listdata = list.map((v) => {
        if (v.addClicked === false) {
          for (let key in v.data) {
            if (v.data[key] === undefined) v.data[key] = null;
          }
          if (v.expand === true) {
            return {
              type: v.type,
              fields: v.data,
            };
          } else {
            return v.data;
          }
        }
        return {
          type: v.type,
          fields: v.data,
        };
      });
      // console.log(listdata);
      // console.log(watch());
      setValue(`${name}`, listdata);
    }
  };
  // console.log(name);
  return (
    <>
      <div
        className="Polaris-Labelled__LabelWrapper"
        style={{
          background: "rgba(0,0,0,.03)",
          padding: "10px",
          border: "1px solid rgba(0,0,0,.05)",
        }}
      >
        <div className="Polaris-Label">
          <label id="PolarisTextField2Label" className="Polaris-Label__Text">
            {data.label}
          </label>
        </div>
      </div>
      <GenerateNestedFields
        list={List}
        name={name}
        errors={errors}
        register={register}
        setValue={setValue}
        watch={watch}
        unregister={unregister}
        handleRemoveClick={handleRemoveClick}
        setState={updateValue}
        dragNDropName={data.entities[0].title}
        control={control}
        location={location}
        token={token}
        entities={entities}
        // type={data.type}
      />
      <div style={{ marginBottom: 10 }}>
        <div
          className="Polaris-Button__ConnectedDisclosureWrapper"
          // style={{ marginBottom: "10px" }}
        >
          <div style={{ display: "inline-grid" }}>
            <button
              onClick={() => handleAddClick(0, data.entities[0].machine_name)}
              className={`Polaris-Button Polaris-Button--primary ${
                entities.length > 1 ? "Polaris-Button--connectedDisclosure" : ""
              }`}
              type="button"
              style={{
                borderRadius: !expand
                  ? "3px"
                  : entities.length > 1
                  ? "3px 0 0 0"
                  : "3px 0 0 3px",
              }}
            >
              <span className="Polaris-Button__Content">
                <span className="Polaris-Button__Text">
                  Add {data.entities[0].title}
                </span>
              </span>
            </button>
            {expand ? (
              <>
                {entities &&
                  entities.map((value, i) => {
                    return (
                      <button
                        onClick={() =>
                          handleAddClick(i + 1, value.machine_name)
                        }
                        key={i}
                        className="Polaris-Button Polaris-Button--primary Polaris-Button--connectedDisclosure"
                        type="button"
                        style={{
                          borderRadius:
                            i === entities.length - 1 ? "0 0 0 3px" : "0",
                        }}
                      >
                        <span className="Polaris-Button__Content">
                          <span className="Polaris-Button__Text">
                            Add {value.title}
                          </span>
                        </span>
                      </button>
                    );
                  })}
              </>
            ) : null}
          </div>

          {entities.length > 1 && (
            <button
              onClick={() => setExpand(!expand)}
              type="button"
              className="Polaris-Button Polaris-Button--primary Polaris-Button--iconOnly Polaris-Button__ConnectedDisclosure"
              aria-label="Other save actions"
              tabindex="0"
              aria-controls="Polarispopover2"
              aria-owns="Polarispopover2"
              aria-expanded="false"
              style={{ height: "auto" }}
            >
              <span className="Polaris-Button__Icon">
                <span className="Polaris-Icon">
                  <svg
                    viewBox="0 0 20 20"
                    className="Polaris-Icon__Svg"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <path d="M5 8l5 5 5-5H5z"></path>
                  </svg>
                </span>
              </span>
            </button>
          )}
        </div>
      </div>
      {errors && errors[name] && (
        <ErrorMessage message={errors[name]["message"]} />
      )}
      <div id="PolarisPortalsContainer">
        <div data-portal-id="popover-Polarisportal1"></div>
      </div>
    </>
  );
};

export default EntityParagraph;
