import React from "react";
import ErrorMessage from "../ErrorMessage";
const Boolean = ({ data, errors, name, register }) => {
  let { label, default_value, required, description } = data;

  if (data.current_value) {
    default_value = parseInt(data.current_value);
  }
  if (default_value === "" || default_value === null) {
    default_value = 0;
  }
  return (
    <div key={name}>
      <label className="Polaris-Choice" htmlFor={name}>
        <span
          className="Polaris-Choice__Control"
          style={{
            top: "-2px",
            position: "relative",
            // border: errors[name] && "1px red solid",
            borderRadius: "2px",
          }}
        >
          <span className="Polaris-Checkbox">
            {required ? (
              <input
                type="checkbox"
                name={name}
                className="Polaris-Checkbox__Input"
                id={name}
                // ref={register({ required: true })}
                defaultChecked={default_value}
                {...register(name, {
                  required: "This is required.",
                })}
              />
            ) : (
              <input
                type="checkbox"
                name={name}
                id={name}
                className="Polaris-Checkbox__Input"
                {...register(name)}
                defaultChecked={default_value}
              />
            )}{" "}
            <span className="Polaris-Checkbox__Backdrop"></span>
            <span className="Polaris-Checkbox__Icon">
              <span className="Polaris-Icon">
                <svg
                  viewBox="0 0 20 20"
                  className="Polaris-Icon__Svg"
                  focusable="false"
                  aria-hidden="true"
                >
                  <path d="M8.315 13.859l-3.182-3.417a.506.506 0 0 1 0-.684l.643-.683a.437.437 0 0 1 .642 0l2.22 2.393 4.942-5.327a.436.436 0 0 1 .643 0l.643.684a.504.504 0 0 1 0 .683l-5.91 6.35a.437.437 0 0 1-.642 0"></path>
                </svg>
              </span>
            </span>
          </span>
        </span>
        <span className="Polaris-Choice__Label">
          {label}
          {data.required ? "*" : ""}
        </span>
      </label>
      {errors && errors[name] && (
        <ErrorMessage message={errors[name]["message"]} />
      )}

      <div
        className="Polaris-Labelled__HelpText"
        id="PolarisTextField2HelpText"
      >
        <span dangerouslySetInnerHTML={{ __html: description }}></span>
      </div>
      <div id="PolarisPortalsContainer"></div>
    </div>
  );
};

export default Boolean;
