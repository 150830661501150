import React from "react";
import SubMenu from "./SubMenu";
import { HomeMajor, OrdersMajor } from "@shopify/polaris-icons";
import { SkeletonBodyText } from "@shopify/polaris";
const Sidebar = ({ routes }) => {
  let nestedAddnew;
  let nested;

  let items = [];
  if (routes) {
    nestedAddnew = Object.entries(routes).map((field, i) => {
      let { bundles, title } = field[1];
      let name = field[0];
      return {
        to: `/add-new-${name}`,
        label: `Add  New ${title}`,
        icon: OrdersMajor,
        content: Object.entries(bundles).map((value, ii) => {
          return {
            to: `/add-new-${name}/${value[0]}`,
            label: `${value[1]}`,
            icon: OrdersMajor,
          };
        }),
      };
    });
    nested = Object.entries(routes).map((field, i) => {
      let { bundles, title } = field[1];
      let name = field[0];
      if (name === "media") return false;
      if (name === "status") return false;
      if (name === "order")
        return {
          to: `/${name}`,
          name: name,
          label: `${title}`,
          icon: OrdersMajor,
        };

      return {
        to: `/${name}`,
        name: name,
        label: `${title}`,
        icon: OrdersMajor,
        content: Object.entries(bundles).map((value, ii) => {
          return {
            to: `/add-new-${name}/${value[0]}`,
            label: `${value[1]}`,
            icon: OrdersMajor,
          };
        }),
      };
    });
    let filtered = nestedAddnew.filter(Boolean);
    items = [
      {
        to: "/",
        label: "Dashboard",
        icon: HomeMajor,
      },
      // {
      //   to: '/manage-front-search',
      //   label: 'Update Front Search Records',
      //   icon: HomeMajor,
      // },
      ...nested.filter(Boolean),
      // ...filtered
    ];
  }
  return (
    <>
      <nav class="Polaris-Navigation">
        <div class="Polaris-Navigation__PrimaryNavigation Polaris-Scrollable Polaris-Scrollable--vertical">
          <ul class="Polaris-Navigation__Section">
            {items.length ? (
              <>
                {items.map((item, index) => {
                  return <SubMenu item={item} key={index} />;
                })}
              </>
            ) : (
              <SkeletonBodyText lines={7} />
            )}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
