import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import "@shopify/polaris/build/esm/styles.css";
// import '@shopify/polaris/dist/styles.css';
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import { StateConsumer, StateContext } from "./Context/StateContext";
import Header from "./Components/Header";
import Body from "./Components/Body";
import Footer from "./Components/Footer";
import {
  AppProvider,
  Frame,
  Card,
  TextContainer,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Toast,
  Breadcrumbs,
} from "@shopify/polaris";
import TopBarComponent from "./Components/TopBar";
import LoginComponent from "./Components/Login";
import { allEntities } from "./Helpers/DrupalHelper";
import RouteConfig from "./Routes/Routes";

import Sidebar from "./Components/Sidebar/Sidebar";
import BreadCrumb from "./Components/Elements/BreadCrumb";

function App() {
  const [route, setRoute] = useState(null);
  const { state, dispatch } = useContext(StateContext);
  const { token, isAuthenticated, user } = state;
  const [loading, setloading] = useState(true);

  const theme = {
    // colors: {
    //   surface: '#111213',
    //   onSurface: '#111213',
    //   interactive: '#2e72d2',
    //   secondary: '#111213',
    //   primary: '#41444e',
    //   critical: '#d82c0d',
    //   warning: '#ffc453',
    //   highlight: '#5bcdda',
    //   success: '#008060',
    //   decorative: '#ffc96b',
    // },
    logo: {
      width: 45,
      innerHeight: 80,
      topBarSource: "/gatsby-icon.png",
      url: "/",
      accessibilityLabel: "O2O Dash",
    },
  };

  const fetchEntities = async () => {
    const token = localStorage.getItem("token");

    await allEntities(token).then(async (data) => {
      dispatch({
        type: "ENTITIES",
        payload: data,
      });
      setRoute(data);
      setloading(false);
    });
  };
  useEffect(() => {
    if (isAuthenticated) fetchEntities();
    else setloading(false);
  }, [isAuthenticated]);
  const toggleSuccessActive = () => {
    dispatch({
      type: "SUCCESSFUL_TOAST",
      payload: false,
    });
  };
  // setIsAuthenticatedd(isAuthenticated);
  return (
    <Router>
      <div className="App">
        <AppProvider
          theme={theme}
          i18n={{
            Polaris: {
              Avatar: {
                label: "Avatar",
                labelWithInitials: "Avatar with initials {initials}",
              },
              Frame: { skipToContent: "Skip to content" },
              TopBar: {
                toggleMenuLabel: "Toggle menu",
                SearchField: {
                  clearButtonLabel: "Clear",
                  search: "Search",
                },
              },
            },
          }}
        >
          {state.isLoading || loading ? (
            <div className="loading">
              <div className="loader"></div>
            </div>
          ) : (
            <>
              {!isAuthenticated ? (
                <LoginComponent />
              ) : !user ? (
                <SkeletonPage
                  primaryAction
                  secondaryActions={2}
                  fullWidth={true}
                >
                  <Layout>
                    <Layout.Section secondary>
                      <Card subdued>
                        <Card.Section>
                          <TextContainer>
                            <SkeletonDisplayText size="small" />
                            <SkeletonBodyText lines={7} />
                          </TextContainer>
                        </Card.Section>
                      </Card>
                    </Layout.Section>
                    <Layout.Section>
                      <Card>
                        <Card.Section>
                          <TextContainer>
                            <SkeletonDisplayText size="small" />
                            <SkeletonBodyText lines={2} />
                          </TextContainer>
                        </Card.Section>
                        <Card.Section>
                          <SkeletonBodyText lines={2} />
                        </Card.Section>
                      </Card>
                    </Layout.Section>
                  </Layout>
                </SkeletonPage>
              ) : (
                <Frame
                  // topBar={topBarMarkup}
                  navigation={<Sidebar routes={route} />}
                  topBar={<TopBarComponent entities={route} />}
                  // showMobileNavigation={mobileNavigationActive}
                  // onNavigationDismiss={toggleMobileNavigationActive}
                  // skipToContentTarget={skipToContentRef.current}
                >
                  <Body fullwidth={true}>
                    <RouteConfig
                      token={token}
                      entityroutes={route}
                      user={user}
                    />
                    {state.successMessage ? (
                      <Toast
                        content={state.successMessage}
                        onDismiss={toggleSuccessActive}
                      />
                    ) : null}
                  </Body>
                  <Footer />
                </Frame>
              )}
            </>
          )}
        </AppProvider>
      </div>
    </Router>
  );
}

export default App;
