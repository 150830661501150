import { Warning } from "@mui/icons-material";
import React from "react";

const ErrorMessage = ({ message }) => {
  return (
    <p style={{ color: "red" }}>
      <Warning />
      {message}
    </p>
  );
};

export default ErrorMessage;
