import React, { useState, useEffect } from "react";
import Select from "react-select";
import ErrorMessage from "../ErrorMessage";

function ListInteger({ data, errors, name, register, label, setValue, watch }) {
  const [selectValue, setSelectValue] = useState(null);
  const options =
    data.allowed_values &&
    data.allowed_values.map((item) => {
      return { value: item.key, label: item.title };
    });
  const handleChange = (v) => {
    // const inputRef = action.name;
    setSelectValue(v);
    setValue(`${name}`, v.value);

    // if(childSelect && )
  };
  const customStyles = {
    container: (base) => ({
      ...base,
      fontSize: "1.4rem",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 334444444,
      fontSize: "1.4rem",
    }),
  };
  useEffect(() => {
    if (data.required) {
      register(name, { required: `This field is required ` });
    } else {
      register(name);
    }
    if (data.current_value) {
      setValue(name, data.current_value);
      if (Array.isArray(data.current_value)) {
        let val = data.current_value.map((v) => {
          let d = data.allowed_values.filter(
            (c) => c.hasOwnProperty("key") && c.key === v
          )[0];
          if (d) {
            return {
              label: d.title,
              value: v,
            };
          } else {
            return {
              label: v,
              value: v,
            };
          }
        });
        setSelectValue(val);
      }
      if (typeof data.current_value === "string") {
        let d = data.allowed_values.filter(
          (c) =>
            c.hasOwnProperty("key") && c.key === parseInt(data.current_value)
        )[0];
        if (d) {
          setSelectValue({
            value: d.key,
            label: d.title,
          });
        } else {
          setSelectValue({
            value: data.current_value,
            label: data.current_value,
          });
        }
      }
    }
    // register({ name: name });
  }, []);
  return (
    <div key={name}>
      <div className="Polaris-Labelled__LabelWrapper">
        <div className="Polaris-Label">
          <label className="Polaris-Label__Text">
            {label}
            {data.required ? "*" : ""}
          </label>
        </div>
      </div>
      <div className="Polaris-Select"></div>
      {data.value_limit ? (
        <Select
          name={name}
          options={options}
          isSearchable={true}
          onChange={handleChange}
          // isClearable
          styles={customStyles}
          value={selectValue}
          // defaultValue={data.current_value}
        />
      ) : (
        <Select
          name={name}
          options={options}
          isSearchable={true}
          onChange={handleChange}
          styles={customStyles}
          isClearable
          isMulti
          value={selectValue}
          // defaultValue={data.current_value}
        />
      )}
      {errors && errors[name] && (
        <ErrorMessage message={errors[name]["message"]} />
      )}
    </div>
  );
}

export default ListInteger;
