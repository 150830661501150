import React, { Component } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
// import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
class BodyEditor extends Component {
  state = {
    editorState: EditorState.createEmpty(),
  };

  onEditorStateChange = (editorState) => {
    this.props.onChange(editorState);
    this.setState({
      editorState,
    });
  };
  componentDidMount = () => {
    if (this.props?.data?.current_value) {
      let editorState = stateFromHTML(this.props?.data?.current_value);
      this.setState({
        editorState: EditorState.createWithContent(editorState),
      });
      this.props.onChange(EditorState.createWithContent(editorState));
    }
  };

  render() {
    const { editorState } = this.state;
    return (
      <div
        style={{
          backgroundColor: "#fff",
        }}
      >
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName p-5"
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            options: [
              "inline",
              "fontSize",
              // "blockType",
              "list",
              "textAlign",
              "colorPicker",
              "link",

              "image",

              "history",
            ],
            inline: {
              options: [
                "bold",
                "italic",
                "underline",
                "strikethrough",
                "superscript",
                "subscript",
              ],
            },
            // textAlign: { inDropdown: true },
            // link: { inDropdown: true },
            // history: { inDropdown: true },
          }}
        />
      </div>
    );
  }
}
export default BodyEditor;
