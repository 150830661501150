import React, { useContext, useState, useEffect, useRef } from "react";
// import { selectStyles } from "../../../../styled/Select";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { components } from "react-select";
import CustomLocationForm from "../CustomLocationForm";
import { getLocationFromId } from "../../../Helpers/DrupalHelper";
function GeoLocations({
  onChange,
  label,
  error,
  name,
  hookForm,
  register,
  defaultValue,
  data,
  currentValue,
  token,
  setValue,
}) {
  const selectStyles = {
    container: (base) => ({
      ...base,
      fontSize: "1.4rem",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 334444444,
      fontSize: "1.4rem",
    }),
  };
  const [formData, setFormData] = useState(null);
  const [customActive, setCustomActive] = useState(false);
  const [selectInput, setSelectInput] = useState(false);
  const pRef = useRef(null);
  const IndicatorsContainer = ({ children, ...props }) => {
    useEffect(() => {
      if (props.selectProps.inputValue) setSelectInput(true);
    }, [props.selectProps.inputValue]);
    return (
      <components.IndicatorsContainer {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {selectInput && (
            <button
              class="Polaris-Button Polaris-Button--primary"
              type="button"
              onClick={() => setCustomActive(true)}
            >
              <span class="Polaris-Button__Content">
                <span class="Polaris-Button__Text">
                  Can't find your location?
                </span>
              </span>
            </button>
          )}{" "}
          {children}
        </div>
      </components.IndicatorsContainer>
    );
  };
  const [placeValue, setPlaceValue] = useState(null);
  const [disable, setDisable] = useState(currentValue ? true : false);
  const onSave = (data) => {
    let values = Object.values(data).filter(Boolean);
    let strAdd = values.toLocaleString().replaceAll(",", ", ");
    // console.log(values, strAdd)
    setValue("customBodyLocation", {
      name: name,
      body: {
        entity_title: strAdd,
        json: {
          field_location_address: { ...data, title: strAdd },
          field_location_shortname: strAdd,
        },
      },
    });
  };
  useEffect(() => {
    register(name);
    register("customBodyLocation");
    if (currentValue) setPlaceValue(JSON.parse(currentValue));
  }, []);
  useEffect(async () => {
    let token = "";
    if (pRef?.current !== null) {
      let { getSessionToken } = pRef.current;
      token = getSessionToken();
    }
    if (placeValue !== null) {
      let chekExtist = await getLocationFromId(
        token,
        placeValue.value.place_id
      );
      if (Array.isArray(chekExtist) && !chekExtist.length) {
        let service = new window.google.maps.places.PlacesService(
          new window.google.maps.Map(document.getElementById("2"), {
            zoom: 15,
          })
        );

        service.getDetails(
          {
            placeId: placeValue.value.place_id,
            // sessionToken: token,
          },
          (a, b) => {
            console.log(b, a);

            if (b === "OK") {
              let field_location_address = {
                city: "",
                country_code: "",
                postal_code: "",
                address_line1: "",
                address_line2: "",

                state: "",
              };
              a.address_components.map((v) => {
                if (v.types.includes("administrative_area_level_1")) {
                  field_location_address.state = v.short_name;
                } else if (v.types.includes("administrative_area_level_2")) {
                  field_location_address.city = v.long_name;
                } else if (v.types.includes("country")) {
                  field_location_address.country_code = v.short_name;
                } else if (v.types.includes("postal_code")) {
                  field_location_address.postal_code = v.long_name;
                } else if (v.types.includes("street_number")) {
                  field_location_address.address_line1 =
                    field_location_address.address_line1
                      ? field_location_address.address_line1 + v.long_name
                      : v.long_name;
                  // field_location_address.address_line2 =
                  //   field_location_address.address_line2 + v.long_name
                } else if (v.types.includes("route")) {
                  field_location_address.address_line1 =
                    field_location_address.address_line1
                      ? field_location_address.address_line1 +
                        ", " +
                        v.long_name
                      : v.long_name;
                  // field_location_address.address_line2 =
                  //   field_location_address.address_line2 + v.long_name
                } else if (v.types.includes("locality")) {
                  field_location_address.address_line1 =
                    field_location_address.address_line1
                      ? field_location_address.address_line1 +
                        ", " +
                        v.long_name
                      : v.long_name;
                  // field_location_address.address_line2 =
                  //   field_location_address.address_line2 + v.long_name
                }
                return v;
              });
              console.log(field_location_address);
              setValue("customBodyLocation", {
                name: name,
                body: {
                  entity_title: placeValue.label,
                  json: {
                    title: placeValue.label,
                    field_location_shortname: placeValue.label,
                    field_active: "1",
                    field_location_address: field_location_address,
                    field_location_id: placeValue.value.place_id,
                    field_location_geocode: JSON.stringify(placeValue),

                    field_location_lat: a.geometry.location.lat(),
                    field_location_lng: a.geometry.location.lng(),
                  },
                },
              });
            }
          }
        );
        // setValue("customBodyLocation", {
        //   name: name,
        //   body: {
        //     entity_title: placeValue.label,
        //     json: {
        //       title: placeValue.label,
        //       field_location_shortname: placeValue.label,
        //       field_active: "1",
        //       field_location_address: {
        //         city: "HaNoi",
        //         country_code: "US",
        //         postal_code: "55555",
        //         address_line1: "ACBDDD",
        //         address_line2: "SDSDFSDF",
        //         // company: "ACB",
        //         state: "NY",
        //       },
        //       field_location_id: placeValue.value.place_id,
        //       field_location_geocode: JSON.stringify(placeValue),
        //     },
        //   },
        // })
        setValue(name, []);
      } else {
        setValue(name, Object.values(chekExtist)[0].id);
      }
    } else {
    }
  }, [placeValue]);
  return (
    <>
      <div id="2" style={{ display: "none" }}></div>

      {!customActive && (
        <>
          <div className="Polaris-Labelled__LabelWrapper">
            <div className="Polaris-Label">
              <label className="Polaris-Label__Text">
                {label}
                {data.required ? "*" : ""}
              </label>
            </div>
          </div>
          <div className="Polaris-Select"></div>
          <GooglePlacesAutocomplete
            apiKey={"AIzaSyChAz7o0_Zbib8mfnY-pRTeK9WYDjdZfFk"}
            apiOptions={{ language: "en", region: "us" }}
            ref={pRef}
            autocompletionRequest={{
              // bounds: [
              //   { lat: 50, lng: 50 },
              //   { lat: 100, lng: 100 },
              // ],
              componentRestrictions: {
                country: ["us", "ca"],
              },
              types: ["geocode"],
            }}
            selectProps={{
              // defaultInputValue: location,
              value: placeValue,
              // value: 'San',
              // isLoading: true,
              onChange: setPlaceValue,
              // menuIsOpen: true,
              isDisabled: disable,
              placeholderFontSize: "40px",
              placeholderPosition: "relative",
              placeholderPadding: "0 0 0 0!important",
              placeholder:
                data.current_value && data?.current_value[0]?.title
                  ? data?.current_value[0]?.title
                  : "Type your address...",
              valueContainerStyles: {
                maxWidth: "360px",
                marginRight: "40px",
                overflow: "hidden",
                padding: "0 0",
                marginBottom: disable ? "4px" : "0",
              },
              styles: selectStyles,
              components: { IndicatorsContainer },
              noOptionsMessage: (e) =>
                e.inputValue === "" ? "Start typing..." : "Nothing found",
            }}
          />
          {currentValue && disable && (
            <button
              class="Polaris-Button Polaris-Button--primary"
              type="button"
              onClick={() => setDisable(false)}
            >
              <span class="Polaris-Button__Content">
                <span class="Polaris-Button__Text">Change Address</span>
              </span>
            </button>
          )}
          {error && <p>{error["message"]}</p>}
        </>
      )}

      <div
        className="Polaris-Labelled__HelpText"
        id="PolarisTextField2HelpText"
      >
        <span dangerouslySetInnerHTML={{ __html: data.description }}></span>
      </div>

      {customActive && <CustomLocationForm onSave={onSave} />}
    </>
  );
}

export default GeoLocations;
