import { StateConsumer } from "../Context/StateContext";

function Footer() {
  return (
    <StateConsumer>
      {({ token, myO2O }) => {
        return (
          <footer className="App-footer">
            <img src="https://api.netlify.com/api/v1/badges/5b952736-a3cf-4e12-b606-9ab3c06f8f41/deploy-status" />
            <br/>© 2022 O2O

          </footer>
        );
      }}
    </StateConsumer>
  );
}

export default Footer;
