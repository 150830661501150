import React, { useState, useRef, useEffect } from "react";
import { ChromePicker } from "react-color";
import { Button } from "@shopify/polaris";
import ErrorMessage from "../ErrorMessage";

function ColorPicker({ data, errors, name, register, setValue }) {
  let { label, default_value, description, required } = data;
  const [color, setColor] = useState();
  const [showColorPicker, setShowColorPicker] = useState(false);
  const handleChange = (color) => {
    setValue(name, color.hex);
    setColor(color.hex);
  };
  useEffect(() => {
    if (data.required) {
      register(name, {
        required: "This Field is required.",
      });
    } else {
      register(name);
    }
    if (data.current_value) {
      setValue(name, data.current_value);
      setColor(data.current_value);
    }
    // register({ name: name });
  }, []);
  const overlayRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        if (showColorPicker) {
          setShowColorPicker(!showColorPicker);
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  const popover = {
    position: "absolute",
    zIndex: "2222",
    left: 120,
    top: 25,
  };
  return (
    <>
      <div style={{ position: "relative" }}>
        <div ref={overlayRef}>
          <div className="Polaris-Labelled__LabelWrapper">
            <div className="Polaris-Label">
              <label
                id="PolarisTextField2Label"
                htmlFor={name}
                className="Polaris-Label__Text"
              >
                {label}
                {data.required ? "*" : ""}
              </label>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <Button onClick={() => setShowColorPicker(!showColorPicker)}>
              {showColorPicker ? "Close" : "Edit"} Color
            </Button>
            {!showColorPicker && color && (
              <div
                style={{
                  position: "relative",
                  width: "40px",
                  height: "40px",
                  marginLeft: "20px",
                  backgroundColor: color,
                }}
              ></div>
            )}
          </div>
          {showColorPicker ? (
            <div style={popover}>
              <ChromePicker color={color} onChangeComplete={handleChange} />
            </div>
          ) : null}
          <div
            className="Polaris-Labelled__HelpText"
            id="PolarisTextField2HelpText"
          >
            <span dangerouslySetInnerHTML={{ __html: description }}></span>
          </div>
        </div>
        <div id="PolarisPortalsContainer"></div>
        {errors && errors[name] && (
          <ErrorMessage message={errors[name]["message"]} />
        )}
      </div>{" "}
    </>
  );
}

export default ColorPicker;
