import React from "react"
import {
  Accordion as Acc,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"

const Accordion = props => {
  const { panels } = props
  return (
    <Acc allowZeroExpanded={true} className="o2o__accordion">
      {panels &&
        panels.map((item, k) => {
          const { title, body } = item
          return (
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>{title}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>{body}</AccordionItemPanel>
            </AccordionItem>
          )
        })}
    </Acc>
  )
}

export default Accordion
