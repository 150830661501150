/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import React from "react";
import LazyImage from "../LazyImage";
import Skeleton from "../Skeleton";
function ImageCardSmall(props) {
  return (
    <div
      className="flex items-center justify-center"
      css={css`
        background: #ffffff;
        border: 1px solid #ebebeb;
        box-sizing: border-box;
        border-radius: 8px;
        overflow: hidden;
        min-width: ${props.size ? props.size + `px` : `80px`};
        width: ${props.size ? props.size + `px` : `80px`};
        height: ${props.size ? props.size + `px` : `80px`};
      `}
    >
      {props.image &&
      (props.image.includes(".png") ||
        props.image.includes(".jpg") ||
        props.image.includes(".jpeg")) ? (
        <LazyImage
          src={props.image}
          alt={props.alt || ""}
          width={"100%"}
          height={"100%"}
        />
      ) : (
        <Skeleton dark />
      )}
    </div>
  );
}

export default ImageCardSmall;
