export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export const toCapitalCase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const timestampToDate = (timestamp) => {
  const date = new Date(timestamp * 1000)
  var formattedDate = ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  return formattedDate;
}