import React from 'react'
import { StateProvider } from '../Context/StateContext'


export const Global = ({ children }) => {
  return (
      <StateProvider>
        <LayoutComponents children={children} />
      </StateProvider>
  );
};

const LayoutComponents = ({ children }) => {
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};

export default Global;
