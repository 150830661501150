import React, { useState, useCallback } from "react";

import { Button, Popover, ActionList } from "@shopify/polaris";

export default function ViewInShopify({ data }) {
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const activator = (
    <Button onClick={toggleActive} disclosure>
      View In Shopify
    </Button>
  );

  return (
    <>
      <Popover
        active={active}
        activator={activator}
        autofocusTarget="first-node"
        onClose={toggleActive}
      >
        <ActionList
          actionRole="menuitem"
          items={[
            {
              content: <a href={`example.com/${"id"}`}>View Product</a>,
              onAction: () => {},
            },
            {
              content: <a href={`example.com/${"id"}`}>View Variant</a>,
              onAction: () => {},
            },
            {
              content: <a href={`example.com/${"id"}`}>View Inventory </a>,
              onAction: () => {},
            },
          ]}
        />
      </Popover>
    </>
  );
}
