import React, { useState, useEffect, useRef, Component } from "react";
import { EntitySuggestions } from "../../../Helpers/DrupalHelper";
import Select from "react-select";
import { FixedSizeList } from "react-window";
import ErrorMessage from "../ErrorMessage";

import { InView } from "react-intersection-observer";
const height = 35;

const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;

  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <FixedSizeList
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </FixedSizeList>
  );
};
function WebForm({ data, errors, name, register, label, setValue, watch }) {
  const [entitySuggestion, setEntitySuggestion] = useState(null);
  const [select1, setSelect1] = useState(null);
  const [defaultValue, setDefaultValue] = useState([]);

  const [inView, setInView] = useState(false);
  const handleChange = (
    value,
    action,
    setStateFunction,
    childSelect = null
  ) => {
    const inputRef = action.name;
    const currentValue = watch(inputRef);
    if (value !== null) {
      const newValue = value.value;
      if (childSelect && newValue !== currentValue) {
        setValue(childSelect, null);
      }
      setValue(inputRef, value);
      setStateFunction(value);
    } else {
      setValue(inputRef, null);
      setStateFunction(null);
    }

    // if(childSelect && )
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const EntitySuggestion = async () => {
      await EntitySuggestions(token, "node", ["webform"]).then(async (data) => {
        let ss = data.map((item) => {
          return { value: item.target_id, label: item.title };
        });
        setEntitySuggestion([...ss]);
      });
    };
    EntitySuggestion();
    if (data.required) {
      register(name, { required: `This field is required ${name}` });
    } else {
      register(name);
    }
  }, []);

  const customStyles = {
    container: (base) => ({
      ...base,
      fontSize: "1.4rem",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 334444444,
      fontSize: "1.4rem",
    }),
  };
  return (
    <>
      {entitySuggestion !== null && entitySuggestion.length > 80 ? (
        <InView onChange={setInView}>
          {({ ref, inView }) => (
            <div key={name} ref={ref}>
              <div className="Polaris-Labelled__LabelWrapper">
                <div className="Polaris-Label">
                  <label className="Polaris-Label__Text">
                    {label}
                    {data.required ? "*" : ""}
                  </label>
                </div>
              </div>
              <div className="Polaris-Select"></div>
              {data.value_limit ? (
                <Select
                  name={name}
                  options={entitySuggestion}
                  isSearchable={true}
                  onChange={(value, action) => {
                    handleChange(value, action, setSelect1, name);
                  }}
                  isClearable
                  components={{ MenuList }}
                  defaultValue={defaultValue[0]}
                  styles={customStyles}
                />
              ) : (
                <Select
                  name={name}
                  options={entitySuggestion}
                  isSearchable={true}
                  components={{ MenuList }}
                  onChange={(value, action) => {
                    handleChange(value, action, setSelect1, name);
                  }}
                  styles={customStyles}
                  isClearable
                  defaultValue={defaultValue}
                  isMulti
                />
              )}
            </div>
          )}
        </InView>
      ) : (
        <div key={name}>
          <div className="Polaris-Labelled__LabelWrapper">
            <div className="Polaris-Label">
              <label className="Polaris-Label__Text">{label}</label>
            </div>
          </div>
          <div className="Polaris-Select"></div>
          {data.value_limit ? (
            <Select
              name={name}
              options={entitySuggestion}
              isSearchable={true}
              onChange={(value, action) => {
                handleChange(value, action, setSelect1, name);
              }}
              isClearable
              styles={customStyles}
            />
          ) : (
            <Select
              name={name}
              options={entitySuggestion}
              isSearchable={true}
              onChange={(value, action) => {
                handleChange(value, action, setSelect1, name);
              }}
              styles={customStyles}
              isClearable
              isMulti
            />
          )}
          {errors && errors[name] && (
            <ErrorMessage message={errors[name]["message"]} />
          )}
        </div>
      )}
    </>
  );
}

export default WebForm;
