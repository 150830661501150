import React from "react";
import ErrorMessage from "../ErrorMessage";

const Email = ({ data, errors, name, register, setValue }) => {
  let { label, default_value, description, required } = data;
  let defaultVal = default_value;
  if (data.current_value) {
    defaultVal = data.current_value;
  }
  // console.log(name);

  return (
    <div key={name}>
      <div className="">
        <div className="Polaris-Labelled__LabelWrapper">
          <div className="Polaris-Label">
            <label
              id="PolarisTextField2Label"
              htmlFor={name}
              className="Polaris-Label__Text"
            >
              {label}
            </label>
          </div>
        </div>
        <div className="Polaris-Connected">
          <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
            <div className="Polaris-TextField Polaris-TextField--hasValue">
              {required ? (
                <input
                  type="email"
                  className="Polaris-TextField__Input"
                  name={name}
                  placeholder="example@email.com"
                  id={name}
                  defaultValue={defaultVal}
                  {...register(name, {
                    required: `This field is required`,
                  })}
                />
              ) : (
                <input
                  type="email"
                  className="Polaris-TextField__Input"
                  name={name}
                  id={name}
                  defaultValue={defaultVal}
                  {...register(name)}
                  placeholder="example@email.com"
                />
              )}
              <div className="Polaris-TextField__Backdrop"></div>
            </div>
          </div>
        </div>
        <div
          className="Polaris-Labelled__HelpText"
          id="PolarisTextField2HelpText"
        >
          <span dangerouslySetInnerHTML={{ __html: description }}></span>
        </div>
      </div>
      <div id="PolarisPortalsContainer"></div>
      {errors && errors[name] && (
        <ErrorMessage message={errors[name]["message"]} />
      )}
    </div>
  );
};

export default Email;
