/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react"
import React from "react"
import Remove from "../../Assets/icons/close.svg"
import { red, green, blue, redLight, greenLight, blueLight } from "../Styles"
function Tag(props) {
  const { onClick, active, showClose, onCloseClick, children } = props

  let bg = greenLight,
    text = green,
    transform = "normal",
    weight = 400,
    size = "14px",
    spacing = "0.02em"

  if (props.blue) {
    bg = blueLight
    text = blue
  }
  if (props.red) {
    bg = redLight
    text = red
  }

  if (props.darkGreen) {
    bg = green
    text = "#fff"
  }
  if (props.upper) transform = "uppercase"
  if (props.bold) {
    weight = 600
    size = "10px"
    spacing = "0.05em"
  }

  const Inner = () => {
    if (showClose) {
      return (
        <>
          {children}
          <div className="pl-2" role="none" onClick={onCloseClick}>
            <Remove className="pt-[1px]" />
          </div>
        </>
      )
    }
    return children
  }

  if (onClick || active) {
    return (
      <div
        onClick={onClick ? onClick : () => {}}
        className="flex items-center justify-center rounded-full cursor-pointer"
        css={css`
          ${!active
            ? `background-color: ${bg};
          color: ${text};`
            : `background-color: ${text};
            color: #fff;
            svg * {
              fill: #fff;
            }`}
          &:hover {
            background-color: ${text};
            color: #fff;
            svg * {
              fill: #fff;
            }
          }
        `}
      >
        <span
          className="flex items-center"
          css={css`
            font-family: "Jost";
            font-style: normal;
            font-weight: ${weight};
            font-size: ${size};
            line-height: 30px;
            letter-spacing: ${spacing};
            /* flex: none;
            order: 0;
            flex-grow: 0; */
            padding: ${showClose ? `0 3px 0 7px` : `0 8px`};
            margin: 0px 10px;
            text-transform: ${transform};
          `}
        >
          <Inner />
        </span>
      </div>
    )
  }
  return (
    <div
      className="flex items-center justify-center rounded-full"
      css={css`
        background-color: ${bg};
        color: ${text};
      `}
    >
      <span
        className="flex items-center"
        css={css`
          font-family: "Jost";
          font-style: normal;
          font-weight: ${weight};
          font-size: ${size};
          line-height: 30px;
          letter-spacing: ${spacing};
          flex: none;
          order: 0;
          flex-grow: 0;
          padding: 0 8px;
          margin: 0px 10px;
          text-transform: ${transform};
        `}
      >
        <Inner />
      </span>
    </div>
  )
}

export default Tag
