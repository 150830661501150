import React, { useState, useEffect } from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import Select from "react-select";
import { Button, Modal, TextContainer } from "@shopify/polaris";
import styled from "styled-components";
import { Icon } from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import { set } from "react-hook-form";
const customStyles = {
  container: (base) => ({
    ...base,
    fontSize: "1.4rem",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 334444444,
    fontSize: "1.4rem",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);
  const [subnav2, setSubnav2] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  const handleChange = (value, action) => {
    setSelectValue(`${value.value}`);

    // history.push(`${value.value}`);
    // setOpen(false);
    // setCurrentItem(null);
  };
  const runCreate = () => {
    let Val = selectValue;
    if (!Val) Val = currentItem.content[0].to;
    history.push(`${Val}`);
    setOpen(false);
    setCurrentItem(null);
  };
  const showSubnav2 = (v) => {
    setOpen(true);
    setCurrentItem(v);
    setSubnav2(!subnav2);
  };
  let location = useLocation();
  useEffect(() => {
    item.content &&
      item.content.map((item) => {
        if (location.pathname === item.to) {
          setSubnav(true);
        }
      });
    // if (location.pathname.includes(item.to)) {
    //   setSubnav(false);
    // }
  }, [location.pathname]);

  return (
    <>
      <li className="Polaris-Navigation__ListItem">
        <div className="Polaris-Navigation__ItemWrapper">
          {item.content ? (
            <Link
              to={"#"}
              onClick={item.content && showSubnav}
              className={
                location.pathname === item.to ||
                location.pathname === `/add-new-${item.name}` ||
                location.pathname.substring(
                  0,
                  location.pathname.lastIndexOf("/") + 1
                ) === `/add-new-${item.name}/`
                  ? "Polaris-Navigation__Item Polaris-Navigation__Item--selected Polaris-Navigation--subNavigationActive"
                  : "Polaris-Navigation__Item"
              }
            >
              <div className="Polaris-Navigation__Icon">
                <span className="Polaris-Icon">
                  <Icon source={item.icon} />
                </span>
              </div>
              <span className="Polaris-Navigation__Text">{item.label}</span>
            </Link>
          ) : (
            <Link
              to={item.to}
              className={
                location.pathname === item.to
                  ? "Polaris-Navigation__Item Polaris-Navigation__Item--selected Polaris-Navigation--subNavigationActive"
                  : "Polaris-Navigation__Item"
              }
            >
              <div className="Polaris-Navigation__Icon">
                <span className="Polaris-Icon">
                  <Icon source={item.icon} />
                </span>
              </div>
              <span className="Polaris-Navigation__Text">{item.label}</span>
            </Link>
          )}
          {/* <div>
            {item.content && subnav
                ? item.iconOpened
                : item.content
                ? item.iconClosed
                : null}
            </div> */}
        </div>
        {item.content && subnav && (
          <div className="Polaris-Navigation__SecondaryNavigation Polaris-Navigation--isExpanded ">
            <ul className="Polaris-Navigation__List">
              {
                item.content.length && (
                  <li className="Polaris-Navigation__ListItem ">
                    <div className="Polaris-Navigation__ItemWrapper">
                      <button
                        className={
                          location.pathname.includes(`/add-new-${item.name}`)
                            ? "Polaris-Navigation__Item Polaris-Navigation__Item--selected Polaris-Navigation--subNavigationActive"
                            : "Polaris-Navigation__Item"
                        }
                        // to={`/add-new-${item.name}`}
                        onClick={() => showSubnav2(item)}
                      >
                        {item.icon ? (
                          <div className="Polaris-Navigation__Icon">
                            <span className="Polaris-Icon">
                              <Icon source={item.icon} />
                            </span>
                          </div>
                        ) : null}
                        <span className="Polaris-Navigation__Text">
                          Add New
                        </span>
                      </button>
                    </div>
                    <div className="Polaris-Navigation__SecondaryNavigation Polaris-Navigation--isExpanded">
                      <ul className="Polaris-Navigation__List">
                        {
                          // item.content &&
                          //   subnav2 &&
                          //   item.content.map((item, index) => {
                          //     return (
                          //       <li
                          //         className="Polaris-Navigation__ListItem"
                          //         key={index}
                          //       >
                          //         <div className="Polaris-Navigation__ItemWrapper">
                          //           <Link
                          //             className={
                          //               location.pathname === item.to
                          //                 ? "Polaris-Navigation__Item Polaris-Navigation__Item--selected Polaris-Navigation--subNavigationActive"
                          //                 : "Polaris-Navigation__Item"
                          //             }
                          //             to={item.to}
                          //           >
                          //             {item.icon ? (
                          //               <div className="Polaris-Navigation__Icon">
                          //                 <span className="Polaris-Icon">
                          //                   <Icon source={item.icon} />
                          //                 </span>
                          //               </div>
                          //             ) : null}
                          //             <span className="Polaris-Navigation__Text">
                          //               {item.label}
                          //             </span>
                          //           </Link>
                          //         </div>
                          //       </li>
                          //     );
                          //   })
                        }
                      </ul>
                    </div>
                  </li>
                )
                // : (
                //   <li className="Polaris-Navigation__ListItem">
                //     <div className="Polaris-Navigation__ItemWrapper">
                //       <Link
                //         className={
                //           location.pathname === item.content[0].to
                //             ? "Polaris-Navigation__Item Polaris-Navigation__Item--selected Polaris-Navigation--subNavigationActive"
                //             : "Polaris-Navigation__Item"
                //         }
                //         to={item.content[0].to}
                //       >
                //         {item.icon ? (
                //           <div className="Polaris-Navigation__Icon">
                //             <span className="Polaris-Icon">
                //               <Icon source={item.content[0].icon} />
                //             </span>
                //           </div>
                //         ) : null}
                //         <span className="Polaris-Navigation__Text">
                //           {item.content[0].label}
                //         </span>
                //       </Link>
                //     </div>
                //   </li>
                // )
              }
              <li className="Polaris-Navigation__ListItem">
                <div className="Polaris-Navigation__ItemWrapper">
                  <Link
                    className={
                      location.pathname === item.to
                        ? "Polaris-Navigation__Item Polaris-Navigation__Item--selected Polaris-Navigation--subNavigationActive"
                        : "Polaris-Navigation__Item"
                    }
                    to={item.to}
                    onClick={() => setSubnav(true)}
                  >
                    {item.icon ? (
                      <div className="Polaris-Navigation__Icon">
                        <span className="Polaris-Icon">
                          <Icon source={item.icon} />
                        </span>
                      </div>
                    ) : null}
                    <span className="Polaris-Navigation__Text">List All</span>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        )}
      </li>

      {/* {subnav &&
        item.content.map((item, index) => {
          return (
            <DropdownLink to={item.to} key={index}>
              {item.icon}
              <SidebarLabel>{item.label}</SidebarLabel>
            </DropdownLink>
          );
        })} */}
      {currentItem !== null && (
        <Modal
          instant
          open={open}
          onClose={() => setOpen(false)}
          title={<>Add New {currentItem.name}</>}
        >
          <Modal.Section>
            <p className="mb-5">
              Which type of <u>{currentItem.name}</u> do you want to create?{" "}
            </p>
            <div className="w-full relative flex">
              <div style={{ width: "80%", marginRight: "12px" }}>
                <Select
                  options={currentItem.content.map((v) => ({
                    ...v,
                    label: v.label,
                    value: v.to,
                  }))}
                  defaultValue={
                    currentItem.content.map((v) => ({
                      ...v,
                      value: v.to,
                    }))[0]
                  }
                  isSearchable={true}
                  onChange={(value, action) => {
                    handleChange(value);
                  }}
                  styles={customStyles}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  menuPlacement={"auto"}
                  menuShouldScrollIntoView={true}
                />
              </div>
              <div className="w-[20%]">
                <Button onClick={() => runCreate()}>Create</Button>
              </div>
            </div>
          </Modal.Section>
        </Modal>
      )}
    </>
  );
};

export default SubMenu;
