/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import React from "react";
import ErrorMessage from "../ErrorMessage";

const Float = ({ data, errors, name, register }) => {
  let { label, default_value, description, required } = data;
  if (data.current_value) {
    default_value = data.current_value;
  }
  return (
    <div key={name}>
      <div className="">
        <div className="Polaris-Labelled__LabelWrapper">
          <div className="Polaris-Label">
            <label
              id="PolarisTextField2Label"
              htmlFor={name}
              className="Polaris-Label__Text"
            >
              {label}
              {data.required ? "*" : ""}
            </label>
          </div>
        </div>
        <div className="Polaris-Connected">
          <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
            <div className="Polaris-TextField Polaris-TextField--hasValue">
              {(name == "field_asset_price" || name == "field_asset_cost") && (
                <span
                  css={css`font-size: 1.4rem;
    z-index: 333;
    color: black;
    padding-left: 10px;
}`}
                >
                  $
                </span>
              )}
              {required ? (
                <input
                  type="number"
                  step="any"
                  className="Polaris-TextField__Input"
                  name={name}
                  id={name}
                  defaultValue={default_value}
                  {...register(name, {
                    required: "This field is required.",
                  })}
                />
              ) : (
                <input
                  type="number"
                  step="any"
                  className="Polaris-TextField__Input"
                  name={name}
                  id={name}
                  defaultValue={
                    default_value
                      ? default_value
                      : name == "field_asset_price"
                      ? "0.00"
                      : 0
                  }
                  {...register(name)}
                />
              )}
              <div className="Polaris-TextField__Backdrop"></div>
            </div>
          </div>
        </div>
        <div
          className="Polaris-Labelled__HelpText"
          id="PolarisTextField2HelpText"
        >
          <span dangerouslySetInnerHTML={{ __html: description }}></span>
        </div>
      </div>
      <div id="PolarisPortalsContainer"></div>
      {errors && errors[name] && (
        <ErrorMessage message={errors[name]["message"]} />
      )}
    </div>
  );
};

export default Float;
