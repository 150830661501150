import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
// import ValueInfinity from "./ValueLimit";
import ErrorMessage from "../ErrorMessage";

const MetaTags = ({ data, name, regis, setValue, errors }) => {
  let { label, default_value, description, required } = data;
  let defaultVal = default_value;
  if (data.current_value) {
    defaultVal = data.current_value;
  }
  const { register, getValues } = useForm();
  const onChange = () => {
    const data = getValues();
    setValue(`${name}`, data);
  };
  useEffect(() => {
    regis(`${name}`);
    setValue(`${name}`, { title: null, description: null, tags: null });
  }, []);
  // console.log(name);

  return (
    <div key={name}>
      <div>
        <div className="Polaris-Labelled__LabelWrapper">
          <div className="Polaris-Label">
            <label id="PolarisTextField2Label" className="Polaris-Label__Text">
              {label}
              {data.required ? "*" : ""}
            </label>
          </div>
        </div>
        <div style={{ margin: "0 ", padding: "14px" }}>
          <form onChange={onChange}>
            <div>
              <div className="Polaris-Labelled__LabelWrapper">
                <div className="Polaris-Label">
                  <label
                    id="PolarisTextField2Label"
                    htmlFor={"title"}
                    className="Polaris-Label__Text"
                  >
                    Meta Title{data.required ? "*" : ""}
                  </label>
                </div>
              </div>
              <div className="Polaris-Connected">
                <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                  <div className="Polaris-TextField Polaris-TextField--hasValue">
                    <input
                      type="text"
                      className="Polaris-TextField__Input"
                      name="title"
                      id="title"
                      // defaultValue={defaultVal}

                      {...register(name, {
                        required: `This field is required`,
                      })}
                    />
                    <div className="Polaris-TextField__Backdrop"></div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="Polaris-Labelled__LabelWrapper">
                <div className="Polaris-Label">
                  <label
                    id="PolarisTextField2Label"
                    htmlFor={"title"}
                    className="Polaris-Label__Text"
                  >
                    Meta Description{data.required ? "*" : ""}
                  </label>
                </div>
              </div>
              <div className="Polaris-Connected">
                <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                  <div className="Polaris-TextField Polaris-TextField--hasValue">
                    <input
                      type="text"
                      className="Polaris-TextField__Input"
                      name="description"
                      id="description"
                      // defaultValue={defaultVal}
                      {...register(name, {
                        required: `This field is required`,
                      })}
                    />
                    <div className="Polaris-TextField__Backdrop"></div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="Polaris-Labelled__LabelWrapper">
                <div className="Polaris-Label">
                  <label
                    id="PolarisTextField2Label"
                    htmlFor={"tags"}
                    className="Polaris-Label__Text"
                  >
                    Meta Tags{data.required ? "*" : ""}
                  </label>
                </div>
              </div>
              <div className="Polaris-Connected">
                <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                  <div className="Polaris-TextField Polaris-TextField--hasValue">
                    <input
                      type="text"
                      className="Polaris-TextField__Input"
                      name="tags"
                      id="tags"
                      // defaultValue={defaultVal}

                      {...register(name, {
                        required: `This field is required`,
                      })}
                    />
                    <div className="Polaris-TextField__Backdrop"></div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {errors && errors[name] && (
            <ErrorMessage message={errors[name]["message"]} />
          )}
        </div>
        <div
          className="Polaris-Labelled__HelpText"
          id="PolarisTextField2HelpText"
        >
          <span dangerouslySetInnerHTML={{ __html: description }}></span>
        </div>
      </div>
      <div id="PolarisPortalsContainer"></div>
    </div>
  );
};

export default MetaTags;
