/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import React from "react";

export const Table = (props) => {
  const { columns, children, sm, overflowVisible, flush } = props;

  let styles = css``;
  if (flush)
    styles = css`
      &&& {
        tr {
          td {
            padding-top: 0.75rem !important;
            padding-bottom: 0.75rem !important;
          }
          td:first-child {
            padding-left: 0 !important;
          }
          td:last-child {
            padding-right: 0 !important;
          }
        }
      }
    `;
  return (
    <div className="flex flex-col">
      <div
        className={`-my-2 ${
          !overflowVisible && `overflow-x-auto`
        } sm:-mx-6 lg:-mx-8`}
      >
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            className={`${!overflowVisible && `overflow-hidden`} ${
              !flush && `border border-[#EBEBEB] rounded-[8px]`
            }`}
          >
            <table className="min-w-full divide-y divide-gray-200">
              {columns && (
                <ThWrapper>
                  {columns.map((item, k) => {
                    return (
                      <Th key={k} sm={sm}>
                        {item}
                      </Th>
                    );
                  })}
                </ThWrapper>
              )}
              <tbody className="bg-white divide-y divide-gray-200" css={styles}>
                {children}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ThWrapper = (props) => {
  const { children } = props;
  return (
    <thead className="">
      <tr>{children}</tr>
    </thead>
  );
};
export const Th = (props) => {
  const { children, right, sm } = props;
  return (
    <th
      scope="col"
      className={`px-6 ${sm ? `py-[15px]` : `py-[30px]`} ${
        right ? `text-right` : `text-left`
      } text-med-16`}
    >
      {children}
    </th>
  );
};
export const Tr = (props) => {
  const { children } = props;
  return <tr className={props.className && props.className}>{children}</tr>;
};
export const Td = (props) => {
  const { children } = props;
  return (
    <td
      className={`px-6 py-4 whitespace-nowrap text-reg-15 ${
        props.className && props.className
      }`}
    >
      {children}
    </td>
  );
};
