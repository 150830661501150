import React, { useEffect, useState, createContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button } from "@shopify/polaris";
import ErrorMessage from "../ErrorMessage";

// import { AiOutlineDelete } from "react-icons/ai";
import { useForm, useFieldArray } from "react-hook-form";
import styled from "styled-components";
// const ShortableOption = styled.ol`
//   display: block;
//   padding: 6px 12px 30px;
//   line-height: 1.42857143;
//   border-radius: 4px;
//   width: 100%;
//   font-size: 14px;
//   border: 1px solid rgba(0, 0, 0, 0.07);
//   background: #fff;
//   box-sizing: border-box;
// `;

const InputItems = styled.li`
  display: flex;
  min-height: 20px;
  cursor: move;
  /* padding-right: 20px; */
  clear: both;
  margin: 5px;
  background-color: #fff;
  width: 100%;
  transition: background-color 250ms ease-in-out, margin-top 400ms;
`;
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const NestedFields = ({
  name,
  index,
  handleRemoveClick,
  list,
  setList,
  required,
  default_value,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    watch,
    getValues,
    unregister,
    control,
  } = useForm();
  const onChange = () => {
    let data = getValues(name);
    const tempArray = [...list];
    const tempF = list[index];
    const tmpObj = data;
    tempArray.splice(index, 1, tmpObj);
    setList([...tempArray]);
    handleSubmit(setList([...tempArray]));
  };
  // console.log(watch());
  return (
    <form onChange={onChange}>
      <Draggable key={name} draggableId={`${name}_${index * 3}`} index={index}>
        {(provided, snapshot) => (
          <>
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{ marginBottom: 10 }}
              key={index}
            >
              <div className="Polaris-TextField Polaris-TextField--hasValue">
                <InputItems>
                  {required ? (
                    <input
                      type="text"
                      className="Polaris-TextField__Input"
                      name={name}
                      defaultValue={default_value}
                      {...register(name, {
                        required: `this field is required`,
                      })}
                    />
                  ) : (
                    <input
                      type="text"
                      className="Polaris-TextField__Input"
                      name={name}
                      defaultValue={default_value}
                      {...register(name)}
                    />
                  )}
                  {index !== 0 && (
                    <button
                      style={{ zIndex: "102" }}
                      onClick={() => handleRemoveClick(index)}
                    >
                      Delete
                    </button>
                  )}
                </InputItems>

                <div className="Polaris-TextField__Backdrop"></div>
              </div>
            </div>
            {snapshot.isDragging && (
              <div className="Polaris-TextField Polaris-TextField--hasValue">
                <InputItems>
                  <input type="text" className="Polaris-TextField__Input" />
                </InputItems>
                <div className="Polaris-TextField__Backdrop"></div>
              </div>
            )}
          </>
        )}
      </Draggable>
    </form>
  );
};

function ValueInfinity({ data, errors, name, register, setValue }) {
  let { label, default_value, value_limit, description, required } = data;
  // if (data.current_value) {
  //   default_value = data.current_value;
  // }

  // if (data.value_limit === 2) {
  //   default_value = data.current_value;
  // }
  let array = [name];
  const [list, setList] = useState(null);
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    // let value = getValues('arr')
    const items = reorder(list, result.source.index, result.destination.index);
    setList(items);
  };
  const handleRemoveClick = (i) => {
    if (i === 0) return;

    const tempArray = [...list];
    tempArray.splice(i, 1);
    setList([...tempArray]);
  };
  const handleAddClick = (i) => {
    if (value_limit !== false && i === value_limit) return;
    let newName = "";
    if (list !== null) {
      let List = [...list, newName];
      setList([...List]);
    } else {
      setList([newName]);
    }
  };
  useEffect(() => {
    // register(`${name}`);
    if (list !== null && list.length && list[0]) setValue(`${name}`, list);
  }, [list]);
  useEffect(() => {
    if (data.required) {
      register(name, { required: `This field is required ` });
    } else {
      register(name);
    }
    if (
      data.current_value !== null &&
      data.current_value !== undefined &&
      Array.isArray(data.current_value) &&
      data.current_value.length
    ) {
      setList([...data.current_value]);
      setValue(`${name}`, data.current_value);
    } else {
      setList([data.current_value]);
      setValue(`${name}`, data.current_value);
    }
  }, []);
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <>
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <div className="">
                <div className="Polaris-Labelled__LabelWrapper">
                  <div className="Polaris-Label">
                    <label
                      id="PolarisTextField2Label"
                      htmlFor={name}
                      className="Polaris-Label__Text"
                    >
                      {label}
                      {data.required ? "*" : ""}
                    </label>
                  </div>
                </div>
                <div className="Polaris-Connected">
                  <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                    {list !== null &&
                      list.length &&
                      list.map((value, i) => {
                        return (
                          <NestedFields
                            name={name}
                            index={i}
                            handleRemoveClick={handleRemoveClick}
                            list={list}
                            setList={setList}
                            required={required}
                            default_value={value}
                          />
                        );
                      })}
                    {errors && errors[name] && (
                      <ErrorMessage message={errors[name]["message"]} />
                    )}
                    <div
                      className="Polaris-Button__ConnectedDisclosureWrapper"
                      style={{ margin: "10px 0" }}
                    >
                      <button
                        onClick={() =>
                          handleAddClick(list !== null ? list.length + 1 : 1)
                        }
                        className="Polaris-Button Polaris-Button--primary"
                        type="button"
                      >
                        <span className="Polaris-Button__Content">
                          <span className="Polaris-Button__Text">
                            Add another {label}
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="Polaris-Labelled__HelpText"
                  id="PolarisTextField2HelpText"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></span>
                </div>
              </div>
              <div id="PolarisPortalsContainer"></div>

              <br />
            </div>
            {provided.placeholder}
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default ValueInfinity;
