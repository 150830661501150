import React, { useCallback, useState, useEffect } from "react";
import { Card, RangeSlider } from "@shopify/polaris";
import ErrorMessage from "../ErrorMessage";

export default function Weight({
  data,
  errors,
  name,
  register,
  setValue,
  label,
}) {
  const [rangeValue, setRangeValue] = useState(data.default_value);

  const handleRangeSliderChange = useCallback((value) => {
    setRangeValue(value);
    setValue(`${name}`, value);
  }, []);
  useEffect(() => {
    if (data.required) {
      register(name, { required: `This field is required ${name}` });
    } else {
      register(name);
    }
    if (data.default_value) {
      setValue(name, data.default_value);
    }
  }, []);
  return (
    <div style={{ maxWidth: 150 }}>
      <div className="Polaris-Labelled__LabelWrapper">
        <div className="Polaris-Label">
          <label
            id="PolarisTextField2Label"
            htmlFor={name}
            className="Polaris-Label__Text"
          >
            {label}
            {data.required ? "*" : ""}
          </label>
        </div>
      </div>
      <Card sectioned>
        <RangeSlider
          // label={label}
          value={rangeValue}
          onChange={handleRangeSliderChange}
          output
          max={50}
          min={-50}
          name={name}
        />
        {errors && errors[name] && (
          <ErrorMessage message={errors[name]["message"]} />
        )}
      </Card>
    </div>
  );
}

// const Weight =({data,errors,name, register})=> {
//     let { label,default_value, description ,required } = data;
//     if(data.current_value){
//         default_value = data.current_value
//     }
//     return (
//         <div key={name}>
//              <div className="">
//         <div className="Polaris-Labelled__LabelWrapper">
//       <div className="Polaris-Label">
//       <label id="PolarisTextField2Label"  htmlFor={name} className="Polaris-Label__Text">{label}</label></div>
//     </div>
//     <div className="Polaris-Connected">
//       <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
//         <div className="Polaris-TextField Polaris-TextField--hasValue">
//         {required
//             ?
//             <input type="number" step=".0001" min="0" max="1" className="Polaris-TextField__Input" name={name} id={name} defaultValue={default_value} ref={register({ required: true })}/>

//             :
//             <input type="number" step=".0001" min="0" max="1"  className="Polaris-TextField__Input" name={name} id={name} defaultValue={default_value} ref={register}/>

//             }
//           <div className="Polaris-TextField__Backdrop"></div>
//         </div>
//       </div>
//     </div>
//     <div className="Polaris-Labelled__HelpText" id="PolarisTextField2HelpText">
//     <span>{description}</span>
//     </div>
//         </div>
//   <div id="PolarisPortalsContainer"></div>

//                 <br/>
//         </div>
//     )
// }

// export default Weight
